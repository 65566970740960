import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})

export class SharingDataService {
  
  public updateParentSubject= new Subject<string>;
  public updateTabSubject= new Subject<number>;
  public rowDataSubject= new Subject<any>;
  constructor() {  }

  updateParentStatus(data : string){
    this.updateParentSubject.next(data);
  }
  updateTabStatus(data: number){
    this.updateTabSubject.next(data);
  }

  storeRowData(data : string){
    this.rowDataSubject.next(data);
  }
}