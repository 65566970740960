import { CommonModule, CurrencyPipe } from '@angular/common';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgxPaginationModule } from 'ngx-pagination';

import { AppSessionService } from './session/app-session.service';
import { AppUrlService } from './nav/app-url.service';
import { AppAuthService } from './auth/app-auth.service';
import { AppRouteGuard } from './auth/auth-route-guard';
import { LocalizePipe } from '@shared/pipes/localize.pipe';

import { AbpPaginationControlsComponent } from './components/pagination/abp-pagination-controls.component';
import { AbpValidationSummaryComponent } from './components/validation/abp-validation.summary.component';
import { AbpModalHeaderComponent } from './components/modal/abp-modal-header.component';
import { AbpModalFooterComponent } from './components/modal/abp-modal-footer.component';
import { LayoutStoreService } from './layout/layout-store.service';

import { BusyDirective } from './directives/busy.directive';
import { EqualValidator } from './directives/equal-validator.directive';
import {AppPageSizeDropdownComponent} from '../shared/components/pagination/app-page-size-dropdown/app-page-size-dropdown.component'
import {SearchComponent} from '../shared/components/search/search.component'
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CheckBoxModule } from '@syncfusion/ej2-angular-buttons';
import { MultiSelectModule } from '@syncfusion/ej2-angular-dropdowns';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
//import { logoComponent } from './components/logos/logo-component';
import {FileDragNDropDirective} from "@shared/directives/file-drag-n-drop.directive";
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import {SearchableDropdownComponent}  from '@shared/components/searchable-dropdown/searchable-dropdown.component';
import {CurrencyFormatDirective}  from '@shared/directives/currency-format.directive';
import {PublicCustomerComponent}  from '@shared/components/public-customer/create-form.component';
import {SuccessPageComponent}  from '@shared/components/public-customer/success-page/success-page.component';
import {PublicCustomerService}  from '@shared/service-proxies/public-customer/public-customer.service';
import {NumericOnlyDirective}  from '@shared/directives/numberOnly.directive';
import {NgxPrintModule} from 'ngx-print';
import { PopoverConfig, PopoverModule } from 'ngx-bootstrap/popover';
import { PrintComponent } from '@shared/components/print/print.component';
import { NgxSpinnerModule } from "ngx-spinner";
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { SpinnerInterceptor } from './interceptor/spinner-interceptor';
import { AbpHttpInterceptor } from 'abp-ng2-module';
import { ClipboardModule } from 'ngx-clipboard';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { NgxCurrencyDirective, NgxCurrencyInputMode, provideEnvironmentNgxCurrency } from 'ngx-currency';
import { SelectDropDownModule } from 'ngx-select-dropdown';
import { SearchDropDownComponent } from './components/search-dropdown/search-dropdown.component';
@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        NgxPaginationModule,
        FormsModule,
        CheckBoxModule,
        MultiSelectModule,
        ReactiveFormsModule,
        TooltipModule,
        BsDatepickerModule.forRoot(),
        NgxPrintModule,
        PopoverModule.forRoot(),
        // BrowserAnimationsModule,
        NgxSpinnerModule.forRoot(),
        ClipboardModule,
        AccordionModule,
        NgxCurrencyDirective,
        SelectDropDownModule,
    ],
    declarations: [
        AbpPaginationControlsComponent,
        AbpValidationSummaryComponent,
        AbpModalHeaderComponent,
        AbpModalFooterComponent,
        LocalizePipe,
        BusyDirective,
        EqualValidator,
        AppPageSizeDropdownComponent,
        SearchComponent,
        FileDragNDropDirective,
        SearchableDropdownComponent,
        CurrencyFormatDirective,
        PublicCustomerComponent,
        SuccessPageComponent,
        PrintComponent,
        NumericOnlyDirective,
        SearchDropDownComponent,
        //logoComponent
    ],
    exports: [
        AbpPaginationControlsComponent,
        AbpValidationSummaryComponent,
        AbpModalHeaderComponent,
        AbpModalFooterComponent,
        LocalizePipe,
        BusyDirective,
        EqualValidator,
        AppPageSizeDropdownComponent,
        SearchComponent,
        CheckBoxModule,
        MultiSelectModule,
        FileDragNDropDirective,
        NgxPaginationModule,
        FormsModule,
        ReactiveFormsModule,
        TooltipModule,
        BsDatepickerModule,
        SearchableDropdownComponent,
        CurrencyFormatDirective,
        PublicCustomerComponent,
        SuccessPageComponent,
        NgxPrintModule,
        PopoverModule,
        PrintComponent,
        NgxSpinnerModule,
        ClipboardModule,
        AccordionModule,
        NgxCurrencyDirective,
        SelectDropDownModule,
        SearchDropDownComponent
    ],
    providers:[
        { provide: HTTP_INTERCEPTORS, useClass: AbpHttpInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: SpinnerInterceptor, multi: true },
        { provide: PopoverConfig, useFactory: getPopoverConfig },
        CurrencyPipe,
        provideEnvironmentNgxCurrency({
            prefix: '$',
            thousands: ',',
            decimal: '.',
            align: 'left',
            inputMode: NgxCurrencyInputMode.Natural,
        })
    ]
})
export class SharedModule {
    static forRoot(): ModuleWithProviders<SharedModule> {
        return {
            ngModule: SharedModule,
            providers: [
                AppSessionService,
                AppUrlService,
                AppAuthService,
                AppRouteGuard,
                LayoutStoreService,
                PublicCustomerService
            ]
        };
    }
}

export function getPopoverConfig(): PopoverConfig {
    return Object.assign(new PopoverConfig(), {
      placement: 'left',
      triggers: 'mouseenter:mouseleave'
    });
  }
