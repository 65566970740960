import {
    mergeMap as _observableMergeMap,
    catchError as _observableCatch,
    mergeMap,
    catchError,
} from "rxjs/operators";
import {
    Observable,
    throwError as _observableThrow,
    of as _observableOf,
    of,
    Subject,
    BehaviorSubject,
    throwError,
} from "rxjs";
import { Injectable, Inject, Optional } from "@angular/core";
import {
    HttpClient,
    HttpHeaders,
    HttpResponse,
    HttpResponseBase,
} from "@angular/common/http";

import * as moment from "moment";
import { ApiException, SelectedItems } from "../../service-proxies";
import { API_BASE_URL } from "@shared/service-proxies/service-proxies";
import { GenericService } from "@shared/service-proxies/generic.service";

// export const API_BASE_URL = new InjectionToken<string>("API_BASE_URL");

@Injectable()
export class ProjectServices extends GenericService {
    private http: HttpClient;
    private baseUrl: string;
    protected jsonParseReviver: ((key: string, value: any) => any) | undefined =
        undefined;
    private _projectCodeBehaviourSubject = new BehaviorSubject<string>("");

    public projectCode$ = this._projectCodeBehaviourSubject.asObservable();

    private _projectNameSubject = new Subject();
    public projectName$ = this._projectNameSubject.asObservable();

    private _savePCMSubject = new Subject<string>();
    public savePCM$ = this._savePCMSubject.asObservable();

    constructor(
        @Inject(HttpClient) http: HttpClient,
        @Optional() @Inject(API_BASE_URL) baseUrl?: string
    ) {
        super();
        this.http = http;
        this.baseUrl = baseUrl !== undefined && baseUrl !== null ? baseUrl : "";
    }


    // Custom functions

    updateArea(item: ITeamAreaWithoutName, list: ITeamArea[]): ITeamAreaWithoutName  {
        const matchingItem = list.find(listItem => listItem.id === item.id);
        return matchingItem ? { ...item, area: matchingItem.area,roleId:matchingItem.roleId } : item;
      };

    setProjectCode(projectCode: string) {
        this._projectCodeBehaviourSubject.next(projectCode);
    }


    setProjectName(projectName: string) {
        this._projectNameSubject.next(projectName);
    }

    setSave(action: string) {
        this._savePCMSubject.next(action);
    }

    /* Get All Project through Sp*/
    GetAllProjects(
        keyword: string,
        sorting: string,
        skipCount: number | 0,
        maxResultCount: number | 10,
        AssetsOnlyLocations: boolean,
        ProjectStatus: string
    ): Observable<ProjectPagedResultDto> {
        let url_ = this.baseUrl + "/api/services/app/Project/GetAllProjects?";
        if (keyword === null)
            throw new Error("The parameter 'keyword' cannot be null.");
        else if (keyword !== undefined)
            url_ += "Keyword=" + encodeURIComponent("" + keyword) + "&";
        if (sorting === null)
            throw new Error("The parameter 'skipCount' cannot be null.");
        else if (sorting !== undefined)
            url_ += "sorting=" + encodeURIComponent("" + sorting) + "&";
        if (skipCount === null)
            throw new Error("The parameter 'skipCount' cannot be null.");
        else if (skipCount !== undefined)
            url_ += "SkipCount=" + encodeURIComponent("" + skipCount) + "&";
        if (maxResultCount === null)
            throw new Error("The parameter 'maxResultCount' cannot be null.");
        else if (maxResultCount !== undefined)
            url_ += "MaxResultCount=" + encodeURIComponent("" + maxResultCount) + "&";
        if (AssetsOnlyLocations !== undefined)
            url_ +=
                "AssetsOnlyLocations=" +
                encodeURIComponent("" + AssetsOnlyLocations) +
                "&";
        if (ProjectStatus !== undefined)
            url_ += "ProjectStatus=" + encodeURIComponent("" + ProjectStatus) + "&";
        url_ = url_.replace(/[?&]$/, "");

        let options_: any = {
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                Accept: "text/plain",
            }),
        };

        return this.http
            .request("get", url_, options_)
            .pipe(
                _observableMergeMap((response_: any) => {
                    return this.processGetProject(response_);
                })
            )
            .pipe(
                _observableCatch((response_: any) => {
                    if (response_ instanceof HttpResponseBase) {
                        try {
                            return this.processGetProject(response_ as any);
                        } catch (e) {
                            return _observableThrow(
                                e
                            ) as any as Observable<ProjectPagedResultDto>;
                        }
                    } else
                        return _observableThrow(
                            response_
                        ) as any as Observable<ProjectPagedResultDto>;
                })
            );
    }
    protected processGetProject(
        response: HttpResponseBase
    ): Observable<ProjectPagedResultDto> {
        const status = response.status;
        const responseBlob =
            response instanceof HttpResponse
                ? response.body
                : (response as any).error instanceof Blob
                    ? (response as any).error
                    : undefined;

        let _headers: any = {};
        if (response.headers) {
            for (let key of response.headers.keys()) {
                _headers[key] = response.headers.get(key);
            }
        }
        if (status === 200) {
            return blobToText(responseBlob).pipe(
                _observableMergeMap((_responseText: string) => {
                    let result200: any = null;
                    let resultData200 =
                        _responseText === ""
                            ? null
                            : JSON.parse(_responseText, this.jsonParseReviver);
                    result200 = ProjectPagedResultDto.fromJS(resultData200);
                    return _observableOf(result200);
                })
            );
        } else if (status !== 200 && status !== 204) {
            return blobToText(responseBlob).pipe(
                _observableMergeMap((_responseText: string) => {
                    return throwException(
                        "An unexpected server error occurred.",
                        status,
                        _responseText,
                        _headers
                    );
                })
            );
        }
        return _observableOf(null as any);
    }

    /*Create Project*/
    createProject(body: CreateProjectDto | undefined): Observable<GetProjectDto> {
        let url_ = this.baseUrl + "/api/services/app/Project/Create";
        url_ = url_.replace(/[?&]$/, "");
        const content_ = JSON.stringify(body);

        let options_: any = {
            body: content_,
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                "Content-Type": "application/json-patch+json",
                Accept: "text/plain",
            }),
        };

        return this.http
            .request("post", url_, options_)
            .pipe(
                _observableMergeMap((response_: any) => {
                    return this.processCreate(response_);
                })
            )
            .pipe(
                _observableCatch((response_: any) => {
                    if (response_ instanceof HttpResponseBase) {
                        try {
                            return this.processCreate(response_ as any);
                        } catch (e) {
                            return _observableThrow(e) as any as Observable<GetProjectDto>;
                        }
                    } else
                        return _observableThrow(
                            response_
                        ) as any as Observable<GetProjectDto>;
                })
            );
    }
    protected processCreate(
        response: HttpResponseBase
    ): Observable<GetProjectDto> {
        const status = response.status;
        const responseBlob =
            response instanceof HttpResponse
                ? response.body
                : (response as any).error instanceof Blob
                    ? (response as any).error
                    : undefined;

        let _headers: any = {};
        if (response.headers) {
            for (let key of response.headers.keys()) {
                _headers[key] = response.headers.get(key);
            }
        }
        if (status === 200) {
            return blobToText(responseBlob).pipe(
                _observableMergeMap((_responseText: string) => {
                    let result200: any = null;
                    let resultData200 =
                        _responseText === ""
                            ? null
                            : JSON.parse(_responseText, this.jsonParseReviver);
                    result200 = GetProjectDto.fromJS(resultData200);
                    return _observableOf(result200);
                })
            );
        } else if (status === 500) {
            return blobToText(responseBlob).pipe(
                _observableMergeMap((_responseText: string) => {
                    let result200: any = null;
                    let resultData200 =
                        _responseText === ""
                            ? null
                            : JSON.parse(_responseText, this.jsonParseReviver);
                    result200 = GetProjectDto.fromJS(resultData200);
                    return _observableOf(result200);
                })
            );
        } else if (status !== 200 && status !== 204) {
            return blobToText(responseBlob).pipe(
                _observableMergeMap((_responseText: string) => {
                    return throwException(
                        "An unexpected server error occurred.",
                        status,
                        _responseText,
                        _headers
                    );
                })
            );
        }
        return _observableOf(null as any);
    }

    //Create Project Team
    createProjectTeam(data: ICreateProjectTeam): Observable<any> {
        let url_ = `${this.baseUrl}/api/services/app/Project/CreateProjectTeam`;

        let options_: any = {
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                Accept: "text/plain",
            }),
        };

        return this.http.post(url_, data, options_).pipe(
            mergeMap((response: any) => this.processGeneric<any>(response)),
            catchError((error: any) => {
                if (error instanceof HttpResponseBase) {
                    return this.processGeneric<any>(error as any);
                } else {
                    return throwError(() => error);
                }
            })
        );
    }

    // Update Project Team

    updateProjectTeam(data: IUpdateProjectTeam): Observable<boolean> {
        let url_ = `${this.baseUrl}/api/services/app/Project/UpdateProjectTeam`;

        let options_: any = {
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                Accept: "text/plain",
            }),
        };

        return this.http.put(url_, data, options_).pipe(
            mergeMap((response: any) => this.processGeneric<boolean>(response)),
            catchError((error: any) => {
                if (error instanceof HttpResponseBase) {
                    return this.processGeneric<boolean>(error as any);
                } else {
                    return throwError(() => error);
                }
            })
        );
    }

    /* Get Project By Id */
    GetProjectById(id: number | undefined): Observable<GetProjectDto> {
        let url_ = this.baseUrl + "/api/services/app/Department/GetDepartmentById?";
        if (id === null) {
            throw new Error("The parameter 'id' cannot be null.");
        } else if (id !== undefined) {
            url_ += "Id=" + encodeURIComponent("" + id) + "&";
        }
        url_ = url_.replace(/[?&]$/, "");

        let options_: any = {
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                Accept: "text/plain",
            }),
        };

        return this.http
            .request("get", url_, options_)
            .pipe(
                _observableMergeMap((response_: any) => {
                    return this.processGetProjectById(response_);
                })
            )
            .pipe(
                _observableCatch((response_: any) => {
                    if (response_ instanceof HttpResponseBase) {
                        try {
                            return this.processGetProjectById(response_ as any);
                        } catch (e) {
                            return _observableThrow(e) as any as Observable<GetProjectDto>;
                        }
                    } else {
                        return _observableThrow(
                            response_
                        ) as any as Observable<GetProjectDto>;
                    }
                })
            );
    }
    protected processGetProjectById(
        response: HttpResponseBase
    ): Observable<GetProjectDto> {
        const status = response.status;
        const responseBlob =
            response instanceof HttpResponse
                ? response.body
                : (response as any).error instanceof Blob
                    ? (response as any).error
                    : undefined;

        let _headers: any = {};
        if (response.headers) {
            for (let key of response.headers.keys()) {
                _headers[key] = response.headers.get(key);
            }
        }
        if (status === 200) {
            return blobToText(responseBlob).pipe(
                _observableMergeMap((_responseText: string) => {
                    let result200: any = null;
                    let resultData200 =
                        _responseText === ""
                            ? null
                            : JSON.parse(_responseText, this.jsonParseReviver);
                    result200 = GetProjectDto.fromJS(resultData200);
                    return _observableOf(result200);
                })
            );
        } else if (status !== 200 && status !== 204) {
            return blobToText(responseBlob).pipe(
                _observableMergeMap((_responseText: string) => {
                    return throwException(
                        "An unexpected server error occurred.",
                        status,
                        _responseText,
                        _headers
                    );
                })
            );
        }
        return _observableOf(null as any);
    }

    //Approve Import Data
    ImportProjectSubTableRecords(
        jsonData: string | undefined
    ): Observable<string> {
        let url_ =
            this.baseUrl + "/api/services/app/Project/ImportProjectSubTableRecords";
        url_ = url_.replace(/[?&]$/, "");
        let options_: any = {
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                Accept: "text/plain",
            }),
        };
        let data = {
            jsonData: jsonData,
        };
        return this.http
            .post(url_, data, options_)
            .pipe(
                _observableMergeMap((response_: any) => {
                    return this.importProjectData(response_);
                })
            )
            .pipe(
                _observableCatch((response_: any) => {
                    if (response_ instanceof HttpResponseBase) {
                        try {
                            return this.importProjectData(response_ as any);
                        } catch (e) {
                            return _observableThrow(e) as any as Observable<string>;
                        }
                    } else {
                        return _observableThrow(response_) as any as Observable<string>;
                    }
                })
            );
    }
    protected importProjectData(response: HttpResponseBase): Observable<string> {
        const status = response.status;
        const responseBlob =
            response instanceof HttpResponse
                ? response.body
                : (response as any).error instanceof Blob
                    ? (response as any).error
                    : undefined;

        let _headers: any = {};
        if (response.headers) {
            for (let key of response.headers.keys()) {
                _headers[key] = response.headers.get(key);
            }
        }
        if (status === 200) {
            return blobToText(responseBlob).pipe(
                _observableMergeMap((_responseText: string) => {
                    let result200: any = null;
                    let resultData200 =
                        _responseText === ""
                            ? null
                            : JSON.parse(_responseText, this.jsonParseReviver);
                    return _observableOf(result200);
                })
            );
        } else if (status !== 200 && status !== 204) {
            return blobToText(responseBlob).pipe(
                _observableMergeMap((_responseText: string) => {
                    return throwException(
                        "An unexpected server error occurred.",
                        status,
                        _responseText,
                        _headers
                    );
                })
            );
        }
        return _observableOf(null as any);
    }

    //Get Projects for DropDown
    GetProjectForDropdown(
        selectedId: string | null,
        projectStatus: number | null
    ): Observable<any> {
        let url_ =
            this.baseUrl + "/api/services/app/Project/GetProjectForDropdown?";
        if (selectedId !== null) {
            url_ += "selectedId=" + encodeURIComponent(selectedId) + "&";
        }
        if (projectStatus !== null) {
            url_ += "projectStatus=" + encodeURIComponent("" + projectStatus) + "&";
        }
        url_ = url_.replace(/[?&]$/, "");
        let options_: any = {
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                Accept: "text/plain",
                "Content-Type": "application/json",
            }),
        };

        return this.http
            .request("get", url_, options_)
            .pipe(
                _observableMergeMap((response_: any) => {
                    return this.processGetProjectsListForDropDown(response_);
                })
            )
            .pipe(
                _observableCatch((response_: any) => {
                    if (response_ instanceof HttpResponseBase) {
                        try {
                            return this.processGetProjectsListForDropDown(response_ as any);
                        } catch (e) {
                            return _observableThrow(e) as any as Observable<SelectedItems>;
                        }
                    } else
                        return _observableThrow(
                            response_
                        ) as any as Observable<SelectedItems>;
                })
            );
    }

    //Get Projects for Rebates
    GetProjectForRebatesDropdown(
        selectedId: string | null,
        projectStatus: number | null
    ): Observable<any> {
        let url_ = this.baseUrl + "/api/services/app/Project/GetProjectForRebates?";
        if (selectedId !== null) {
            url_ += "selectedId=" + encodeURIComponent(selectedId) + "&";
        }
        if (projectStatus !== null) {
            url_ += "projectStatus=" + encodeURIComponent("" + projectStatus) + "&";
        }
        url_ = url_.replace(/[?&]$/, "");
        let options_: any = {
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                Accept: "text/plain",
                "Content-Type": "application/json",
            }),
        };

        return this.http
            .request("get", url_, options_)
            .pipe(
                _observableMergeMap((response_: any) => {
                    return this.processGetProjectsListForDropDown(response_);
                })
            )
            .pipe(
                _observableCatch((response_: any) => {
                    if (response_ instanceof HttpResponseBase) {
                        try {
                            return this.processGetProjectsListForDropDown(response_ as any);
                        } catch (e) {
                            return _observableThrow(e) as any as Observable<SelectedItems>;
                        }
                    } else
                        return _observableThrow(
                            response_
                        ) as any as Observable<SelectedItems>;
                })
            );
    }

    //Get ProjectsDropDown for costing
    GetProjectForDropdownCosting(
        selectedId: string | null,
        projectStatus: number | null
    ): Observable<any> {
        let url_ =
            this.baseUrl + "/api/services/app/Project/GetProjectForDropdownCosting?";
        if (selectedId !== null) {
            url_ += "selectedId=" + encodeURIComponent(selectedId) + "&";
        }
        if (projectStatus !== null) {
            url_ += "projectStatus=" + encodeURIComponent("" + projectStatus) + "&";
        }
        url_ = url_.replace(/[?&]$/, "");
        let options_: any = {
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                Accept: "text/plain",
                "Content-Type": "application/json",
            }),
        };

        return this.http
            .request("get", url_, options_)
            .pipe(
                _observableMergeMap((response_: any) => {
                    return this.processGetProjectsListForDropDown(response_);
                })
            )
            .pipe(
                _observableCatch((response_: any) => {
                    if (response_ instanceof HttpResponseBase) {
                        try {
                            return this.processGetProjectsListForDropDown(response_ as any);
                        } catch (e) {
                            return _observableThrow(e) as any as Observable<SelectedItems>;
                        }
                    } else
                        return _observableThrow(
                            response_
                        ) as any as Observable<SelectedItems>;
                })
            );
    }

    protected processGetProjectsListForDropDown(
        response: HttpResponseBase
    ): Observable<any> {
        const status = response.status;
        const responseBlob =
            response instanceof HttpResponse
                ? response.body
                : (response as any).error instanceof Blob
                    ? (response as any).error
                    : undefined;

        let _headers: any = {};
        if (response.headers) {
            for (let key of response.headers.keys()) {
                _headers[key] = response.headers.get(key);
            }
        }
        if (status === 200) {
            return blobToText(responseBlob).pipe(
                _observableMergeMap((_responseText: string) => {
                    let result200: any = null;
                    let resultData200 =
                        _responseText === ""
                            ? null
                            : JSON.parse(_responseText, this.jsonParseReviver);
                    // result200 = SelectedItems.fromJS(resultData200)
                    return _observableOf(resultData200);
                    // return resultData200;
                })
            );
        } else if (status !== 200 && status !== 204) {
            return blobToText(responseBlob).pipe(
                _observableMergeMap((_responseText: string) => {
                    return throwException(
                        "An unexpected server error occurred.",
                        status,
                        _responseText,
                        _headers
                    );
                })
            );
        }
        return _observableOf(null as any);
    }

    // Get Summary OfLight Fitting Sp
    //  LFC = Light Fitting Cost
    GetSummaryOfLFCSP(ProjectCode: string): Observable<ISummaryofLFCSP> {
        let url_ =
            this.baseUrl +
            "/api/services/app/ProjectCosting/GetSummaryofLightFittingCostsSP?";
        if (ProjectCode !== undefined) {
            url_ += "ProjectCode=" + encodeURIComponent("" + ProjectCode) + "&";
        }
        url_ = url_.replace(/[?&]$/, "");
        let options_: any = {
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                Accept: "text/plain",
            }),
        };

        return this.http
            .request("get", url_, options_)
            .pipe(
                _observableMergeMap((response_: any) => {
                    return this.processGetSummaryOfLFCSP(response_);
                })
            )
            .pipe(
                _observableCatch((response_: any) => {
                    if (response_ instanceof HttpResponseBase) {
                        try {
                            return this.processGetSummaryOfLFCSP(response_ as any);
                        } catch (e) {
                            return _observableThrow(e) as any as Observable<ISummaryofLFCSP>;
                        }
                    } else
                        return _observableThrow(
                            response_
                        ) as any as Observable<ISummaryofLFCSP>;
                })
            );
    }

    protected processGetSummaryOfLFCSP(
        response: HttpResponseBase
    ): Observable<ISummaryofLFCSP> {
        const status = response.status;
        const responseBlob =
            response instanceof HttpResponse
                ? response.body
                : (response as any).error instanceof Blob
                    ? (response as any).error
                    : undefined;

        let _headers: any = {};
        if (response.headers) {
            for (let key of response.headers.keys()) {
                _headers[key] = response.headers.get(key);
            }
        }
        if (status === 200) {
            return blobToText(responseBlob).pipe(
                _observableMergeMap((_responseText: string) => {
                    let result200: any = null;
                    let resultData200 =
                        _responseText === ""
                            ? null
                            : JSON.parse(_responseText, this.jsonParseReviver);
                    return _observableOf(resultData200);
                })
            );
        } else if (status !== 200 && status !== 204) {
            return blobToText(responseBlob).pipe(
                _observableMergeMap((_responseText: string) => {
                    return throwException(
                        "An unexpected server error occurred.",
                        status,
                        _responseText,
                        _headers
                    );
                })
            );
        }
        return _observableOf(null as any);
    }

    deleteProject(id: number | undefined): Observable<void> {
        let url_ = this.baseUrl + "/api/services/app/Project/Delete?";
        if (id === null) throw new Error("The parameter 'id' cannot be null.");
        else if (id !== undefined)
            url_ += "Id=" + encodeURIComponent("" + id) + "&";
        url_ = url_.replace(/[?&]$/, "");

        let options_: any = {
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({}),
        };

        return this.http
            .request("delete", url_, options_)
            .pipe(
                _observableMergeMap((response_: any) => {
                    return this.processDelete(response_);
                })
            )
            .pipe(
                _observableCatch((response_: any) => {
                    if (response_ instanceof HttpResponseBase) {
                        try {
                            return this.processDelete(response_ as any);
                        } catch (e) {
                            return _observableThrow(e) as any as Observable<void>;
                        }
                    } else return _observableThrow(response_) as any as Observable<void>;
                })
            );
    }

    protected processDelete(response: HttpResponseBase): Observable<void> {
        const status = response.status;
        const responseBlob =
            response instanceof HttpResponse
                ? response.body
                : (response as any).error instanceof Blob
                    ? (response as any).error
                    : undefined;

        let _headers: any = {};
        if (response.headers) {
            for (let key of response.headers.keys()) {
                _headers[key] = response.headers.get(key);
            }
        }
        if (status === 200) {
            return blobToText(responseBlob).pipe(
                _observableMergeMap((_responseText: string) => {
                    return _observableOf(null as any);
                })
            );
        } else if (status !== 200 && status !== 204) {
            return blobToText(responseBlob).pipe(
                _observableMergeMap((_responseText: string) => {
                    return throwException(
                        "An unexpected server error occurred.",
                        status,
                        _responseText,
                        _headers
                    );
                })
            );
        }
        return _observableOf(null as any);
    }

    //  UpdateProject
    UpdateProject(obj: IUpdateProjectDto): Observable<number> {
        let url_ = this.baseUrl + "/api/services/app/Project/UpdateProjects";
        url_ = url_.replace(/[?&]$/, "");

        // const content_ = {
        //   jsonData: data,
        //   teamMembers: arr,
        // };

        let options_: any = {
            body: obj,
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                "Content-Type": "application/json-patch+json",
                Accept: "text/plain",
            }),
        };
        return this.http
            .request("put", url_, options_)
            .pipe(
                _observableMergeMap((response_: any) => {
                    return this.processGeneric<any>(response_);
                })
            )
            .pipe(
                _observableCatch((response_: any) => {
                    if (response_ instanceof HttpResponseBase) {
                        try {
                            return this.processGeneric<any>(response_ as any);
                        } catch (e) {
                            return _observableThrow(e) as any as Observable<number>;
                        }
                    } else
                        return _observableThrow(response_) as any as Observable<number>;
                })
            );
    }

    protected processGeneric<T>(response: HttpResponseBase): Observable<T> {
        const status = response.status;
        const responseBlob =
            response instanceof HttpResponse
                ? response.body
                : (response as any).error instanceof Blob
                    ? (response as any).error
                    : undefined;

        let _headers: any = {};
        if (response.headers) {
            for (let key of response.headers.keys()) {
                _headers[key] = response.headers.get(key);
            }
        }
        if (status === 200) {
            return blobToText(responseBlob).pipe(
                _observableMergeMap((_responseText: string) => {
                    let resultData200 =
                        _responseText === ""
                            ? null
                            : JSON.parse(_responseText, this.jsonParseReviver);
                    return _observableOf(resultData200);
                })
            );
        } else if (status !== 200 && status !== 204) {
            return blobToText(responseBlob).pipe(
                _observableMergeMap((_responseText: string) => {
                    return throwException(
                        "An unexpected server error occurred.",
                        status,
                        _responseText,
                        _headers
                    );
                })
            );
        }
        return _observableOf(null as any);
    }

    //  UpdateWarranty
    updateWarrantyEndDate(data: IUpdateWarrantEndDate): Observable<boolean> {
        let url_ =
            this.baseUrl +
            "/api/services/app/WarrantyCostAppServices/UpdateWarrantyCostEndDate";
        url_ = url_.replace(/[?&]$/, "");

        let options_: any = {
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                "Content-Type": "application/json-patch+json",
                Accept: "text/plain",
            }),
        };
        return this.http
            .put(url_, data, options_)
            .pipe(
                _observableMergeMap((response_: any) => {
                    return this.processGeneric<any>(response_);
                })
            )
            .pipe(
                _observableCatch((response_: any) => {
                    if (response_ instanceof HttpResponseBase) {
                        try {
                            return this.processGeneric<any>(response_ as any);
                        } catch (e) {
                            return _observableThrow(e) as any as Observable<boolean>;
                        }
                    } else
                        return _observableThrow(response_) as any as Observable<boolean>;
                })
            );
    }

    GetProjectForGuaranteesAndInvoices(): Observable<any> {
        let url_ = `${this.baseUrl}/api/services/app/Project/GetProjectForGuaranteesAndInvoices`;
        let options_: any = {
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                Accept: "text/plain",
            }),
        };
        return this.http.get(url_, options_).pipe(
            mergeMap((response: any) =>
                this.processGeneric<any>(response)
            ),
            catchError((error: any) => {
                if (error instanceof HttpResponseBase) {
                    return this.processGeneric<any>(error as any);
                } else {
                    return throwError(() => error);
                }
            })
        );
    }

     // Get user by roles for Project TEam Section
     GetUserByRoles(data: IGetUserByRoleNameRequest): Observable<IGetUserByRoleNameResponse[]> {
        let url_ = `${this.baseUrl}/api/services/app/User/GetUserByRole`;
        let body = data;

        let options_: any = {
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                Accept: "text/plain",
            }),
        };

        return this.http.post(url_, body, options_).pipe(
            mergeMap((response: any) => this.processGeneric<IGetUserByRoleNameResponse[]>(response)),
            catchError((error: any) => {
                if (error instanceof HttpResponseBase) {
                    return this.processGeneric<IGetUserByRoleNameResponse[]>(error as any);
                } else {
                    return throwError(() => error);
                }
            })
        );
    }
}

@Injectable()
export class ImportProjectServices extends GenericService {
    private http: HttpClient;
    private baseUrl: string;
    protected jsonParseReviver: ((key: string, value: any) => any) | undefined =
        undefined;

    constructor(
        @Inject(HttpClient) http: HttpClient,
        @Optional() @Inject(API_BASE_URL) baseUrl?: string
    ) {
        super();
        this.http = http;
        this.baseUrl = baseUrl !== undefined && baseUrl !== null ? baseUrl : "";
    }

    // delete api on discard import dialog
    DeleteProjectCostingTemp(projectCode: string | undefined): Observable<any> {
        let url_ =
            this.baseUrl +
            "/api/services/app/ProjectCosting/DeleteProjectCostingTemp?";
        if (projectCode === null)
            throw new Error("The parameter 'Project Code' cannot be null.");
        else if (projectCode !== undefined)
            url_ += "ProjectCode=" + encodeURIComponent("" + projectCode) + "&";
        url_ = url_.replace(/[?&]$/, "");

        let options_: any = {
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({}),
        };

        return this.http
            .request("delete", url_, options_)
            .pipe(
                _observableMergeMap((response_: any) => {
                    return this.processGeneric(response_);
                })
            )
            .pipe(
                _observableCatch((response_: any) => {
                    if (response_ instanceof HttpResponseBase) {
                        try {
                            return this.processGeneric(response_ as any);
                        } catch (e) {
                            return _observableThrow(e) as any as Observable<any>;
                        }
                    } else return _observableThrow(response_) as any as Observable<any>;
                })
            );
    }

    /* Get Project Costing Temp through Sp*/
    GetAllProjectCostingTemp(
        keyword: string,
        sorting: string,
        skipCount: number | 0,
        maxResultCount: number | 10
    ): Observable<ProjectCostingTempPagedResultDto> {
        let url_ =
            this.baseUrl + "/api/services/app/Project/GetAllProjectCostingTemp?";
        url_ += "Keyword=" + encodeURIComponent("" + keyword) + "&";
        url_ += "sorting=" + encodeURIComponent("" + sorting) + "&";
        url_ += "SkipCount=" + encodeURIComponent("" + skipCount) + "&";
        url_ += "MaxResultCount=" + encodeURIComponent("" + maxResultCount) + "&";
        url_ = url_.replace(/[?&]$/, "");

        let options_: any = {
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                Accept: "text/plain",
            }),
        };

        return this.http
            .request("get", url_, options_)
            .pipe(
                _observableMergeMap((response_: any) => {
                    return this.processGetAllProjectCostingTemp(response_);
                })
            )
            .pipe(
                _observableCatch((response_: any) => {
                    if (response_ instanceof HttpResponseBase) {
                        try {
                            return this.processGetAllProjectCostingTemp(response_ as any);
                        } catch (e) {
                            return _observableThrow(
                                e
                            ) as any as Observable<ProjectCostingTempPagedResultDto>;
                        }
                    } else
                        return _observableThrow(
                            response_
                        ) as any as Observable<ProjectCostingTempPagedResultDto>;
                })
            );
    }
    protected processGetAllProjectCostingTemp(
        response: HttpResponseBase
    ): Observable<ProjectCostingTempPagedResultDto> {
        const status = response.status;
        const responseBlob =
            response instanceof HttpResponse
                ? response.body
                : (response as any).error instanceof Blob
                    ? (response as any).error
                    : undefined;

        let _headers: any = {};
        if (response.headers) {
            for (let key of response.headers.keys()) {
                _headers[key] = response.headers.get(key);
            }
        }
        if (status === 200) {
            return blobToText(responseBlob).pipe(
                _observableMergeMap((_responseText: string) => {
                    let result200: any = null;
                    let resultData200 =
                        _responseText === ""
                            ? null
                            : JSON.parse(_responseText, this.jsonParseReviver);
                    result200 = ProjectCostingTempPagedResultDto.fromJS(resultData200);
                    return _observableOf(result200);
                })
            );
        } else if (status !== 200 && status !== 204) {
            return blobToText(responseBlob).pipe(
                _observableMergeMap((_responseText: string) => {
                    return throwException(
                        "An unexpected server error occurred.",
                        status,
                        _responseText,
                        _headers
                    );
                })
            );
        }
        return _observableOf(null as any);
    }

    /*IMPORT PROJECT*/
    ImportProjects(file: File): Observable<boolean> {
        const formData = new FormData();
        formData.append("file", file);
        let url_ = this.baseUrl + "/api/services/app/Project/ImportProjects";
        url_ = url_.replace(/[?&]$/, "");

        let options_: any = {
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                Accept: "text/plain",
            }),
        };

        return this.http
            .post(url_, formData, options_)
            .pipe(
                _observableMergeMap((response_: any) => {
                    return this.ImportProjectsInDB(response_);
                })
            )
            .pipe(
                _observableCatch((response_: any) => {
                    if (response_ instanceof HttpResponseBase) {
                        try {
                            return this.ImportProjectsInDB(response_ as any);
                        } catch (e) {
                            return _observableThrow(e) as any as Observable<boolean>;
                        }
                    } else
                        return _observableThrow(response_) as any as Observable<boolean>;
                })
            );
    }

    protected ImportProjectsInDB(
        response: HttpResponseBase
    ): Observable<boolean> {
        const status = response.status;
        const responseBlob =
            response instanceof HttpResponse
                ? response.body
                : (response as any).error instanceof Blob
                    ? (response as any).error
                    : undefined;

        let _headers: any = {};
        if (response.headers) {
            for (let key of response.headers.keys()) {
                _headers[key] = response.headers.get(key);
            }
        }
        if (status === 200) {
            return of(true);
        } else if (status !== 200 && status !== 204) {
            return blobToText(responseBlob).pipe(
                _observableMergeMap((_responseText: string) => {
                    return throwException(
                        "An unexpected server error occurred.",
                        status,
                        _responseText,
                        _headers
                    );
                })
            );
        }
        return _observableOf(null as any);
    }

    getImportSubTotal(): Observable<IGetImportSubTotal> {
        let url_ = `${this.baseUrl}/api/services/app/ProjectCosting/GetImportProjectSubTotal`;

        let options_: any = {
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                Accept: "text/plain",
            }),
        };

        return this.http.get(url_, options_).pipe(
            mergeMap((response: any) =>
                this.processGeneric<IGetImportSubTotal>(response)
            ),
            catchError((error: any) => {
                if (error instanceof HttpResponseBase) {
                    return this.processGeneric<IGetImportSubTotal>(error as any);
                } else {
                    return throwError(() => error);
                }
            })
        );
    }



}
// Interfaces And Classes
//Get Dtos

export interface IGetUserByRoleNameResponse {
    id: number;
    roleId: number;
    firstName: string;
    surName: string;
    fullName: string;
    emailAddress: string;
    roleName: string;
    roleDisplayName: string;
  }

  export  interface IGetUserByRoleNameRequest {
  roleName: string;
}
export interface IGetImportSubTotal {
    costMaterialMCT: number;
    costLabourLCT: number;
    lftct: number;
    sellingMaterialMST: number;
    sellingLabourLST: number;
    combinedSellingCST: number;
}

export interface IUpdateWarrantEndDate {
    projectCode: string;
    warrantyEndDate: string;
    warrantyStartDate: string;
}

export interface IGetProjectDto {
    id: number;
    ProjectCode: string;
    ProjectName: string;
    ProjectType: string;
    Comments: string;
    DataEntered: string;
    Builder: string;
    //Address
    Address: string;
    Suburb: string;
    State: string;
    PostCode: string;
    //Teams
    /*OpsManager: UserDto;*/
    //ProjectCoordinator: UserDto;
    //LeadingHands: UserDto;
    //SiteAdministrator: UserDto;
    //SiteManager: UserDto;
    // CommunciationLead: string;
    // SecurityLead: string;
    TeamMember: string;
    // Warranty
    WarrantyPhase: boolean;
    WarrantyStartDate: moment.Moment | any;
    WarrantyEndDate: moment.Moment | any;
    WarrantyPeriod: number;
    Budget: number;
    //Planning
    StartDate: moment.Moment | any;
    EstCompletionDate: moment.Moment | any;
    AcceptedContractValue: number;
    ParentProject: string;
    //Additional Informatoin
    SignOfDeeds: moment.Moment | any;
    BlanketOrders: boolean;
    DailySms: boolean;
    AssetLocationOnly: boolean;
    ToolAudits: boolean;
    SpwReporting: boolean;
    ToolForecasts: boolean;
    ToolIndex: number;
    SAHReporting: boolean;
    AssetLocnOnly: string;
    DefPerson: string;
    AssetLocn: string;
    projectStatus: number;
    projectCategory: number;
    longitutde: string,
    latitude: string
}
export class GetProjectDto implements IGetProjectDto {
    id: number;
    ProjCode: string;
    ProjectCode: string;
    ProjectName: string;
    ProjectType: string;
    projectStatus: number;
    projectCategory: number;
    Comments: string;
    DataEntered: string;
    Builder: string;
    //Address
    Address: string;
    Suburb: string;
    State: string;
    PostCode: string;
    //Teams
    OpsManagerId: number;
    OpsManagerFullName: string;
    ProjectCoordinatorId: number;
    ProjectCoordinatorFullName: string;
    LeadingHandsId: number;
    LeadingHandsFullName: string;
    SiteAdministratorId: number;
    SiteAdministratorFullName: string;
    SiteManagerId: number;
    SiteManagerFullName: string;
    CommunciationLead: string;
    SecurityLead: string;
    TeamMember: string;
    // Warranty
    WarrantyPhase: boolean;
    WarrantyStartDate: moment.Moment;
    WarrantyEndDate: moment.Moment;
    WarrantyPeriod: number;
    Budget: number;
    //Planning
    StartDate: moment.Moment;
    EstCompletionDate: moment.Moment;
    AcceptedContractValue: number;
    ParentProject: string;
    //Additional Informatoin
    SignOfDeeds: moment.Moment;
    BlanketOrders: boolean;
    DailySms: boolean;
    AssetLocationOnly: boolean;
    ToolAudits: boolean;
    SpwReporting: boolean;
    ToolForecasts: boolean;
    ToolIndex: number;
    SAHReporting: boolean;
    AssetLocnOnly: string;
    DefPerson: string;
    AssetLocn: string;
    longitutde: string;
    latitude: string

    constructor(data?: IGetProjectDto) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }
    init(_data?: any) {
        if (_data) {
            this.id = _data["id"];
            this.ProjCode = _data["projCode"];
            this.ProjectCode = _data["projectCode"];
            this.ProjectName = _data["projectName"];
            this.ProjectType = _data["projectType"];
            this.Builder = _data["builder"];
            this.projectStatus = _data["projectStatus"];
            // this.projectStatus = (_data["projectStatus"] == 0) ? "InProgress" : "Completed";
            this.projectCategory = _data["projectCategory"];
            // this.projectCategory = (_data["projectCategory"] == 0) ? "Appartments" : (_data["projectCategory"] == 1) ? "Commercial" : "Hospitals";
            this.Comments = _data["comments"];
            this.DataEntered = _data["dataEntered"];
            this.Address = _data["address"];
            this.Suburb = _data["suburb"];
            this.State = _data["state"];
            this.PostCode = _data["postCode"];
            this.OpsManagerId = _data["opsManagerId"];
            this.OpsManagerFullName = _data["opsManagerName"];
            this.ProjectCoordinatorId = _data["projectCoordinatorId"];
            this.ProjectCoordinatorFullName = _data["projectCoordinatorName"];
            this.LeadingHandsId = _data["leadingHandsId"];
            this.LeadingHandsFullName = _data["leadingHandsName"];
            this.SiteAdministratorId = _data["siteAdministratorId"];
            this.SiteAdministratorFullName = _data["siteAdministratorName"];
            this.SiteManagerId = _data["siteManagerId"];
            this.SiteManagerFullName = _data["siteManagerName"];
            this.CommunciationLead = _data["communciationLead"];
            this.SecurityLead = _data["securityLead"];
            this.TeamMember = _data["teamMember"];
            this.WarrantyPhase = _data["warrantyPhase"];
            this.WarrantyStartDate = _data["warrantyStartDate"];
            this.WarrantyEndDate = _data["warrantyEndDate"];
            this.WarrantyPeriod = _data["warrantyPeriod"];
            this.Budget = _data["budget"];
            this.StartDate = _data["startDate"];
            this.EstCompletionDate = _data["estCompletionDate"];
            this.AcceptedContractValue = _data["acceptedContractValue"];
            this.ParentProject = _data["parentProject"];
            this.SignOfDeeds = _data["signOfDeeds"];
            this.BlanketOrders = _data["blanketOrders"];
            this.DailySms = _data["dailySms"];
            this.AssetLocationOnly = _data["assetLocationOnly"];
            this.ToolAudits = _data["toolAudits"];
            this.SpwReporting = _data["spwReporting"];
            this.ToolForecasts = _data["toolForecasts"];
            this.ToolIndex = _data["toolIndex"];
            this.SAHReporting = _data["sahReporting"];
            this.AssetLocnOnly = _data["assetLocnOnly"];
            this.DefPerson = _data["defPerson"];
            this.AssetLocn = _data["assetLocn"];
            this.latitude=_data["latitude"];
            this.longitutde=_data["longitutde"]
        }
    }
    static fromJS(data: any): GetProjectDto {
        data = typeof data === "object" ? data : {};
        let result = new GetProjectDto();
        result.init(data);
        return result;
    }
    toJSON(data?: any) {
        data = typeof data === "object" ? data : {};
        data["id"] = this.id;
        data["projectCode"] = this.ProjectCode;
        data["projectName"] = this.ProjectName;
        data["projectType"] = this.ProjectType;
        data["builder"] = this.Builder;
        data["projectStatus"] = this.projectStatus;
        data["projectCategory"] = this.projectCategory;
        data["comments"] = this.Comments;
        data["dataEntered"] = this.DataEntered;
        data["address"] = this.Address;
        data["suburb"] = this.Suburb;
        data["state"] = this.State;
        data["postCode"] = this.PostCode;
        //data["opsManagerId"] = this.OpsManager.id;
        //data["opsManagerName"] = this.OpsManager.fullName;
        //data["projectCoordinatorId"] = this.ProjectCoordinator.id;
        //data["projectCoordinatorName"] = this.ProjectCoordinator.fullName;
        //data["leadingHandsId"] = this.LeadingHands.id;
        //data["leadingHandsName"] = this.LeadingHands.fullName;
        //data["siteAdministratorId"] = this.SiteAdministrator.id;
        //data["siteAdministratorName"] = this.SiteAdministrator.fullName;
        //data["siteManagerId"] = this.SiteManager.id;
        //data["siteManagerName"] = this.SiteManager.fullName;
        data["communciationLead"] = this.CommunciationLead;
        data["securityLead"] = this.SecurityLead;
        data["warrantyPhase"] = this.WarrantyPhase;
        data["warrantyStartDate"] = this.WarrantyStartDate;
        data["warrantyEndDate"] = this.WarrantyEndDate;
        data["warrantyPeriod"] = this.WarrantyPeriod;
        data["budget"] = this.Budget;
        data["startDate"] = this.StartDate;
        data["estCompletionDate"] = this.EstCompletionDate;
        data["acceptedContractValue"] = this.AcceptedContractValue;
        data["parentProject"] = this.ParentProject;
        data["signOfDeeds"] = this.SignOfDeeds;
        data["blanketOrders"] = this.BlanketOrders;
        data["dailySms"] = this.DailySms;
        data["assetLocationOnly"] = this.AssetLocationOnly;
        data["toolAudits"] = this.ToolAudits;
        data["spwReporting"] = this.SpwReporting;
        data["toolForecasts"] = this.ToolForecasts;
        data["toolIndex"] = this.ToolIndex;
        data["sAHReporting"] = this.SAHReporting;
        data["assetLocnOnly"] = this.AssetLocnOnly;
        data["defPerson"] = this.DefPerson;
        data["assetLocn"] = this.AssetLocn;
        return data;
    }
    clone(): GetProjectDto {
        const json = this.toJSON();
        let result = new GetProjectDto();
        result.init(json);
        return result;
    }
}

// PagedResultRequestDto
export interface IProjectPagedResultDto {
    items: GetProjectDto[] | undefined;
    totalCount: number;
}
export class ProjectPagedResultDto implements IProjectPagedResultDto {
    items: GetProjectDto[] | undefined;
    totalCount: number;
    constructor(data?: IProjectPagedResultDto) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            if (Array.isArray(_data["items"])) {
                this.items = [] as any;
                for (let item of _data["items"])
                    this.items.push(GetProjectDto.fromJS(item));
            }
            this.totalCount = _data["totalCount"];
        }
    }

    static fromJS(data: any): ProjectPagedResultDto {
        data = typeof data === "object" ? data : {};
        let result = new ProjectPagedResultDto();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === "object" ? data : {};
        if (Array.isArray(this.items)) {
            data["items"] = [];
            for (let item of this.items) data["items"].push(item.toJSON());
        }
        data["totalCount"] = this.totalCount;
        return data;
    }

    clone(): ProjectPagedResultDto {
        const json = this.toJSON();
        let result = new ProjectPagedResultDto();
        result.init(json);
        return result;
    }
}

// Create Dto
export interface ICreateProjectDto {
    projCode: string;
    projName: string;
    projType: string;
    projectStatus: number;
    projectCategory: number;
    builder: string;
    //Address
    address: string;
    suburb: string;
    state: string;
    postcode: string;
    //Teams
    // operManagerId: number;
    // projectCoordinatorId: number;
    // leadingHandsId: number;
    // siteAdministratorId: number;
    // siteManagerId: number;
    // communications: number;
    // security: number;
    teamMembers?: ITeamAreaWithoutName[];
    // Warranty
    warrantyPhase: boolean;
    warrantyStartDate: moment.Moment;
    warrantyEndDate: moment.Moment;
    warrantyPeriod: number;
    budget: number;
    //Planning
    startDate: string;
    estComplDate: moment.Moment;
    acceptedContractValue: number;
    parentProject: string;
    //Additional Informatoin
    deedOfReleaseSigned: moment.Moment;
    blanketOrds: boolean;
    sms: boolean;
    assetLocationOnly: boolean;
    includeForToolAudits: boolean;
    spwreporting: boolean;
    toolForecasting: boolean;
    sahreporting: boolean;
    longitude: string,
    latitude: string
}
export class CreateProjectDto implements ICreateProjectDto {
    projCode: string;
    projName: string;
    projType: string;
    projectStatus: number;
    projectCategory: number;
    builder: string;
    //Address
    address: string;
    suburb: string;
    state: string;
    postcode: string;
    //Teams
    // operManagerId: number;
    // projectCoordinatorId: number;
    // leadingHandsId: number;
    // siteAdministratorId: number;
    // siteManagerId: number;
    // communications: number;
    // security: number;
    teamMembers: ITeamAreaWithoutName[] = [];
    // Warranty
    warrantyPhase: boolean;
    warrantyStartDate: moment.Moment;
    warrantyEndDate: moment.Moment;
    warrantyPeriod: number;
    budget: number;
    //Planning
    startDate: string;
    estComplDate: moment.Moment;
    acceptedContractValue: number;
    parentProject: string;
    //Additional Informatoin
    deedOfReleaseSigned: moment.Moment;
    blanketOrds: boolean;
    sms: boolean;
    assetLocationOnly: boolean;
    includeForToolAudits: boolean;
    spwreporting: boolean;
    toolForecasting: boolean;
    sahreporting: boolean;
    longitude: string;
    latitude: string;
    constructor(data?: ICreateProjectDto) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }


    // init(_data?: any) {
    //     if (_data) {
    //         this.ProjectCode = _data["projectCode"];
    //         this.ProjectName = _data["projectName"];
    //         this.ProjectType = _data["projectType"];
    //         this.Address = _data["address"];
    //         this.Suburb = _data["suburb"];
    //         this.State = _data["state"];
    //         this.PostCode = _data["postCode"];
    //         this.OpsManagerId = _data["opsManagerId"];
    //         this.ProjectCoordinatorId = _data["projectCoordinatorId"];
    //         this.LeadingHandsId = _data["leadingHandsId"];
    //         this.SiteAdministratorId = _data["siteAdministratorId"];
    //         this.SiteManagerId = _data["siteManagerId"];
    //         this.CommunciationLead = _data["communciationLead"];
    //         this.SecurityLead = _data["securityLead"];
    //         this.WarrantyPhase = _data["warrantyPhase"];
    //         this.WarrantyStartDate = _data["warrantyStartDate"];
    //         this.WarrantyEndDate = _data["warrantyEndDate"];
    //         this.WarrantyPeriod = _data["warrantyPeriod"];
    //         this.Budget = _data["budget"];
    //         this.StartDate = _data["startDate"];
    //         this.EstCompletionDate = _data["estCompletionDate"];
    //         this.AcceptedContractValue = _data["acceptedContractValue"];
    //         this.ParentProject = _data["parentProject"];
    //         this.SignOfDeeds = _data["signOfDeeds"];
    //         this.BlanketOrders = _data["blanketOrders"];
    //         this.DailySms = _data["dailySms"];
    //         this.AssetLocationOnly = _data["assetLocationOnly"];
    //         this.ToolAudits = _data["toolAudits"];
    //         this.SpwReporting = _data["spwReporting"];
    //         this.ToolForecasts = _data["toolForecasts"];
    //         this.SahReporting = _data["sahReporting"];
    //     }
    // }

    // static fromJS(data: any): CreateProjectDto {
    //     data = typeof data === "object" ? data : {};
    //     let result = new CreateProjectDto();
    //     result.init(data);
    //     return result;
    // }

    // toJSON(data?: any) {
    //     data = typeof data === "object" ? data : {};
    //     data["projectCode"] = this.ProjectCode;
    //     data["projectName"] = this.ProjectName;
    //     data["projectType"] = this.ProjectType;
    //     data["address"] = this.Address;
    //     data["suburb"] = this.Suburb;
    //     data["state"] = this.State;
    //     data["postCode"] = this.PostCode;
    //     data["opsManagerId"] = this.OpsManagerId;
    //     data["projectCoordinatorId"] = this.ProjectCoordinatorId;
    //     data["leadingHandsId"] = this.LeadingHandsId;
    //     data["siteAdministratorId"] = this.SiteAdministratorId;
    //     data["siteManagerId"] = this.SiteManagerId;
    //     data["communciationLead"] = this.CommunciationLead;
    //     data["securityLead"] = this.SecurityLead;
    //     data["warrantyPhase"] = this.WarrantyPhase;
    //     data["warrantyStartDate"] = this.WarrantyStartDate;
    //     data["warrantyEndDate"] = this.WarrantyEndDate;
    //     data["warrantyPeriod"] = this.WarrantyPeriod;
    //     data["budget"] = this.Budget;
    //     data["startDate"] = this.StartDate;
    //     data["estCompletionDate"] = this.EstCompletionDate;
    //     data["acceptedContractValue"] = this.AcceptedContractValue;
    //     data["parentProject"] = this.ParentProject;
    //     data["signOfDeeds"] = this.SignOfDeeds;
    //     data["blanketOrders"] = this.BlanketOrders;
    //     data["dailySms"] = this.DailySms;
    //     data["assetLocationOnly"] = this.AssetLocationOnly;
    //     data["toolAudits"] = this.ToolAudits;
    //     data["spwReporting"] = this.SpwReporting;
    //     data["toolForecasts"] = this.ToolForecasts;
    //     data["sahReporting"] = this.SahReporting;
    //     return data;
    // }

    // clone(): CreateProjectDto {
    //     const json = this.toJSON();
    //     let result = new CreateProjectDto();
    //     result.init(json);
    //     return result;
    // }
}

//update project Dto
export interface IUpdateProjectDto {
    jsonData: string;
    teamMembers: ITeamAreaWithoutName[] | string[];
    isListingEdit: boolean;
}

// Project Costing Temp Dto
export interface IGetProjectCostingTempSpDto {
    Id: number;
    ProjCode: string;
    Area: string;
    PrimaryActivity: string;
    SecondaryActivity: string;
    Item: string;
    VariationNo: number;
    CostCentreMccc: number;
    Qmt: number;
    CostMaterialMcr: number;
    M: number;
    CostMaterialMct: number;
    Mtdat: number;
    Mfct: number;
    PoNo: number;
    CostCentreLccc: number;
    HoursLabourQlt: number;
    CostLabRateLcr: number;
    L: number;
    CostLabourLct: number;
    Ltdat: number;
    Lc: number;
    Ltdct: number;
    Lftct: number;
    SellingMaterialMsr: number;
    SellingMaterialMst: number;
    SellingLabourLsr: number;
    SellingLabourLst: number;
    CombinedSellingCst: number;
    EstimatedProfit: number;
    ProfitToDate: number;
    ForecastProfit: number;
    Comment: string;
    Description: string;
    Attachments: string;
    DateTime: moment.Moment;
    Paseq: number;
    Srch: string;
}
export class GetProjectCostingTempSpDto implements IGetProjectCostingTempSpDto {
    Id: number;
    ProjCode: string;
    Area: string;
    PrimaryActivity: string;
    SecondaryActivity: string;
    Item: string;
    VariationNo: number;
    CostCentreMccc: number;
    Qmt: number;
    CostMaterialMcr: number;
    M: number;
    CostMaterialMct: number;
    Mtdat: number;
    Mfct: number;
    PoNo: number;
    CostCentreLccc: number;
    HoursLabourQlt: number;
    CostLabRateLcr: number;
    L: number;
    CostLabourLct: number;
    Ltdat: number;
    Lc: number;
    Ltdct: number;
    Lftct: number;
    SellingMaterialMsr: number;
    SellingMaterialMst: number;
    SellingLabourLsr: number;
    SellingLabourLst: number;
    CombinedSellingCst: number;
    EstimatedProfit: number;
    ProfitToDate: number;
    ForecastProfit: number;
    Comment: string;
    Description: string;
    Attachments: string;
    DateTime: moment.Moment;
    Paseq: number;
    Srch: string;

    constructor(data?: IGetProjectCostingTempSpDto) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }
    init(_data?: any) {
        if (_data) {
            this.Id = _data["id"];
            this.ProjCode = _data["projCode"];
            this.Area = _data["area"];
            this.PrimaryActivity = _data["primaryActivity"];
            this.SecondaryActivity = _data["secondaryActivity"];
            this.Item = _data["item"];
            this.VariationNo = _data["variationNo"];
            this.CostCentreMccc = _data["costCentreMccc"];
            this.Qmt = _data["qmt"];
            this.CostMaterialMcr = _data["costMaterialMcr"];
            this.M = _data["m"];
            this.CostMaterialMct = _data["costMaterialMct"];
            this.Mtdat = _data["mtdat"];
            this.Mfct = _data["mfct"];
            this.PoNo = _data["poNo"];
            this.CostCentreLccc = _data["costCentreLccc"];
            this.HoursLabourQlt = _data["hoursLabourQlt"];
            this.CostLabRateLcr = _data["costLabRateLcr"];
            this.L = _data["l"];
            this.CostLabourLct = _data["costLabourLct"];
            this.Ltdat = _data["ltdat"];
            this.Lc = _data["lc"];
            this.Ltdct = _data["ltdct"];
            this.Lftct = _data["lftct"];
            this.SellingMaterialMsr = _data["sellingMaterialMsr"];
            this.SellingMaterialMst = _data["sellingMaterialMst"];
            this.SellingLabourLsr = _data["sellingLabourLsr"];
            this.SellingLabourLst = _data["sellingLabourLst"];
            this.CombinedSellingCst = _data["combinedSellingCst"];
            this.EstimatedProfit = _data["estimatedProfit"];
            this.ProfitToDate = _data["profitToDate"];
            this.ForecastProfit = _data["forecastProfit"];
            this.Comment = _data["comment"];
            this.Description = _data["description"];
            this.Attachments = _data["attachments"];
            this.DateTime = _data["dateTime"];
            this.Paseq = _data["paseq"];
            this.Srch = _data["srch"];
        }
    }

    static fromJS(data: any): GetProjectCostingTempSpDto {
        data = typeof data === "object" ? data : {};
        let result = new GetProjectCostingTempSpDto();
        result.init(data);
        return result;
    }

    toJSON(_data?: any) {
        _data["id"] = this.Id;
        _data["projCode"] = this.ProjCode;
        _data["area"] = this.Area;
        _data["primaryActivity"] = this.PrimaryActivity;
        _data["secondaryActivity"] = this.SecondaryActivity;
        _data["item"] = this.Item;
        _data["variationNo"] = this.VariationNo;
        _data["costCentreMccc"] = this.CostCentreMccc;
        _data["qmt"] = this.Qmt;
        _data["costMaterialMcr"] = this.CostMaterialMcr;
        _data["m"] = this.M;
        _data["costMaterialMct"] = this.CostMaterialMct;
        _data["mtdat"] = this.Mtdat;
        _data["mfct"] = this.Mfct;
        _data["poNo"] = this.PoNo;
        _data["costCentreLccc"] = this.CostCentreLccc;
        _data["hoursLabourQlt"] = this.HoursLabourQlt;
        _data["costLabRateLcr"] = this.CostLabRateLcr;
        _data["l"] = this.L;
        _data["costLabourLct"] = this.CostLabourLct;
        _data["ltdat"] = this.Ltdat;
        _data["lc"] = this.Lc;
        _data["ltdct"] = this.Ltdct;
        _data["lftct"] = this.Lftct;
        _data["sellingMaterialMsr"] = this.SellingMaterialMsr;
        _data["sellingMaterialMst"] = this.SellingMaterialMst;
        _data["sellingLabourLsr"] = this.SellingLabourLsr;
        _data["sellingLabourLst"] = this.SellingLabourLst;
        _data["combinedSellingCst"] = this.CombinedSellingCst;
        _data["estimatedProfit"] = this.EstimatedProfit;
        _data["profitToDate"] = this.ProfitToDate;
        _data["forecastProfit"] = this.ForecastProfit;
        _data["comment"] = this.Comment;
        _data["description"] = this.Description;
        _data["attachments"] = this.Attachments;
        _data["dateTime"] = this.DateTime;
        _data["paseq"] = this.Paseq;
        _data["srch"] = this.Srch;
        return _data;
    }

    clone(): GetProjectCostingTempSpDto {
        const json = this.toJSON();
        let result = new GetProjectCostingTempSpDto();
        result.init(json);
        return result;
    }
}

export interface IProjectCostingTempPagedResultDto {
    items: GetProjectCostingTempSpDto[] | undefined;
    totalCount: number;
}
export class ProjectCostingTempPagedResultDto
    implements IProjectCostingTempPagedResultDto {
    items: GetProjectCostingTempSpDto[] | undefined;
    totalCount: number;
    constructor(data?: IProjectPagedResultDto) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            if (Array.isArray(_data["items"])) {
                this.items = [] as any;
                for (let item of _data["items"])
                    this.items.push(GetProjectCostingTempSpDto.fromJS(item));
            }
            this.totalCount = _data["totalCount"];
        }
    }

    static fromJS(data: any): ProjectCostingTempPagedResultDto {
        data = typeof data === "object" ? data : {};
        let result = new ProjectCostingTempPagedResultDto();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === "object" ? data : {};
        if (Array.isArray(this.items)) {
            data["items"] = [];
            for (let item of this.items) data["items"].push(item.toJSON());
        }
        data["totalCount"] = this.totalCount;
        return data;
    }

    clone(): ProjectCostingTempPagedResultDto {
        const json = this.toJSON();
        let result = new ProjectCostingTempPagedResultDto();
        result.init(json);
        return result;
    }
}

export interface ISummaryofLFCSP {
    items: ISummaryofLFCSPItems[];
    totalCount: number;
}

export interface ISummaryofLFCSPItems {
    projectCode: string;
    materialsCostCentre: number;
    materialTenderCost: number;
    materialToDateCost: number;
    materialForecastAdditionalCost: number;
    materialExpectedCost: number;
    sumOfpo_order_total: number;
    netChange: number;
    updatedPOValue: number;
}


export enum DepartmentType {
    SiteManager = 'site',
    SiteAdministrator = 'adm',
    ProjectCoordinator = 'coor',
    OpsManager='ops',
    CommsLead= 'comm',
    LeadingHand = 'lead',
    SecurityLead = 'security'
  }
  export interface IManagerId{
    id:number;
    roleId:number;
  }

  export interface IUserResponse {
    role_id: number;
    user_id: number;
    user_Name: string;
    area: string;
  }

  export interface ITeamArea{
    id:number;
    name:string;
    area:string | null,
    roleId:number
  }

  export interface ITeamAreaWithoutName extends Omit<ITeamArea, 'name'> {}

  export interface ILatLong {
    longitude: number;
    latitude: number;
  }

  export interface ICreateProjectTeam {
    teamMembers: ITeamAreaWithoutName[];
    // project: IGetProjectDto;
    projectCode: string;
    projectId: number;
  }

  export interface IUpdateProjectTeam {
    jsonData: string;
    teamMembers: ITeamAreaWithoutName[];
    isListingEdit: boolean;
    projectCode: string;
    projectId: number;
  }

//blob and exception
function throwException(
    message: string,
    status: number,
    response: string,
    headers: { [key: string]: any },
    result?: any
): Observable<any> {
    if (result !== null && result !== undefined) return _observableThrow(result);
    else
        return _observableThrow(
            new ApiException(message, status, response, headers, null)
        );
}
function blobToText(blob: any): Observable<string> {
    return new Observable<string>((observer: any) => {
        if (!blob) {
            observer.next("");
            observer.complete();
        } else {
            let reader = new FileReader();
            reader.onload = (event) => {
                observer.next((event.target as any).result);
                observer.complete();
            };
            reader.readAsText(blob);
        }
    });
}
