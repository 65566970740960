import { Injectable } from '@angular/core';
import {
    HttpInterceptor,
    HttpRequest,
    HttpHandler,
    HttpEvent,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable()
export class SpinnerInterceptor implements HttpInterceptor {
    constructor(private spinner: NgxSpinnerService) {}

    private requestCount: number = 0;

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        if (this.requestCount === 0) {
            // abp.ui.setBusy();
            this.spinner.show('common_spinner');
        }

        this.requestCount++;

        return next.handle(request).pipe(
            finalize(() => {
                this.requestCount--;

                if (this.requestCount === 0) {
                    // abp.ui.clearBusy();
                    this.spinner.hide('common_spinner');
                }
            })
        );
    }
}
