import { Directive, ElementRef, Input } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';

@Directive({
  selector: '[busy]',
})
export class BusyDirective {

  constructor(private _element: ElementRef,private spinner:NgxSpinnerService) { }

  @Input() set busy(isBusy: boolean) {
    this.refreshState(isBusy);
  }

  refreshState(isBusy: boolean): void {
    if (isBusy === undefined) {
      return;
    }

    if (isBusy) {
      this.spinner.show('common_spinner');
      // abp.ui.setBusy(this._element.nativeElement);
    } else {
      this.spinner.hide('common_spinner');
      // abp.ui.clearBusy(this._element.nativeElement);
    }
  }
}
