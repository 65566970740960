<section>
    <div class="container-fluid px-4" id="toPrint">
      <div class="row mb-3">
        <div class="col-8 pl-4">
          <div class="d-flex">
            <img src="assets/img/logo.png" id="logo" [ngClass]="(data?.state) ? 'print-mt-3 mr-5 logo' : 'mr-5 logo'" alt="" />
            <div class="">
              <div class="col-12">
                <p [ngClass]="(data?.state) ? 'mb-0 mt-2 fw-600 fs-20' : 'print-mt-2 mb-0 mt-2 fw-600 fs-20'">
                  {{ data?.title }}  
                </p>
              </div>
              <div class="col-12" *ngIf="data?.state">
                <p class="mb-0 mt-2 fs-12">
                  Status:
                  {{ data?.state }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-4 d-flex justify-content-end">
          <button
            class="generate-report-btn fw-600"
            styleSheetFile="assets/css/custom_print.css"
            [printTitle]="this.data?.title" 
            printSectionId="toPrint"
            [previewOnly]="false"
            ngxPrint
          >
            <span><i class="fa-solid fa-paint-roller fnt-11 mr-2"></i></span>
            <span class="fnt-12">{{data?.printBtnTitle}}</span>
          </button>
        </div>
      </div>


      <!-- <div class="row mb-3">
        <div class="col-1 pl-4 d-flex align-item-center">
            <img src="assets/img/logo.png" id="logo" class="logo" alt="" />
        </div>

        <div class="col-7">
            <div class="col-12">
              <p class="mb-0 mt-2 fw-600 fs-20">
                {{ data?.title }}
              </p>
            </div>
            <div class="col-12" *ngIf="data?.state">
              <p class="mb-0 mt-2 fs-12">
                Status:
                {{ data?.state }}
              </p>
          </div>
        </div>

        <div class="col-4 d-flex justify-content-end">
          <button
            class="generate-report-btn fw-600"
            styleSheetFile="assets/css/custom_print.css"
            [printTitle]="this.data?.title" 
            printSectionId="toPrint"
            [previewOnly]="false"
            ngxPrint
          >
            <span><i class="fa-solid fa-paint-roller fnt-11 mr-2"></i></span>
            <span class="fnt-12">{{data?.printBtnTitle}}</span>
          </button>
        </div>
      </div> -->



      <div class="row px-3">
        <div class="col-12 brdr-bottom"></div>
      </div>
  
      <!-- <section> -->
      <div class="content-header bg-white pl-3 print-mt-1">
        <div class="table-responsive custom-table-responsive">
          <table class="table table-striped" [busy]="isTableLoading">
            <thead class="bg-white">
              <tr class="">
                <th
                  *ngFor="let heading of data?.tableHeading; let i = index"
                  class="border-0 print-font print-left-text print-pl-1"
                >
                  {{ heading?.title }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let data of bankGuaranteesList " class="print-font">
                <td class="print-left-text">{{data?.projCode}}</td>
                <td class="print-left-text">{{data?.amount | currency:'USD':'symbol':'1.0-0'}}</td>
                <td class="print-left-text">{{data?.guarantee}}</td>
                <td class="print-left-text">{{data?.type}}</td>
                <td class="print-left-text">{{data?.issueDate | date : 'dd/MM/yyyy'}}</td>
                <td class="print-left-text">{{data?.expiryDate | date: 'dd/MM/yyyy'}}</td>
                <td class="print-left-text">{{data?.returnDate | date: 'dd/MM/yyyy'}}</td>
              
              </tr>
              <tr>
                <td *ngIf="!bankGuaranteesList.length"
                  [attr.colspan]="data?.tableHeading.length"
                  class="text-center text-danger fw-600 print-font"
                >
                  No Data
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <!-- </section> -->
    </div>
  </section>
  