<div >
<section *ngIf="!showSuccess" class="bg-white" >
  <div class="container-fluid pl-0">
    <div class="row h-100">
      <div class="col-12 col-md-4 zero-right">
        <div class="green-background">
          <div class="col-12 d-flex justify-content-center logo-position">
            <img
              src="assets/img/light-appselec-logo.png"
              class="logo-size"
              alt=""
            />
          </div>
        </div>
      </div>

      <div class="col-12 col-md-8 mt-5 mobile-heading-margin">
        <div class="col-12">
          <div class="">
            <p class="title mb-0">Customer Details</p>
          </div>
          <div class="col-12 pl-0 center">
            <div class="green-border"></div>
          </div>
        </div>
        <div class="col-12 col-md-4 mt-3">
          <p class="title-detail">
            New customer details form. Please fill all the fields.<br />In case
            of any query, please write to us:
            <span class="bluetext">service@appselec.com.au</span>
          </p>
        </div>
        <form action="" [formGroup]="customerForm" (ngSubmit)="save()">
          <div class="container">
            <div class="col-12 mt-5">
              <div class="row">
                <div class="col-12 col-md-6 d-flex">
                  <div class="col-4 col-md-3">
                    <label for=""> Company </label>
                  </div>
                  <div class="col-8 col-md-7">
                    <input
                      type="text"
                      class="w-100 input-box"
                      placeholder="Write Here..."
                      formControlName="company"
                      name="company"
                      id="company"
                    />
                    <div
                      *ngIf="
                        customerForm.get('company').hasError('required') &&
                        customerForm.get('company').touched
                      "
                      class="text-danger fs-12 ml-1"
                    >
                      Field is required
                    </div>
                  </div>
                </div>
                <div
                  class="col-12 col-md-6 d-flex mobile-mt-4 justify-content-end"
                >
                  <div class="col-4 col-md-3">
                    <label for=""> ABN </label>
                  </div>
                  <div class="col-8 col-md-7">
                    <input
                      type="text"
                      class="w-100 input-box"
                      placeholder="Write Here..."
                      formControlName="abn"
                      name="abn"
                      id="abn"
                    />
                    <div
                      *ngIf="
                        customerForm.get('abn').hasError('required') &&
                        customerForm.get('abn').touched
                      "
                      class="text-danger fs-12 ml-1"
                    >
                      Field is required
                    </div>
                  </div>
                </div>
              </div>

              <div class="row mt-4">
                <div class="col-12 col-md-6 d-flex">
                  <div class="col-4 col-md-3">
                    <label for=""> Last Name </label>
                  </div>
                  <div class="col-8 col-md-7">
                    <input
                      type="text"
                      class="w-100 input-box"
                      placeholder="Write Here..."
                      formControlName="lastName"
                      name="lastName"
                      id="lastName"
                    />
                    <div
                      *ngIf="
                        customerForm.get('lastName').hasError('required') &&
                        customerForm.get('lastName').touched
                      "
                      class="text-danger fs-12 ml-1"
                    >
                      Field is required
                    </div>
                  </div>
                </div>
                <div
                  class="col-12 col-md-6 d-flex mobile-mt-4 justify-content-end"
                >
                  <div class="col-4 col-md-3">
                    <label for=""> First Name </label>
                  </div>
                  <div class="col-8 col-md-7">
                    <input
                      type="text"
                      class="w-100 input-box"
                      placeholder="Write Here..."
                      formControlName="firstName"
                      name="firstName"
                      id="firstName"
                    />
                    <div
                      *ngIf="
                        customerForm.get('firstName').hasError('required') &&
                        customerForm.get('firstName').touched
                      "
                      class="text-danger fs-12 ml-1"
                    >
                      Field is required
                    </div>
                  </div>
                </div>
              </div>

              <div class="row mt-4">
                <div class="col-12 col-md-6 d-flex">
                  <div class="col-4 col-md-3">
                    <label for=""> Email Address </label>
                  </div>
                  <div class="col-8 col-md-7">
                    <input
                      type="text"
                      class="w-100 input-box"
                      placeholder="Write Here..."
                      formControlName="email"
                      name="email"
                      id="email"
                    />
                    <div *ngIf="customerForm.get('email').hasError('required') && customerForm.get('email').touched" class="text-danger fs-12 ml-1"
                    >
                      Field is required
                    </div>
                    <div *ngIf="customerForm.get('email').hasError('email')" class="text-danger fs-12 ml-1">
                        Please enter a valid email address
                      </div>
                  </div>
                </div>
                <div
                  class="col-12 col-md-6 d-flex mobile-mt-4 justify-content-end"
                >
                  <div class="col-4 col-md-3">
                    <label for=""> Business Phone </label>
                  </div>
                  <div class="col-8 col-md-7">
                    <input
                      type="number"
                      class="w-100 input-box"
                      placeholder="Write Here..."
                      formControlName="businessPhone"
                      name="businessPhone"
                      id="businessPhone"
                    />
                    <div
                      *ngIf="
                        customerForm.get('businessPhone').hasError('required') &&
                        customerForm.get('businessPhone').touched
                      "
                      class="text-danger fs-12 ml-1"
                    >
                      Field is required
                    </div>
                  </div>
                </div>
              </div>

              <div class="row mt-4">
                <div class="col-12 col-md-6 d-flex">
                  <div class="col-4 col-md-3">
                    <label for=""> Mobile Phone </label>
                  </div>
                  <div class="col-8 col-md-7">
                    <input
                      type="number"
                      class="w-100 input-box"
                      placeholder="Write Here..."
                      formControlName="mobilePhone"
                      name="mobilePhone"
                      id="mobilePhone"
                    />
                    <div
                      *ngIf="
                        customerForm.get('mobilePhone').hasError('required') &&
                        customerForm.get('mobilePhone').touched
                      "
                      class="text-danger fs-12 ml-1"
                    >
                      Field is required
                    </div>
                  </div>
                </div>
                <div
                  class="col-12 col-md-6 d-flex mobile-mt-4 justify-content-end"
                >
                  <div class="col-4 col-md-3">
                    <label for=""> Address </label>
                  </div>
                  <div class="col-8 col-md-7">
                    <input
                      type="text"
                      class="w-100 input-box"
                      placeholder="Write Here..."
                      formControlName="address"
                      name="address"
                      id="address"
                    />
                    <div
                      *ngIf="
                        customerForm.get('address').hasError('required') &&
                        customerForm.get('address').touched
                      "
                      class="text-danger fs-12 ml-1"
                    >
                      Field is required
                    </div>
                  </div>
                </div>
              </div>

              <div class="row mt-4">
                <div class="col-12 col-md-6 d-flex">
                  <div class="col-4 col-md-3">
                    <label for=""> City </label>
                  </div>
                  <div class="col-8 col-md-7">
                    <input
                      type="text"
                      class="w-100 input-box"
                      placeholder="Write Here..."
                      formControlName="city"
                      name="city"
                      id="city"
                    />
                    <div
                      *ngIf="
                        customerForm.get('city').hasError('required') &&
                        customerForm.get('city').touched
                      "
                      class="text-danger fs-12 ml-1"
                    >
                      Field is required
                    </div>
                  </div>
                </div>
                <div
                  class="col-12 col-md-6 d-flex mobile-mt-4 justify-content-end"
                >
                  <div class="col-4 col-md-3">
                    <label for=""> State </label>
                  </div>
                  <div class="col-8 col-md-7">
                    <input
                      type="text"
                      class="w-100 input-box"
                      placeholder="Write Here..."
                      formControlName="state"
                      name="state"
                      id="state"
                    />
                    <div
                      *ngIf="
                        customerForm.get('state').hasError('required') &&
                        customerForm.get('state').touched
                      "
                      class="text-danger fs-12 ml-1"
                    >
                      Field is required
                    </div>
                  </div>
                </div>
              </div>

              <div class="row mt-4">
                <div class="col-12 col-md-6 d-flex">
                  <div class="col-4 col-md-3">
                    <label for=""> Post Code </label>
                  </div>
                  <div class="col-8 col-md-7">
                    <input
                      type="number"
                      class="w-100 input-box"
                      placeholder="Write Here..."
                      formControlName="postCode"
                      name="postCode"
                      id="postCode"
                    />
                    <div
                      *ngIf="
                        customerForm.get('postCode').hasError('required') &&
                        customerForm.get('postCode').touched
                      "
                      class="text-danger fs-12 ml-1"
                    >
                      Field is required
                    </div>
                  </div>
                </div>
                <div
                  class="col-12 col-md-6 d-flex mobile-mt-4 justify-content-end"
                >
                  <div class="col-4 col-md-3">
                    <label for=""> Country </label>
                  </div>
                  <div class="col-8 col-md-7">
                    <input
                      type="text"
                      class="w-100 input-box"
                      placeholder="Write Here..."
                      formControlName="country"
                      name="country"
                      id="country"
                    />
                    <div
                      *ngIf="
                        customerForm.get('country').hasError('required') &&
                        customerForm.get('country').touched
                      "
                      class="text-danger fs-12 ml-1"
                    >
                      Field is required
                    </div>
                  </div>
                </div>
              </div>

              <div class="row mt-5 mobile-mb-2">
                <div class="col-12 d-flex justify-content-end">
                  <button class="submit-btn" type="submit">Submit</button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</section>
</div>

<section *ngIf="showSuccess" class="bg-white h-100">
    <success-page (moveBack)="getDataFromChild($event)"></success-page>
</section>
