import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UrlHistoryService {
  private urlHistory: string[] = [];

  addToUrlHistory(url: string) {
    // Keep only the last two URLs
    if (this.urlHistory.length === 2) {
      this.urlHistory.shift();  // Remove the oldest URL
    }
    this.urlHistory.push(url);
  }

  getUrlHistory(): string[] {
    return this.urlHistory;
  }
}
