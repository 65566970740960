<pagination-template #p="paginationApi"
                     [id]="id"
                     [maxSize]="maxSize"
                     (pageChange)="pageChange.emit($event)">
    <nav>
        <ul *ngIf="!(autoHide && p.pages.length <= 1)" class="pagination m-0">
            <li *ngIf="directionLinks"
                class="page-item"
                [class.disabled]="p.isFirstPage()">
                <a *ngIf="!p.isFirstPage()"
                   class=""
                   href="javascript:;"
                   (click)="p.previous()">
                    <i class="fas fa-chevron-left"></i>
                </a>
                <a *ngIf="p.isFirstPage()" class="" href="javascript:;">
                    <i class="fas fa-chevron-left"></i>
                </a>
            </li>
            <li *ngFor="let page of p.pages"
                class="page-item ml-4 mr-4"
                [class.active]="p.getCurrent() === page.value"
                [style.z-index]="p.getCurrent() === page.value ? '0' : ''">
                <a class=""
                   href="javascript:;"
                   (click)="p.setCurrent(page.value)">
                    {{ page.label }}
                </a>
            </li>
            <li *ngIf="directionLinks"
                class="page-item"
                [class.disabled]="p.isLastPage()">
                <a *ngIf="!p.isLastPage()"
                   class=""
                   href="javascript:;"
                   (click)="p.next()">
                    <i class="fas fa-chevron-right"></i>
                </a>
                <a *ngIf="p.isLastPage()" class="" href="javascript:;">
                    <i class="fas fa-chevron-right"></i>
                </a>
            </li>
        </ul>
    </nav>
</pagination-template>
<style>
    li a{
        border:none;
    }

    li.page-item.active a {
        color: green;
        font-weight:800;
    }

    li.page-item a {
        color: black;
    }
</style>
