import { OnInit, Component } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import {
  ICreate,
  PublicCustomerService,
} from "@shared/service-proxies/public-customer/public-customer.service";

@Component({
  templateUrl: "./create-form.component.html",
  styleUrls: ["./create-form.component.css"]
})
export class PublicCustomerComponent implements OnInit {
  isTableLoading: boolean = false;
  customerForm = new FormGroup({
    company: new FormControl("", [Validators.required]),
    abn: new FormControl("", [Validators.required]),
    lastName: new FormControl("", [Validators.required]),
    firstName: new FormControl("", [Validators.required]),
    email: new FormControl("", [Validators.required, Validators.email]),
    businessPhone: new FormControl("", [Validators.required]),
    mobilePhone: new FormControl("", [Validators.required]),
    address: new FormControl("", [Validators.required]),
    city: new FormControl("", [Validators.required]),
    state: new FormControl("", [Validators.required]),
    postCode: new FormControl("", [Validators.required]),
    country: new FormControl("", [Validators.required]),
  });
  showSuccess: boolean = false;

  constructor(private _publicCustomerService: PublicCustomerService) {}

  ngOnInit(): void {}

  save() {
    if (this.customerForm?.invalid) {
      this.customerForm?.markAllAsTouched();
      return;
    }
    if (this.customerForm?.valid) {
      let data: ICreate = {
        company: this.customerForm.controls.company.value,
        lastName: this.customerForm.controls.lastName.value,
        firstName: this.customerForm.controls.firstName.value,
        eMailAddress: this.customerForm.controls.email.value,
        businessPhone: this.customerForm.controls.businessPhone.value.toString(),
        mobilePhone: this.customerForm.controls.mobilePhone.value.toString(),
        address: this.customerForm.controls.address.value,
        city: this.customerForm.controls.city.value,
        state: this.customerForm.controls.state.value,
        postalCode: this.customerForm.controls.postCode.value.toString(),
        country: this.customerForm.controls.country.value,
        abn: this.customerForm.controls.abn.value,
        customerStatus: 0,
      };
      this.isTableLoading = true;
      this._publicCustomerService.create(data).subscribe({
        next: (res) => {
          this.isTableLoading = false;
          if (res.id) {
            abp.notify.success("Customer Created Successfully!");
            this.showSuccess = true;
            this.customerForm?.reset();
          }
        },
        error: (err) => {
          this.isTableLoading = false;
          abp.notify.error(err.message);
        },
      });
    }
  }

  getDataFromChild(flag) {
    if (flag) {
      if (flag == true) {
        this.showSuccess = false;
        this.customerForm;
      }
    }
  }

  ngOnDestroy(): void {}
}
