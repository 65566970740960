import {
  mergeMap as _observableMergeMap,
  catchError as _observableCatch,
  mergeMap,
  catchError,
} from "rxjs/operators";
import {
  Observable,
  throwError as _observableThrow,
  of as _observableOf,
  throwError,
} from "rxjs";
import { Injectable, Inject, Optional } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
  HttpResponse,
  HttpResponseBase,
} from "@angular/common/http";

import * as moment from "moment";
import { ApiException, UserDto } from "../../service-proxies";
import { API_BASE_URL } from "@shared/service-proxies/service-proxies";
import { GenericService } from "@shared/service-proxies/generic.service";

@Injectable()
export class BankGuaranteesServices extends GenericService {
  private http: HttpClient;
  private baseUrl: string;
  protected jsonParseReviver: ((key: string, value: any) => any) | undefined =
    undefined;

  constructor(
    @Inject(HttpClient) http: HttpClient,
    @Optional() @Inject(API_BASE_URL) baseUrl?: string
  ) {
    super();
    this.http = http;
    this.baseUrl = baseUrl !== undefined && baseUrl !== null ? baseUrl : "";
  }
  GetAllBankGuaranteeData(
    keyword: string,
    sorting: string,
    skipCount: number | 0,
    maxResultCount: number |  null,
    projectCode?: string,
    IsPrint:boolean=false
  ): Observable<IGetDataForAllBankGuarantees> {
    let url_ =
      this.baseUrl + "/api/services/app/BankGuarantee/GetAllBankGauranteeSP?";
    if (keyword === null)
      throw new Error("The parameter 'keyword' cannot be null.");
    else if (keyword !== undefined)
      url_ += "Keyword=" + encodeURIComponent("" + keyword) + "&";
    if (sorting === null)
      throw new Error("The parameter 'skipCount' cannot be null.");
    else if (sorting !== undefined)
      url_ += "sorting=" + encodeURIComponent("" + sorting) + "&";
    if (skipCount === null)
      throw new Error("The parameter 'skipCount' cannot be null.");
    else if (skipCount !== undefined)
      url_ += "SkipCount=" + encodeURIComponent("" + skipCount) + "&";
    // if (maxResultCount === null)
    //   throw new Error("The parameter 'maxResultCount' cannot be null.");
    // else if (maxResultCount !== undefined)
      url_ += "MaxResultCount=" + encodeURIComponent("" + maxResultCount) + "&";
    if (projectCode === null)
      throw new Error("The parameter 'projectCode' cannot be null.");
    else if (projectCode !== undefined)
      url_ += "ProjectCode=" + encodeURIComponent("" + projectCode) + "&";

      url_ += "IsPrint=" + encodeURIComponent("" + IsPrint) + "&";

    url_ = url_.replace(/[?&]$/, "");

    let options_: any = {
      observe: "response",
      responseType: "blob",
      headers: new HttpHeaders({
        Accept: "text/plain",
      }),
    };

    return this.http
      .request("get", url_, options_)
      .pipe(
        _observableMergeMap((response_: any) => {
          return this.processGetAllBankGuaranteeData(response_);
        })
      )
      .pipe(
        _observableCatch((response_: any) => {
          if (response_ instanceof HttpResponseBase) {
            try {
              return this.processGetAllBankGuaranteeData(response_ as any);
            } catch (e) {
              return _observableThrow(
                e
              ) as any as Observable<IGetDataForAllBankGuarantees>;
            }
          } else
            return _observableThrow(
              response_
            ) as any as Observable<IGetDataForAllBankGuarantees>;
        })
      );
  }
  
  GetCurrentBankGuaranteeData(): Observable<IGetDataForAllBankGuarantees> {
    let url_ =this.baseUrl + "/api/services/app/BankGuarantee/GetCurrentBankGauranteeSP?";
    url_ = url_.replace(/[?&]$/, "");

    let options_: any = {
      observe: "response",
      responseType: "blob",
      headers: new HttpHeaders({
        Accept: "text/plain",
      }),
    };

    return this.http
      .request("get", url_, options_)
      .pipe(
        _observableMergeMap((response_: any) => {
          return this.processGetAllBankGuaranteeData(response_);
        })
      )
      .pipe(
        _observableCatch((response_: any) => {
          if (response_ instanceof HttpResponseBase) {
            try {
              return this.processGetAllBankGuaranteeData(response_ as any);
            } catch (e) {
              return _observableThrow(
                e
              ) as any as Observable<IGetDataForAllBankGuarantees>;
            }
          } else
            return _observableThrow(
              response_
            ) as any as Observable<IGetDataForAllBankGuarantees>;
        })
      );
  }
  
  protected processGetAllBankGuaranteeData(
    response: HttpResponseBase
  ): Observable<IGetDataForAllBankGuarantees> {
    const status = response.status;
    const responseBlob =
      response instanceof HttpResponse
        ? response.body
        : (response as any).error instanceof Blob
        ? (response as any).error
        : undefined;

    let _headers: any = {};
    if (response.headers) {
      for (let key of response.headers.keys()) {
        _headers[key] = response.headers.get(key);
      }
    }
    if (status === 200) {
      return blobToText(responseBlob).pipe(
        _observableMergeMap((_responseText: string) => {
          let result200: any = null;
          let resultData200 =
            _responseText === ""
              ? null
              : JSON.parse(_responseText, this.jsonParseReviver);
          return _observableOf(resultData200);
        })
      );
    } else if (status !== 200 && status !== 204) {
      return blobToText(responseBlob).pipe(
        _observableMergeMap((_responseText: string) => {
          return throwException(
            "An unexpected server error occurred.",
            status,
            _responseText,
            _headers
          );
        })
      );
    }
    return _observableOf(null as any);
  }

  /*Create bankguarantee*/
  createBankGuarantee(body: ICreateBankGuarantee | undefined): Observable<any> {
    let url_ = this.baseUrl + "/api/services/app/BankGuarantee/Create";
    url_ = url_.replace(/[?&]$/, "");
    const content_ = JSON.stringify(body);

    let options_: any = {
      body: content_,
      observe: "response",
      responseType: "blob",
      headers: new HttpHeaders({
        "Content-Type": "application/json-patch+json",
        Accept: "text/plain",
      }),
    };

    return this.http
      .request("post", url_, options_)
      .pipe(
        _observableMergeMap((response_: any) => {
          return this.processCreate(response_);
        })
      )
      .pipe(
        _observableCatch((response_: any) => {
          if (response_ instanceof HttpResponseBase) {
            try {
              return this.processCreate(response_ as any);
            } catch (e) {
              return _observableThrow(e) as any as Observable<any>;
            }
          } else return _observableThrow(response_) as any as Observable<any>;
        })
      );
  }
  protected processCreate(response: HttpResponseBase): Observable<any> {
    const status = response.status;
    const responseBlob =
      response instanceof HttpResponse
        ? response.body
        : (response as any).error instanceof Blob
        ? (response as any).error
        : undefined;

    let _headers: any = {};
    if (response.headers) {
      for (let key of response.headers.keys()) {
        _headers[key] = response.headers.get(key);
      }
    }
    if (status === 200) {
      return blobToText(responseBlob).pipe(
        _observableMergeMap((_responseText: string) => {
          let result200: any = null;
          let resultData200 =
            _responseText === ""
              ? null
              : JSON.parse(_responseText, this.jsonParseReviver);
          return _observableOf(resultData200);
        })
      );
    } else if (status !== 200 && status !== 204) {
      return blobToText(responseBlob).pipe(
        _observableMergeMap((_responseText: string) => {
          return throwException(
            "An unexpected server error occurred.",
            status,
            _responseText,
            _headers
          );
        })
      );
    }
    return _observableOf(null as any);
  }

  // update bank guarantee
  updateBankGuarantee(body: IUpdateBankGurantee | undefined): Observable<any> {
    let url_ = this.baseUrl + "/api/services/app/BankGuarantee/Update";
    url_ = url_.replace(/[?&]$/, "");
    const content_ = JSON.stringify(body);

    let options_: any = {
      body: content_,
      observe: "response",
      responseType: "blob",
      headers: new HttpHeaders({
        "Content-Type": "application/json-patch+json",
        Accept: "text/plain",
      }),
    };

    return this.http
      .request("put", url_, options_)
      .pipe(
        _observableMergeMap((response_: any) => {
          return this.processCreate(response_);
        })
      )
      .pipe(
        _observableCatch((response_: any) => {
          if (response_ instanceof HttpResponseBase) {
            try {
              return this.processCreate(response_ as any);
            } catch (e) {
              return _observableThrow(e) as any as Observable<any>;
            }
          } else return _observableThrow(response_) as any as Observable<any>;
        })
      );
  }
  protected processEdit(response: HttpResponseBase): Observable<any> {
    const status = response.status;
    const responseBlob =
      response instanceof HttpResponse
        ? response.body
        : (response as any).error instanceof Blob
        ? (response as any).error
        : undefined;

    let _headers: any = {};
    if (response.headers) {
      for (let key of response.headers.keys()) {
        _headers[key] = response.headers.get(key);
      }
    }
    if (status === 200) {
      return blobToText(responseBlob).pipe(
        _observableMergeMap((_responseText: string) => {
          let result200: any = null;
          let resultData200 =
            _responseText === ""
              ? null
              : JSON.parse(_responseText, this.jsonParseReviver);
          // result200 = GetProjectDto.fromJS(resultData200);
          return _observableOf(resultData200);
        })
      );
    } else if (status !== 200 && status !== 204) {
      return blobToText(responseBlob).pipe(
        _observableMergeMap((_responseText: string) => {
          return throwException(
            "An unexpected server error occurred.",
            status,
            _responseText,
            _headers
          );
        })
      );
    }
    return _observableOf(null as any);
  }

  // delete bankGuarantee
  delete(id: number | undefined): Observable<void> {
    let url_ = this.baseUrl + "/api/services/app/BankGuarantee/Delete?";
    if (id === null) throw new Error("The parameter 'id' cannot be null.");
    else if (id !== undefined)
      url_ += "Id=" + encodeURIComponent("" + id) + "&";
    url_ = url_.replace(/[?&]$/, "");

    let options_: any = {
      observe: "response",
      responseType: "blob",
      headers: new HttpHeaders({}),
    };

    return this.http
      .request("delete", url_, options_)
      .pipe(
        _observableMergeMap((response_: any) => {
          return this.processDelete(response_);
        })
      )
      .pipe(
        _observableCatch((response_: any) => {
          if (response_ instanceof HttpResponseBase) {
            try {
              return this.processDelete(response_ as any);
            } catch (e) {
              return _observableThrow(e) as any as Observable<void>;
            }
          } else return _observableThrow(response_) as any as Observable<void>;
        })
      );
  }

  protected processDelete(response: HttpResponseBase): Observable<void> {
    const status = response.status;
    const responseBlob =
      response instanceof HttpResponse
        ? response.body
        : (response as any).error instanceof Blob
        ? (response as any).error
        : undefined;

    let _headers: any = {};
    if (response.headers) {
      for (let key of response.headers.keys()) {
        _headers[key] = response.headers.get(key);
      }
    }
    if (status === 200) {
      return blobToText(responseBlob).pipe(
        _observableMergeMap((_responseText: string) => {
          return _observableOf(null as any);
        })
      );
    } else if (status !== 200 && status !== 204) {
      return blobToText(responseBlob).pipe(
        _observableMergeMap((_responseText: string) => {
          return throwException(
            "An unexpected server error occurred.",
            status,
            _responseText,
            _headers
          );
        })
      );
    }
    return _observableOf(null as any);
  }

  // bank gurantee type
  BankGuranteeTypeDropDown(): Observable<IBankGuranteeType> {
    let url_ = `${this.baseUrl}/api/services/app/BankGuarantee/GetBankGauranteeTypesForDropdownSP`;
    let options_: any = {
      observe: "response",
      responseType: "blob",
      headers: new HttpHeaders({
        Accept: "text/plain",
      }),
    };

    return this.http.get(url_, options_).pipe(
      mergeMap((response: any) =>
        this.processGeneric<IBankGuranteeType>(response)
      ),
      catchError((error: any) => {
        if (error instanceof HttpResponseBase) {
          return this.processGeneric<IBankGuranteeType>(error as any);
        } else {
          return throwError(() => error);
        }
      })
    );
  }
  // bank gurantee type

  // bank gurantee class

  BankGuranteeClassDropDown(): Observable<IBankGuranteeClass> {
    let url_ = `${this.baseUrl}/api/services/app/BankGuarantee/GetBankGauranteeClassForDropdownSP`;
    let options_: any = {
      observe: "response",
      responseType: "blob",
      headers: new HttpHeaders({
        Accept: "text/plain",
      }),
    };

    return this.http.get(url_, options_).pipe(
      mergeMap((response: any) =>
        this.processGeneric<IBankGuranteeClass>(response)
      ),
      catchError((error: any) => {
        if (error instanceof HttpResponseBase) {
          return this.processGeneric<IBankGuranteeClass>(error as any);
        } else {
          return throwError(() => error);
        }
      })
    );
  }

  // bank gurantee class
}

// GetDataForAllInvoices Dto
export interface IGetDataForAllBankGuarantees {
  items: IGetDataForAllBankGuaranteesItems[] | undefined;
  totalCount: number;
}
export interface IGetDataForAllBankGuaranteesItems {
  projCode: string;
  amount: number;
  guarantee: string;
  type: number;
  issueDate: string;
  expiryDate: string;
  returnDate: string;
}
// CreateInvoices Dto
export interface ICreateBankGuarantee {
  projCode: string;
  amount: number;
  guarantee: string;
  type: string;
  issueDate: moment.Moment;
  expiryDate: moment.Moment;
  returnDate: moment.Moment;
}

// UpdateBankGuarantee Dto
export interface IUpdateBankGurantee {
  id: number;
  projCode: string;
  amount: number;
  guarantee: string;
  type: string;
  issueDate: moment.Moment;
  expiryDate: moment.Moment;
  returnDate: moment.Moment;
}
// bank gurantee type interface
export interface IBankGuranteeType {
  items: IBankGuranteeTypeItems[];
  totalCount: number;
}
export interface IBankGuranteeTypeItems {
  guaranteeType: string;
}
// bank gurantee Class interface
export interface IBankGuranteeClass {
  items: IBankGuranteeClassItems[];
  totalCount: number;
}
export interface IBankGuranteeClassItems {
  class: string;
}

//blob and exception
function throwException(
  message: string,
  status: number,
  response: string,
  headers: { [key: string]: any },
  result?: any
): Observable<any> {
  if (result !== null && result !== undefined) return _observableThrow(result);
  else
    return _observableThrow(
      new ApiException(message, status, response, headers, null)
    );
}

function blobToText(blob: any): Observable<string> {
  return new Observable<string>((observer: any) => {
    if (!blob) {
      observer.next("");
      observer.complete();
    } else {
      let reader = new FileReader();
      reader.onload = (event) => {
        observer.next((event.target as any).result);
        observer.complete();
      };
      reader.readAsText(blob);
    }
  });
}
