import { Component, OnInit } from '@angular/core';
import { AppAuthService } from '@shared/auth/app-auth.service';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.css']
})
export class NotFoundComponent implements OnInit {
  access_forbidden: boolean = false;
  constructor(private _authService: AppAuthService) { }

  ngOnInit() {
    let data = history.state;
    if (data) {
      this.access_forbidden = data.access_forbidden;
    }

  }

  backToLogin(){
    this._authService.logout();
  }

}
