import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AbpHttpInterceptor } from 'abp-ng2-module';

import * as ApiServiceProxies from './service-proxies';

// Projects
import { ProjectServices } from './dakonex/projects/project.service';
import { ImportProjectServices } from './dakonex/projects/project.service';
// ProjectCosting
import { ProjectCostingServices } from './dakonex/projects/projectCosting.service';
import { SharingDataService } from './dakonex/projects/sharingData.service';
import { ProjectResourcingServices } from './dakonex/projects/project-resourcing.service';
import { InvoicesServices } from './dakonex/invoices/Invoice.service';
import { BankGuaranteesServices } from './dakonex/bankGuarantees/bank-guarantee.service';
import { RebateServices } from './dakonex/rebate/rebate.service';
@NgModule({
    providers: [
        ApiServiceProxies.RoleServiceProxy,
        ApiServiceProxies.SessionServiceProxy,
        ApiServiceProxies.TenantServiceProxy,
        ApiServiceProxies.UserServiceProxy,
        ApiServiceProxies.TokenAuthServiceProxy,
        ApiServiceProxies.AccountServiceProxy,
        ApiServiceProxies.ConfigurationServiceProxy,
        //Project
        ProjectServices,
        ImportProjectServices,
        //ProjectCosting
        ProjectCostingServices,
        //ProjectResourcing
        ProjectResourcingServices,
        //Invoice Services
        InvoicesServices,
        //sharingData
        SharingDataService,
        //Bank Guarantee Services
        BankGuaranteesServices,
        //rebate service
        RebateServices,

        { provide: HTTP_INTERCEPTORS, useClass: AbpHttpInterceptor, multi: true }
    ]
})
export class ServiceProxyModule { }
