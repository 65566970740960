import {
    mergeMap as _observableMergeMap,
    catchError as _observableCatch,
    mergeMap,
    catchError,
} from "rxjs/operators";
import {
    Observable,
    throwError as _observableThrow,
    of as _observableOf,
    of,
    throwError,
} from "rxjs";
import { Injectable, Inject, Optional, InjectionToken } from "@angular/core";
import {
    HttpClient,
    HttpHeaders,
    HttpResponse,
    HttpResponseBase,
} from "@angular/common/http";

import * as moment from "moment";
import { ApiException, UserDto } from "../../service-proxies";
import { API_BASE_URL } from "@shared/service-proxies/service-proxies";
import { extend } from "lodash-es";
import { GetDepartmentDto } from "@app/departments/department.service";

// export const API_BASE_URL = new InjectionToken<string>("API_BASE_URL");

@Injectable()
export class ProjectCostingServices {
    private http: HttpClient;
    private baseUrl: string;
    protected jsonParseReviver: ((key: string, value: any) => any) | undefined =
        undefined;

    constructor(
        @Inject(HttpClient) http: HttpClient,
        @Optional() @Inject(API_BASE_URL) baseUrl?: string
    ) {
        this.http = http;
        this.baseUrl = baseUrl !== undefined && baseUrl !== null ? baseUrl : "";
    }
    GetAllProjectCosting(
        keyword: string,
        sorting: string,
        skipCount: number | 0,
        maxResultCount: number | 10,
        projectCode: string,
        hiddenItem: boolean,
    ): Observable<ProjectCostingPagedResultDto> {
        let url_ =
            this.baseUrl +
            "/api/services/app/ProjectCosting/GetAllProjectCostingMaintenanceSP?";
        if (keyword === null)
            throw new Error("The parameter 'keyword' cannot be null.");
        else if (keyword !== undefined)
            url_ += "Keyword=" + encodeURIComponent("" + keyword) + "&";
        if (sorting === null)
            throw new Error("The parameter 'skipCount' cannot be null.");
        else if (sorting !== undefined)
            url_ += "sorting=" + encodeURIComponent("" + sorting) + "&";
        if (skipCount === null)
            throw new Error("The parameter 'skipCount' cannot be null.");
        else if (skipCount !== undefined)
            url_ += "SkipCount=" + encodeURIComponent("" + skipCount) + "&";
        if (maxResultCount === null)
            throw new Error("The parameter 'maxResultCount' cannot be null.");
        else if (maxResultCount !== undefined)
            url_ += "MaxResultCount=" + encodeURIComponent("" + maxResultCount) + "&";

        url_ += "projectCode=" + encodeURIComponent("" + projectCode) + "&";
        url_ += "showAll=" + encodeURIComponent("" + hiddenItem) + "&";

        url_ = url_.replace(/[?&]$/, "");

        let options_: any = {
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                Accept: "text/plain",
            }),
        };

        return this.http
            .request("get", url_, options_)
            .pipe(
                _observableMergeMap((response_: any) => {
                    return this.processGetProject(response_);
                })
            )
            .pipe(
                _observableCatch((response_: any) => {
                    if (response_ instanceof HttpResponseBase) {
                        try {
                            return this.processGetProject(response_ as any);
                        } catch (e) {
                            return _observableThrow(
                                e
                            ) as any as Observable<ProjectCostingPagedResultDto>;
                        }
                    } else
                        return _observableThrow(
                            response_
                        ) as any as Observable<ProjectCostingPagedResultDto>;
                })
            );
    }
    protected processGetProject(
        response: HttpResponseBase
    ): Observable<ProjectCostingPagedResultDto> {
        const status = response.status;
        const responseBlob =
            response instanceof HttpResponse
                ? response.body
                : (response as any).error instanceof Blob
                    ? (response as any).error
                    : undefined;

        let _headers: any = {};
        if (response.headers) {
            for (let key of response.headers.keys()) {
                _headers[key] = response.headers.get(key);
            }
        }
        if (status === 200) {
            return blobToText(responseBlob).pipe(
                _observableMergeMap((_responseText: string) => {
                    let result200: any = null;
                    let resultData200 =
                        _responseText === ""
                            ? null
                            : JSON.parse(_responseText, this.jsonParseReviver);
                    result200 = ProjectCostingPagedResultDto.fromJS(resultData200);
                    return _observableOf(result200);
                })
            );
        } else if (status !== 200 && status !== 204) {
            return blobToText(responseBlob).pipe(
                _observableMergeMap((_responseText: string) => {
                    return throwException(
                        "An unexpected server error occurred.",
                        status,
                        _responseText,
                        _headers
                    );
                })
            );
        }
        return _observableOf(null as any);
    }

    GetAllProjectCostingLabour(
        keyword: string,
        sorting: string,
        skipCount: number | 0,
        maxResultCount: number | 10,
        projectCode: string
    ): Observable<IProjectCostingLabourMaintainance> {
        let url_ =
            this.baseUrl +
            "/api/services/app/ProjectCosting/GetAllProjectCostingLabourMaintenanceSP?";
        if (keyword === null)
            throw new Error("The parameter 'keyword' cannot be null.");
        else if (keyword !== undefined)
            url_ += "Keyword=" + encodeURIComponent("" + keyword) + "&";
        if (sorting === null)
            throw new Error("The parameter 'skipCount' cannot be null.");
        else if (sorting !== undefined)
            url_ += "sorting=" + encodeURIComponent("" + sorting) + "&";
        if (skipCount === null)
            throw new Error("The parameter 'skipCount' cannot be null.");
        else if (skipCount !== undefined)
            url_ += "SkipCount=" + encodeURIComponent("" + skipCount) + "&";
        if (maxResultCount === null)
            throw new Error("The parameter 'maxResultCount' cannot be null.");
        else if (maxResultCount !== undefined)
            url_ += "MaxResultCount=" + encodeURIComponent("" + maxResultCount) + "&";

        url_ += "projectCode=" + encodeURIComponent("" + projectCode) + "&";

        url_ = url_.replace(/[?&]$/, "");

        let options_: any = {
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                Accept: "text/plain",
            }),
        };

        return this.http
            .request("get", url_, options_)
            .pipe(
                _observableMergeMap((response_: any) => {
                    return this.processGetProjectCostingLabour(response_);
                })
            )
            .pipe(
                _observableCatch((response_: any) => {
                    if (response_ instanceof HttpResponseBase) {
                        try {
                            return this.processGetProjectCostingLabour(response_ as any);
                        } catch (e) {
                            return _observableThrow(
                                e
                            ) as any as Observable<IProjectCostingLabourMaintainance>;
                        }
                    } else
                        return _observableThrow(
                            response_
                        ) as any as Observable<IProjectCostingLabourMaintainance>;
                })
            );
    }
    protected processGetProjectCostingLabour(
        response: HttpResponseBase
    ): Observable<IProjectCostingLabourMaintainance> {
        const status = response.status;
        const responseBlob =
            response instanceof HttpResponse
                ? response.body
                : (response as any).error instanceof Blob
                    ? (response as any).error
                    : undefined;

        let _headers: any = {};
        if (response.headers) {
            for (let key of response.headers.keys()) {
                _headers[key] = response.headers.get(key);
            }
        }
        if (status === 200) {
            return blobToText(responseBlob).pipe(
                _observableMergeMap((_responseText: string) => {
                    let result200: any = null;
                    let resultData200 =
                        _responseText === ""
                            ? null
                            : JSON.parse(_responseText, this.jsonParseReviver);
                    // result200 = ProjectCostingPagedResultDto.fromJS(resultData200);
                    return _observableOf(resultData200);
                })
            );
        } else if (status !== 200 && status !== 204) {
            return blobToText(responseBlob).pipe(
                _observableMergeMap((_responseText: string) => {
                    return throwException(
                        "An unexpected server error occurred.",
                        status,
                        _responseText,
                        _headers
                    );
                })
            );
        }
        return _observableOf(null as any);
    }

    // GetPurchaseOrderDetailForProjectCostingMaintenance( pagedPOProjectCostingMaintenanceRequestResult: PagedPOProjectCostingMaintenanceRequestResult): Observable<IPurchaseOrderDetailForProjectCostingMaintenance> {
    GetPurchaseOrderDetailForProjectCostingMaintenance(
        PurchaseOrderJobNo: string,
        PurchaseOrderNo: number
    ): Observable<IPurchaseOrderDetailForProjectCostingMaintenance> {
        let url_ =
            this.baseUrl +
            "/api/services/app/PurchaseOrder/GetPurchaseOrderDetailForProjectCostingMaintenanceSP?";
        if (PurchaseOrderJobNo === null)
            throw new Error("The parameter 'PurchaseOrderJobNo' cannot be null.");
        else if (PurchaseOrderJobNo !== undefined)
            url_ += "PurchaseOrderJobNo=" + encodeURIComponent("" + PurchaseOrderJobNo) + "&";

        if (PurchaseOrderNo === null)
            throw new Error("The parameter 'PurchaseOrderNo' cannot be null.");
        else if (PurchaseOrderNo !== undefined)
            url_ += "PurchaseOrderNo=" + encodeURIComponent("" + PurchaseOrderNo) + "&";


        url_ = url_.replace(/[?&]$/, "");

        // const content_ = JSON.stringify(pagedPOProjectCostingMaintenanceRequestResult);

        // let options_: any = {
        //     body: content_,
        //     observe: "response",
        //     responseType: "blob",
        //     headers: new HttpHeaders({
        //         "Content-Type": "application/json-patch+json",
        //         Accept: "text/plain",
        //     }),
        // };

        let options_: any = {
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                "Content-Type": "application/json-patch+json",
                Accept: "text/plain",
            }),
        };

        return this.http
            .request("get", url_, options_)
            .pipe(
                _observableMergeMap((response_: any) => {
                    return this.getPurchaseOrderDetailForProjectCostingMaintenance(
                        response_
                    );
                })
            )
            .pipe(
                _observableCatch((response_: any) => {
                    if (response_ instanceof HttpResponseBase) {
                        try {
                            return this.getPurchaseOrderDetailForProjectCostingMaintenance(
                                response_ as any
                            );
                        } catch (e) {
                            return _observableThrow(
                                e
                            ) as any as Observable<IPurchaseOrderDetailForProjectCostingMaintenance>;
                        }
                    } else
                        return _observableThrow(
                            response_
                        ) as any as Observable<IPurchaseOrderDetailForProjectCostingMaintenance>;
                })
            );
    }
    protected getPurchaseOrderDetailForProjectCostingMaintenance(
        response: HttpResponseBase
    ): Observable<IPurchaseOrderDetailForProjectCostingMaintenance> {
        const status = response.status;
        const responseBlob =
            response instanceof HttpResponse
                ? response.body
                : (response as any).error instanceof Blob
                    ? (response as any).error
                    : undefined;

        let _headers: any = {};
        if (response.headers) {
            for (let key of response.headers.keys()) {
                _headers[key] = response.headers.get(key);
            }
        }
        if (status === 200) {
            return blobToText(responseBlob).pipe(
                _observableMergeMap((_responseText: string) => {
                    let result200: any = null;
                    let resultData200 =
                        _responseText === ""
                            ? null
                            : JSON.parse(_responseText, this.jsonParseReviver);
                    // result200 = ProjectCostingPagedResultDto.fromJS(resultData200);
                    return _observableOf(resultData200);
                })
            );
        } else if (status !== 200 && status !== 204) {
            return blobToText(responseBlob).pipe(
                _observableMergeMap((_responseText: string) => {
                    return throwException(
                        "An unexpected server error occurred.",
                        status,
                        _responseText,
                        _headers
                    );
                })
            );
        }
        return _observableOf(null as any);
    }

    //  UpdateProjectCostingMaintenanceSP
    UpdateProjectCostingMaintenanceSP(data: any): Observable<number> {
        let url_ =
            this.baseUrl +
            "/api/services/app/ProjectCosting/UpdateProjectCostingMaintenanceSP";
        url_ = url_.replace(/[?&]$/, "");

        const content_ = {
            jsonData: data,
        };

        let options_: any = {
            body: content_,
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                "Content-Type": "application/json-patch+json",
                Accept: "text/plain",
            }),
        };
        return this.http
            .request("put", url_, options_)
            .pipe(
                _observableMergeMap((response_: any) => {
                    return this.processGeneric<any>(response_);
                })
            )
            .pipe(
                _observableCatch((response_: any) => {
                    if (response_ instanceof HttpResponseBase) {
                        try {
                            return this.processGeneric<any>(response_ as any);
                        } catch (e) {
                            return _observableThrow(e) as any as Observable<number>;
                        }
                    } else
                        return _observableThrow(response_) as any as Observable<number>;
                })
            );
    }

    //  UpdateProjectCostingLabourMaintenanceSP
    UpdateProjectCostingLabourMaintenanceSP(data: any): Observable<number> {
        let url_ =
            this.baseUrl +
            "/api/services/app/ProjectCostingLabour/UpdateProjectCostingLabourMaintenanceSP";
        url_ = url_.replace(/[?&]$/, "");

        const content_ = {
            json: data,
        };

        let options_: any = {
            body: content_,
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                "Content-Type": "application/json-patch+json",
                Accept: "text/plain",
            }),
        };
        return this.http
            .request("put", url_, options_)
            .pipe(
                _observableMergeMap((response_: any) => {
                    return this.processGeneric<any>(response_);
                })
            )
            .pipe(
                _observableCatch((response_: any) => {
                    if (response_ instanceof HttpResponseBase) {
                        try {
                            return this.processGeneric<any>(response_ as any);
                        } catch (e) {
                            return _observableThrow(e) as any as Observable<number>;
                        }
                    } else
                        return _observableThrow(response_) as any as Observable<number>;
                })
            );
    }

    //  UpdateLftctOnLcSP
    UpdateLftctOnLcSP(body: IUpdateLftctOnLcSP): Observable<boolean> {
        let url_ =
            this.baseUrl + "/api/services/app/ProjectCostingLabour/UpdateLftctOnLcSP";
        url_ = url_.replace(/[?&]$/, "");

        const content_ = JSON.stringify(body);

        let options_: any = {
            body: content_,
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                "Content-Type": "application/json-patch+json",
                Accept: "text/plain",
            }),
        };
        return this.http
            .request("put", url_, options_)
            .pipe(
                _observableMergeMap((response_: any) => {
                    return this.processGeneric<boolean>(response_);
                })
            )
            .pipe(
                _observableCatch((response_: any) => {
                    if (response_ instanceof HttpResponseBase) {
                        try {
                            return this.processGeneric<boolean>(response_ as any);
                        } catch (e) {
                            return _observableThrow(e) as any as Observable<boolean>;
                        }
                    } else
                        return _observableThrow(response_) as any as Observable<boolean>;
                })
            );
    }

    //UpdateContigencyOnLcSp
    UpdateContigencyOnLcSp(body: IUpdateContigencyOnLcSp): Observable<boolean> {
        let url_ =
            this.baseUrl +
            "/api/services/app/ProjectCostingLabour/UpdateLftctOnContigencySP";
        url_ = url_.replace(/[?&]$/, "");

        const content_ = JSON.stringify(body);

        let options_: any = {
            body: content_,
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                "Content-Type": "application/json-patch+json",
                Accept: "text/plain",
            }),
        };
        return this.http
            .request("put", url_, options_)
            .pipe(
                _observableMergeMap((response_: any) => {
                    return this.processGeneric<boolean>(response_);
                })
            )
            .pipe(
                _observableCatch((response_: any) => {
                    if (response_ instanceof HttpResponseBase) {
                        try {
                            return this.processGeneric<boolean>(response_ as any);
                        } catch (e) {
                            return _observableThrow(e) as any as Observable<boolean>;
                        }
                    } else
                        return _observableThrow(response_) as any as Observable<boolean>;
                })
            );
    }

    // GetSubTotalOfProjectCostingLabourMaintainace
    GetSubTotalOfProjectCostingLabourMaintainace(
        data: IGetSubTotalOfProjectCosting
    ): Observable<IGetSubTotalOfProjectCostingResponse> {
        let url_ =
            this.baseUrl +
            "/api/services/app/ProjectCosting/GetSubTotalOfProjectCostingLabourMaintainace?";
        if (data.keyword === null)
            throw new Error("The parameter 'keyword' cannot be null.");
        else if (data.keyword !== undefined)
            url_ += "Keyword=" + encodeURIComponent("" + data.keyword) + "&";
        if (data.ProjectCode === null || data.ProjectCode == "")
            throw new Error("The parameter 'ProjectCode' cannot be null or empty.");
        else if (data.ProjectCode !== undefined)
            url_ += "ProjectCode=" + encodeURIComponent("" + data.ProjectCode) + "&";
        if (data.TableName === null)
            throw new Error("The parameter 'TableName' cannot be null.");
        else if (data.TableName !== undefined)
            url_ += "TableName=" + encodeURIComponent("" + data.TableName) + "&";

        url_ = url_.replace(/[?&]$/, "");

        let options_: any = {
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                Accept: "text/plain",
            }),
        };

        return this.http
            .request("get", url_, options_)
            .pipe(
                _observableMergeMap((response_: any) => {
                    return this.processGeneric<IGetSubTotalOfProjectCostingResponse>(
                        response_
                    );
                })
            )
            .pipe(
                _observableCatch((response_: any) => {
                    if (response_ instanceof HttpResponseBase) {
                        try {
                            return this.processGeneric<IGetSubTotalOfProjectCostingResponse>(
                                response_ as any
                            );
                        } catch (e) {
                            return _observableThrow(
                                e
                            ) as any as Observable<IGetSubTotalOfProjectCostingResponse>;
                        }
                    } else
                        return _observableThrow(
                            response_
                        ) as any as Observable<IGetSubTotalOfProjectCostingResponse>;
                })
            );
    }
    protected processGeneric<T>(response: HttpResponseBase): Observable<T> {
        const status = response.status;
        const responseBlob =
            response instanceof HttpResponse
                ? response.body
                : (response as any).error instanceof Blob
                    ? (response as any).error
                    : undefined;

        let _headers: any = {};
        if (response.headers) {
            for (let key of response.headers.keys()) {
                _headers[key] = response.headers.get(key);
            }
        }
        if (status === 200) {
            return blobToText(responseBlob).pipe(
                _observableMergeMap((_responseText: string) => {
                    let resultData200 =
                        _responseText === ""
                            ? null
                            : JSON.parse(_responseText, this.jsonParseReviver);
                    return _observableOf(resultData200);
                })
            );
        } else if (status !== 200 && status !== 204) {
            return blobToText(responseBlob).pipe(
                _observableMergeMap((_responseText: string) => {
                    return throwException(
                        "An unexpected server error occurred.",
                        status,
                        _responseText,
                        _headers
                    );
                })
            );
        }
        return _observableOf(null as any);
    }

    //Get Planner Ctrl for Project Costing Labour Maintenence
    GetPlannerCtrl(projCode: string): Observable<IGetPlannerCtrlDto> {
        let url_ =
            this.baseUrl +
            "/api/services/app/PlannerCtrl/GetPlannerCtrlByProjectCode?";
        url_ += "ProjectCode=" + encodeURIComponent("" + projCode) + "&";
        url_ = url_.replace(/[?&]$/, "");
        let options_: any = {
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                Accept: "text/plain",
            }),
        };

        return this.http
            .request("get", url_, options_)
            .pipe(
                _observableMergeMap((response_: any) => {
                    return this.processGetPlannerCtrlt(response_);
                })
            )
            .pipe(
                _observableCatch((response_: any) => {
                    if (response_ instanceof HttpResponseBase) {
                        try {
                            return this.processGetPlannerCtrlt(response_ as any);
                        } catch (e) {
                            return _observableThrow(
                                e
                            ) as any as Observable<IGetPlannerCtrlDto>;
                        }
                    } else
                        return _observableThrow(
                            response_
                        ) as any as Observable<IGetPlannerCtrlDto>;
                })
            );
    }
    protected processGetPlannerCtrlt(
        response: HttpResponseBase
    ): Observable<IGetPlannerCtrlDto> {
        const status = response.status;
        const responseBlob =
            response instanceof HttpResponse
                ? response.body
                : (response as any).error instanceof Blob
                    ? (response as any).error
                    : undefined;

        let _headers: any = {};
        if (response.headers) {
            for (let key of response.headers.keys()) {
                _headers[key] = response.headers.get(key);
            }
        }
        if (status === 200) {
            return blobToText(responseBlob).pipe(
                _observableMergeMap((_responseText: string) => {
                    let result200: any = null;
                    let resultData200 =
                        _responseText === ""
                            ? null
                            : JSON.parse(_responseText, this.jsonParseReviver);
                    // result200 = ProjectCostingPagedResultDto.fromJS(resultData200);
                    return _observableOf(resultData200);
                })
            );
        } else if (status !== 200 && status !== 204) {
            return blobToText(responseBlob).pipe(
                _observableMergeMap((_responseText: string) => {
                    return throwException(
                        "An unexpected server error occurred.",
                        status,
                        _responseText,
                        _headers
                    );
                })
            );
        }
        return _observableOf(null as any);
    }

    //  CreateProjectCostingNotes
    CreateProjectCostingNotes(data: any): Observable<number> {
        let url_ = this.baseUrl + "/api/services/app/ProjectCostingNote/Create";
        url_ = url_.replace(/[?&]$/, "");

        let options_: any = {
            body: data,
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                "Content-Type": "application/json-patch+json",
                Accept: "text/plain",
            }),
        };
        return this.http
            .request("post", url_, options_)
            .pipe(
                _observableMergeMap((response_: any) => {
                    return this.processGeneric<any>(response_);
                })
            )
            .pipe(
                _observableCatch((response_: any) => {
                    if (response_ instanceof HttpResponseBase) {
                        try {
                            return this.processGeneric<any>(response_ as any);
                        } catch (e) {
                            return _observableThrow(e) as any as Observable<number>;
                        }
                    } else
                        return _observableThrow(response_) as any as Observable<number>;
                })
            );
    }
    //GetDataForCostingLabourMaintenanceChartSP
    //   GetDataForChart(projCode: string): Observable<IGetDataForCostingLabourMaintenanceChart> {
    //     let url_ =
    //       this.baseUrl +
    //       "/api/services/app/ProjectCostingLabour/GetDataForCostingLabourMaintenanceChartSP?";
    //     url_ += "ProjectCode=" + encodeURIComponent("" + projCode) + "&";
    //     url_ = url_.replace(/[?&]$/, "");
    //     let options_: any = {
    //       observe: "response",
    //       responseType: "blob",
    //       headers: new HttpHeaders({
    //         Accept: "text/plain",
    //       }),
    //     };

    //     return this.http
    //       .request("get", url_, options_)
    //       .pipe(
    //         _observableMergeMap((response_: any) => {
    //           return this.processGeneric(response_);
    //         })
    //       )
    //       .pipe(
    //         _observableCatch((response_: any) => {
    //           if (response_ instanceof HttpResponseBase) {
    //             try {
    //               return this.processGeneric(response_ as any);
    //             } catch (e) {
    //               return _observableThrow(
    //                 e
    //               ) as any as Observable<IGetDataForCostingLabourMaintenanceChart>;
    //             }
    //           } else
    //             return _observableThrow(
    //               response_
    //             ) as any as Observable<IGetDataForCostingLabourMaintenanceChart>;
    //         })
    //       );
    //   }
    GetDataForChart(
        data: string
    ): Observable<IGetDataForCostingLabourMaintenanceChart> {
        let url_ =
            this.baseUrl +
            "/api/services/app/ProjectCostingLabour/GetDataForCostingLabourMaintenanceChartSP?";
        url_ += "ProjectCode=" + encodeURIComponent("" + data) + "&";
        url_ = url_.replace(/[?&]$/, "");

        let options_: any = {
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                Accept: "text/plain",
            }),
        };

        return this.http
            .request("get", url_, options_)
            .pipe(
                _observableMergeMap((response_: any) => {
                    return this.processGeneric<IGetDataForCostingLabourMaintenanceChart>(
                        response_
                    );
                })
            )
            .pipe(
                _observableCatch((response_: any) => {
                    if (response_ instanceof HttpResponseBase) {
                        try {
                            return this.processGeneric<IGetDataForCostingLabourMaintenanceChart>(
                                response_ as any
                            );
                        } catch (e) {
                            return _observableThrow(
                                e
                            ) as any as Observable<IGetDataForCostingLabourMaintenanceChart>;
                        }
                    } else
                        return _observableThrow(
                            response_
                        ) as any as Observable<IGetDataForCostingLabourMaintenanceChart>;
                })
            );
    }

    CreateProjectCosting(data: string): Observable<boolean> {
        let url_ = this.baseUrl + "/api/services/app/ProjectCosting/CreateProjectCostingMaintenanceLineItemSP";
        url_ = url_.replace(/[?&]$/, "");
        const content_ = {
            jsonData: data,
        };

        let options_: any = {
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                "Content-Type": "application/json-patch+json",
                Accept: "text/plain",
            }),
        };
        return this.http.post(url_, content_, options_).pipe(
            _observableMergeMap((response_: any) => {
                return this.processGeneric<any>(response_);
            })
        )
            .pipe(
                _observableCatch((response_: any) => {
                    if (response_ instanceof HttpResponseBase) {
                        try {
                            return this.processGeneric<any>(response_ as any);
                        } catch (e) {
                            return _observableThrow(e) as any as Observable<boolean>;
                        }
                    } else
                        return _observableThrow(response_) as any as Observable<boolean>;
                })
            );
    }

    // openFileInBrowser(attachmentLink: string): Observable<any> {
    //     let url_ = `${this.baseUrl}/api/services/app/FTPFileAppServices/OpenFileInBrowser`;
    //     let data = {
    //       ftpUrl: attachmentLink,
    //     };
    //     let options_: any = {
    //       observe: "response",
    //       responseType: "blob",
    //       headers: new HttpHeaders({
    //         Accept: "text/plain",
    //       }),
    //     };

    //     return this.http.post(url_, data, options_).pipe(
    //       mergeMap((response: any) => this.processGeneric<any>(response)),
    //       catchError((error: any) => {
    //         if (error instanceof HttpResponseBase) {
    //           return this.processGeneric<any>(error as any);
    //         } else {
    //           return throwError(() => error);
    //         }
    //       })
    //     );
    //   }

    downloadFileFromFTP(ftpUrl: string): Observable<Blob> {
        let url_ = `${this.baseUrl}/api/services/app/File/CreateFileStream`;
        return this.http.post<Blob>(url_, { ftpUrl }, { responseType: 'blob' as 'json' });
      }


}
export interface IProjectCostingPagedResultDto {
    items: GetProjectCostingDto[] | undefined;
    totalCount: number;
}
export class ProjectCostingPagedResultDto
    implements IProjectCostingPagedResultDto {
    items: GetProjectCostingDto[] | undefined;
    totalCount: number;
    constructor(data?: IProjectCostingPagedResultDto) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            if (Array.isArray(_data["items"])) {
                this.items = [] as any;
                for (let item of _data["items"])
                    this.items.push(GetProjectCostingDto.fromJS(item));
            }
            this.totalCount = _data["totalCount"];
        }
    }

    static fromJS(data: any): ProjectCostingPagedResultDto {
        data = typeof data === "object" ? data : {};
        let result = new ProjectCostingPagedResultDto();
        result.init(data);
        return result;
    }
}

//Get Dtos
export interface IGetProjectCostingDto {
    Id: number;
    ProjCode: string;
    Area: string;
    PrimaryActivity: string;
    SecondaryActivity: string;
    Item: string;
    VariationNo: string;
    PDFLink: string;
    CostCentreMCCC: number;
    QMT: number;
    CostMaterialMCR: number;
    M: number;
    MTDAT: number;
    MFCT: number;
    PercentSpent: number;
    PONo: number;
    Override: string;
    Updated: moment.Moment;
    Mcontingency: number;
    CostCentreLCCC: number;
    HoursLabourQLT: number;
    CostLabRateLCR: number;
    L: number;
    LTDAT: number;
    LC: number;
    EstimatedProfit: number;
    ProfitToDate: number;
    ForecastProfit: number;
    Comment: string;
    Description: string;
    Attachments: string;
    DateStamp: moment.Moment;
    Paseq: number;
    NoOfPurchOrds: number;
    NotesRecorded: number;
    BlanketOrder: boolean;
    CommsBlanketOrder: boolean;
    OtherOrders: boolean;
    Srch: string;
    Hilite: boolean;
    Hide: boolean;
    Sort: number;
    McitySortSeq: number;
    GlobalSortKey: number;
    GeorgeStSort: number;
    Mains: boolean;
    BuildingA: boolean;
    BuildingB: boolean;
    BuildingsCDAndE: boolean;
    Comms: boolean;
    AreaId: number;
    SellingMaterialMSR: number;
    SellingMaterialMST: number;
    CosLabourLCT: number;
    SellingLabourLSR: number;
    SellingLabourLST: number;
    CombinedSellingCST: number;
    CostMaterialMCT: number;
    HiddenItem:boolean
}

export class GetProjectCostingDto implements IGetProjectCostingDto {
    Id: number;
    ProjCode: string;
    Area: string;
    PrimaryActivity: string;
    SecondaryActivity: string;
    Item: string;
    VariationNo: string;
    PDFLink: string;
    CostCentreMCCC: number;
    QMT: number;
    CostMaterialMCR: number;
    M: number;
    MTDAT: number;
    MFCT: number;
    PercentSpent: number;
    PONo: number;
    Override: string;
    Updated: moment.Moment;
    Mcontingency: number;
    CostCentreLCCC: number;
    HoursLabourQLT: number;
    CostLabRateLCR: number;
    L: number;
    LTDAT: number;
    LC: number;
    EstimatedProfit: number;
    ProfitToDate: number;
    ForecastProfit: number;
    Comment: string;
    Description: string;
    Attachments: string;
    DateStamp: moment.Moment;
    Paseq: number;
    NoOfPurchOrds: number;
    NotesRecorded: number;
    BlanketOrder: boolean;
    CommsBlanketOrder: boolean;
    OtherOrders: boolean;
    Srch: string;
    Hilite: boolean;
    Hide: boolean;
    Sort: number;
    McitySortSeq: number;
    GlobalSortKey: number;
    GeorgeStSort: number;
    Mains: boolean;
    BuildingA: boolean;
    BuildingB: boolean;
    BuildingsCDAndE: boolean;
    Comms: boolean;
    AreaId: number;
    SellingMaterialMSR: number;
    SellingMaterialMST: number;
    CosLabourLCT: number;
    SellingLabourLSR: number;
    SellingLabourLST: number;
    CombinedSellingCST: number;
    CostMaterialMCT: number;
    ProjectCostingNotes: string;
    isChecked?: boolean;
    tempPono?: number;
    HiddenItem:boolean

    constructor(data?: IGetProjectCostingDto) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }
    init(_data?: any) {
        if (_data) {
            this.Id = _data["id"];
            this.ProjCode = _data["projCode"];
            this.Area = _data["area"];
            this.PrimaryActivity = _data["primaryActivity"];
            this.SecondaryActivity = _data["secondaryActivity"];
            this.Item = _data["item"];
            this.VariationNo = _data["variationNo"];
            this.VariationNo = _data["variationNo"];
            this.PDFLink = _data["pdfLink"];
            this.QMT = _data["qmt"];
            this.CostMaterialMCR = _data["costMaterialMCR"];
            this.M = _data["m"];
            this.CostCentreMCCC = _data["costCentreMCCC"];
            this.MTDAT = _data["mTDAT"];
            this.MFCT = _data["mfct"];
            this.PercentSpent = _data["percentSpent"];
            this.PONo = _data["poNo"];
            this.Override = _data["override"];
            this.Updated = _data["updated"];
            this.Mcontingency = _data["mcontingency"];
            this.CostCentreLCCC = _data["costCentreLCCC"];
            this.HoursLabourQLT = _data["hoursLabourQLT"];
            this.CostLabRateLCR = _data["costLabRateLCR"];
            this.L = _data["l"];
            this.LTDAT = _data["lTDAT"];
            this.LC = _data["lc"];
            this.EstimatedProfit = _data["estimatedProfit"];
            this.ProfitToDate = _data["profitToDate"];
            this.ForecastProfit = _data["forecastProfit"];
            this.Comment = _data["comment"];
            this.Description = _data["description"];
            this.Attachments = _data["attachments"];
            this.DateStamp = _data["dateStamp"];
            this.Paseq = _data["paseq"];
            this.NoOfPurchOrds = _data["noOfPurchOrds"];
            this.NotesRecorded = _data["notesRecorded"];
            this.BlanketOrder = _data["blanketOrder"];
            this.CommsBlanketOrder = _data["commsBlanketOrder"];
            this.OtherOrders = _data["otherOrders"];
            this.Srch = _data["srch"];
            this.Hilite = _data["hilite"];
            this.Hide = _data["hide"];
            this.Sort = _data["sort"];
            this.McitySortSeq = _data["mcitySortSeq"];
            this.GeorgeStSort = _data["georgeStSort"];
            this.Mains = _data["mains"];
            this.BuildingA = _data["buildingA"];
            this.BuildingB = _data["buildingB"];
            this.BuildingsCDAndE = _data["buildingsCDAndE"];
            this.Comms = _data["comms"];
            this.AreaId = _data["areaId"];
            this.SellingMaterialMSR = _data["sellingMaterialMSR"];
            this.SellingMaterialMST = _data["sellingMaterialMST"];
            this.CosLabourLCT = _data["cosLabourLCT"];
            this.SellingLabourLSR = _data["sellingLabourLSR"];
            this.SellingLabourLST = _data["sellingLabourLST"];
            this.CombinedSellingCST = _data["combinedSellingCST"];
            this.CostMaterialMCT = _data["costMaterialMCT"];
            this.ProjectCostingNotes = _data["projectCostingNotes"];
            this.isChecked = false;
            this.tempPono = null;
            this.HiddenItem = _data["hiddenItem"] ?? false;
        }
    }
    static fromJS(data: any): GetProjectCostingDto {
        data = typeof data === "object" ? data : {};
        let result = new GetProjectCostingDto();
        result.init(data);
        return result;
    }
}

// Get ProjectCostingLabourMaintainance
export interface IProjectCostingLabourMaintainance {
    totalCount: number;
    items: IPCLabourMaintainaceItem[];
}

// GetPurchaseOrderDetailForProjectCostingMaintenance

export interface IPurchaseOrderDetailForProjectCostingMaintenance {
    totalCount: number;
    items: IPurchaseOrderDetailForProjectCostingMaintenanceItems[];
}

export interface IPurchaseOrderDetailForProjectCostingMaintenanceItems {
    pO_OrderNo: number;
    pO_Shortname: string;
    pO_Description: string;
    pO_OrderDate: moment.Moment;
    pO_OrderTotal: number;
    pO_ReceivedDate: moment.Moment;
    pO_ReceivedTotal: number;
    pO_JobNo: string;
    pO_JobCostCentre: number;
    pO_UserName: string;
    pO_BackOrderFlag: string;
}

export interface IPagedPOProjectCostingMaintenanceRequestResult {
    keyword: string;
    purchaseOrderNo: number;
    purchaseOrderJobNo: string;
}

export class PagedPOProjectCostingMaintenanceRequestResult
    implements IPagedPOProjectCostingMaintenanceRequestResult {
    keyword: string;
    purchaseOrderNo: number;
    purchaseOrderJobNo: string;
}

export interface IPCLabourMaintainaceItem {
    id: number,
    projectCode: string;
    costentreLCCC: number;
    costLabourLCT: number | string;
    lc: number;
    ltdat: number;
    lftct: number;
    contingency: number;
    comment?: any;
    totalForecast?: any;
    lctot: number;
}

export interface IUpdateLftctOnLcSP {
        projectCode: string;
        lccc: number;
        id: number;
        lc: number;
}

export interface IUpdateContigencyOnLcSp {
    id: number;
    projectCode: string;
    contigency: number;
}

export interface IGetSubTotalOfProjectCosting {
    keyword: string;
    ProjectCode: string;
    TableName: string;
}

export interface IGetSubTotalOfProjectCostingResponse {
    items: IGetSubTotalOfProjectCostingItems | undefined;
    totalCount: number;
}

export interface IGetSubTotalOfProjectCostingItems {
    sumLTDAT:number | null;
    sumCostLabour: number | null;
    sumLFTCT: number | null;
    sumContingency: number | null;
    sumTotalForecast: number | null;
    sumLCTOT: number | null;
    costMaterialMCT: number | null;
    mfct: number | null;
    lct: number | null,
    mst: number | null,
    lst: number | null,
    cst: number | null
}

export enum TableName {
    ProjectCosting = "projectCosting",
    ProjectCostingLabour = "projectCostingLabour",
}

// Planner Ctrl
export interface IGetPlannerCtrlDto {
    id: number;
    projCode: string;
    projName: string;
    forecastCommencing: moment.Moment;
    forecastPeriod: string;
    noOfWeeks: 0;
    noOfManWeeks: 0;
    totalCost: 0;
    filePath: string;
    submissionDate: moment.Moment;
    reportingReq: string;
    ssmaTimeStamp: string;
}

export interface IGetDataForCostingLabourMaintenanceChart {
    items: IGetDataForCostingLabourMaintenanceChartItems | undefined;
    totalCount: number;
}
export interface IGetDataForCostingLabourMaintenanceChartItems {
    weekEnding: moment.Moment;
    tenderCost: string;
    normal: number;
    office: number;
    overtime: string;
    lctot: number;
    ltot: number;
    projectCode: string;
    projectName: string;
}

// create Project Costing maintenance
// export interface ICreateProjectCostingDialouge {
//   project: string,
//   PrimaryActivity: string,
//   SecondaryActivity: string,
//   Item: string,
//   Mccc: number,
//   Qmt: number,
//   Mcr: number,
//   Qlt: number,
//   Lcr: number,
//   Msr: number,
//   Mst: number,
//   Lsr: number,
//   Lct: number,
//   Cst: number,
//   VarNo: number,
// }


export interface IAddProjectCostingSp {
    ProjCode: string,
    Area: string,
    PrimaryActivity: string,
    SecondaryActivity: string,
    Item: string,
    VariationNo: number,
    CostCentreMccc: number,
    Qmt: number,
    CostMaterialMcr: number,
    M: number,
    CostMaterialMct: number,
    Mtdat: number,
    Mfct: number,
    PoNo: number,
    CostCentreLccc: number,
    HoursLabourQlt: number,
    CostLabRateLcr: number,
    L: number,
    CostLabourLct: number,
    Ltdat: number,
    Lc: number,
    Ltdct: number,
    Lftct: number,
    SellingMaterialMsr: number,
    SellingMaterialMst: number,
    SellingLabourLsr: number,
    SellingLabourLst: number,
    CombinedSellingCst: number,
    EstimatedProfit: number,
    ProfitToDate: number,
    ForecastProfit: number,
    Comment: string,
    Description: string,

}


//blob and exception
function throwException(
    message: string,
    status: number,
    response: string,
    headers: { [key: string]: any },
    result?: any
): Observable<any> {
    if (result !== null && result !== undefined) return _observableThrow(result);
    else
        return _observableThrow(
            new ApiException(message, status, response, headers, null)
        );
}

function blobToText(blob: any): Observable<string> {
    return new Observable<string>((observer: any) => {
        if (!blob) {
            observer.next("");
            observer.complete();
        } else {
            let reader = new FileReader();
            reader.onload = (event) => {
                observer.next((event.target as any).result);
                observer.complete();
            };
            reader.readAsText(blob);
        }
    });
}
