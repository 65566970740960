import { AbpMultiTenancyService, UtilsService } from 'abp-ng2-module';
import { Injectable } from '@angular/core';
import {
    ApplicationInfoDto,
    GetCurrentLoginInformationsOutput,
    SessionServiceProxy,
    TenantLoginInfoDto,
    UserLoginInfoDto
} from '@shared/service-proxies/service-proxies';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AppConsts } from '@shared/AppConsts';
import { Router } from '@angular/router';

@Injectable()
export class AppSessionService {

    public _user: UserLoginInfoDto;
    private _tenant: TenantLoginInfoDto;
    private _application: ApplicationInfoDto;

    constructor(
        private _sessionService: SessionServiceProxy,
        private _abpMultiTenancyService: AbpMultiTenancyService,
        private _utilsService: UtilsService,
        private router:Router
     ) {
    }

    get application(): ApplicationInfoDto {
        return this._application;
    }

    get user(): UserLoginInfoDto {
        return this._user;
    }

    get userId(): number {
        return this.user ? this.user.id : null;
    }

    get tenant(): TenantLoginInfoDto {
        return this._tenant;
    }

    get tenantId(): number {
        return this.tenant ? this.tenant.id : null;
    }

    getShownLoginName(): string {
        const userName = this._user.userName;
        if (!this._abpMultiTenancyService.isEnabled) {
            return userName;
        }

        return (this._tenant ? this._tenant.tenancyName : '.') + '\\' + userName;
    }

    init(): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            this._sessionService.getCurrentLoginInformations().toPromise().then((result: GetCurrentLoginInformationsOutput) => {
                this._application = result.application;
                this._user = result.user;
                this._tenant = result.tenant;

                resolve(true);
            }, (err) => {
                reject(err);
            });
        });
    }

    changeTenantIfNeeded(tenantId?: number): boolean {
        if (this.isCurrentTenant(tenantId)) {
            return false;
        }

        abp.multiTenancy.setTenantIdCookie(tenantId);
        location.reload();
        return true;
    }

    private isCurrentTenant(tenantId?: number) {
        if (!tenantId && this.tenant) {
            return false;
        } else if (tenantId && (!this.tenant || this.tenant.id !== tenantId)) {
            return false;
        }

        return true;
    }

    public IsAuthenticated(): boolean {
        const token = this._utilsService.getCookieValue(AppConsts.AbpAuthToken);
    
        if (!token) {
            return false;
        }
    
        const helper = new JwtHelperService();
        if (helper.isTokenExpired(token)) {
            this._logout();
            return false;
        }
    
        const decodedToken = helper.decodeToken(token);
        const nameId = Number(decodedToken.nameid);
        const userId = this._user?.id || 0; // Using optional chaining to handle null _user
    
        if (nameId !== userId) {
            this._user = null;
            location.reload();
            return false;
        }
    
        return true;
    }
    

    private _logout(){
        this._user=null;
        abp.auth.clearToken();
        abp.utils.deleteCookie(AppConsts.authorization.encryptedAuthTokenName);
        // this.router.navigate([AppConsts.appBaseUrl]);
        // this.router.navigateByUrl('/account/login');
        let log = {
            session_expired: true
        }
        this.router.navigate(["/account/login"], { state: log });
    }
    
    
}
