
<div class="modal-footer justify-content-end">
  <!-- <button
    type="button"
    class="btn btn-default"
    [disabled]="cancelDisabled"
    (click)="onCancelClick.emit()"
  >
    {{ cancelLabel }}
  </button> -->
  <button type="submit" class="btn btn-success" [disabled]="saveDisabled">
    {{ saveLabel }}
  </button>
</div>
