<!-- <div >
  <input  #searchInput="ngModel" name="search" type="text" class="form-control" placeholder="Search..." [(ngModel)]="searchText" (input)="filterOptions()">
  <div >
    <ul   *ngIf="searchInput.dirty || searchInput.touched">
      <li *ngFor="let option of filteredOptions"  (click)="selectOption(option)">
        {{ option.text }}
      </li>
    </ul>
  </div>
</div> -->

<div class="dropdown">
  <input #searchInput="ngModel" (focusin)="openDropdown(true)" (focusout)="openDropdown(false)" name="search" type="text" class="form-control fs-12" [placeholder]="placeHolder" [(ngModel)]="searchText" (input)="filterOptions()">
  <ul class="dropdown-menu fs-12" *ngIf="isFocus">
    <ng-container  *ngIf="filteredOptions?.length else noData">
      <li *ngFor="let option of filteredOptions" type="button" (click)="selectOption(option)">
        {{ option.text }}
      </li>
    </ng-container>
    <ng-template #noData>
      no record 
    </ng-template>
  </ul>
</div>



