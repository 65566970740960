import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {NotFoundComponent} from '../src/shared/components/not-found/not-found.component'
import { AppRouteGuard } from '@shared/auth/auth-route-guard';
import { PublicCustomerComponent } from '@shared/components/public-customer/create-form.component';
const routes: Routes = [
    { path: 'customer-registration', component: PublicCustomerComponent },
    { path: '', redirectTo: '/account/login', pathMatch: 'full' },
    {
        path: 'account',
        loadChildren: () => import('account/account.module').then(m => m.AccountModule),
    },
    {
        path: 'app',
        data:{permission:'Admin.View'},
        loadChildren: () => import('app/app.module').then(m => m.AppModule),
        canLoad:[AppRouteGuard]
    },
    {
        path: 'dakonex',
        data:{permission:'Dakonex.View'},
        loadChildren: () => import('dakonex/dakonex.module').then(m => m.DakonexModule),
        canLoad:[AppRouteGuard]
    },
    {
        path: 'job-costing-service',
        data:{permission:'JobCostingServices.View'},
        loadChildren: () => import('modules/job-costing-services/job-costing-services.module').then(m => m.JobCostingServicesModule),
        canLoad:[AppRouteGuard]
    },
    {
        path: 'a-line-business-system',
        loadChildren: () => import('modules/aLine-business-system/aLine-business-system.module').then(m => m.ALineBusinessSystemModule),
        canLoad:[AppRouteGuard]
    },
    {
        path: 'illuminate',
        data:{permission:'Illuminate.View'},
        loadChildren: () => import('modules/illuminate/illuminate.module').then(m => m.IlluminateModule),
        canLoad:[AppRouteGuard]
    },

    { path: '**', component: NotFoundComponent },
    { path: 'access-denied', component: NotFoundComponent },

];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
    providers: []
})
export class RootRoutingModule { }
