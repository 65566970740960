import { UrlHistoryService } from './../service-proxies/url-history.service';
import { Injectable } from '@angular/core';
import { PermissionCheckerService } from 'abp-ng2-module';
import { AppSessionService } from '../session/app-session.service';

import {
    CanActivate, Router,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    CanActivateChild,
    CanLoad,
    Route,
    UrlSegment,
} from '@angular/router';

@Injectable()
export class AppRouteGuard implements CanActivate, CanActivateChild,CanLoad {

    constructor(
        private _permissionChecker: PermissionCheckerService,
        private _router: Router,
        private _sessionService: AppSessionService,
        private urlHistoryService:UrlHistoryService
    ) { }
    canLoad(route: Route, segments: UrlSegment[]): boolean  {
        // if (!this._sessionService.user) {
        //     this._router.navigate(['/account/login']);
        //     return false;
        // }
        if (!this._sessionService.IsAuthenticated()) {
            this._router.navigate(['/account/login']);
            return false;
        }
        if (!route.data || !route.data['permission']) {
            return true;
        }

        if (this._permissionChecker.isGranted(route.data['permission'])) {
            return true;
        }

        this._router.navigate([this.selectBestRoute()]);
        return false;

        // return true;
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        // if (!this._sessionService.user) {
        //     this._router.navigate(['/account/login']);
        //     return false;
        // }
        this.urlHistoryService.addToUrlHistory(state.url);

         if(!this._sessionService.IsAuthenticated() ){
            this._router.navigate(['/account/login']);
            return false;
        }

        if (!route.data || !route.data['permission']) {
            return true;
        }

        if (this._permissionChecker.isGranted(route.data['permission'])) {
            return true;
        }

        this._router.navigate([this.selectBestRoute()]);
        return false;
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        return this.canActivate(route, state);
    }

    selectBestRoute(): string {
        // if (!this._sessionService.user) {
        //     return '/account/login';
        // }

        if (!this._sessionService.IsAuthenticated()) {
            return '/account/login';
        }

        if(this._permissionChecker.isGranted('Dakonex.View')) {
            return '/dakonex/home';
        }
       else if (this._permissionChecker.isGranted('Admin.View')) {
            return '/app/home';
        }
        else if(this._permissionChecker.isGranted('JobCosting.View')) {
            return 'job-costing-service/home';
        }
        else if(this._permissionChecker.isGranted('Illuminate.View')) {
            return 'illuminate/home';
        }
        else if(this._permissionChecker.isGranted('Aline.View')) {
            return 'illuminate/home';
        }
        else{
            let data = {
                access_forbidden: true
            }
            this._router.navigate(["access-denied"], { state: data });
            // abp.notify.info('unauthorized action','403');
            return;
        }

        // return '/app/home';
    }
}
