import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponseBase,
} from "@angular/common/http";
import { API_BASE_URL } from "@shared/service-proxies/service-proxies";
import { GenericService } from "@shared/service-proxies/generic.service";
import { Inject, Injectable, Optional } from "@angular/core";
import { mergeMap, catchError } from "rxjs/operators";
import { Observable, throwError } from "rxjs";

@Injectable()
export class PublicCustomerService extends GenericService {
  private baseUrl: string;
  constructor(
    private http: HttpClient,
    @Optional() @Inject(API_BASE_URL) baseUrl?: string
  ) {
    super();
    this.baseUrl = baseUrl !== undefined && baseUrl !== null ? baseUrl : "";
  }

  //create
  create(data: ICreate): Observable<any> {
    let url_ = `${this.baseUrl}/api/services/app/Customer/Create`;
    let body = data;

    let options_: any = {
      observe: "response",
      responseType: "blob",
      headers: new HttpHeaders({
        Accept: "text/plain",
      }),
    };

    return this.http.post(url_, body, options_).pipe(
      mergeMap((response: any) => this.processGeneric<any>(response)),
      catchError((error: any) => {
        if (error instanceof HttpResponseBase) {
          return this.processGeneric<any>(error as any);
        } else {
          return throwError(() => error);
        }
      })
    );
  }
}

// Interfaces'

export interface ICreate {
  company: string;
  lastName: string;
  firstName: string;
  eMailAddress: string;
  businessPhone: string;
  mobilePhone: string;
  address: string;
  city: string;
  state: string;
  postalCode: string;
  country: string;
  abn: string;
  customerStatus: number;
}
