import {
  mergeMap as _observableMergeMap,
  catchError as _observableCatch,
} from "rxjs/operators";
import {
  Observable,
  throwError as _observableThrow,
  of as _observableOf,
  of,
} from "rxjs";
import { Injectable, Inject, Optional, InjectionToken } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
  HttpResponse,
  HttpResponseBase,
} from "@angular/common/http";

import * as moment from "moment";
import { ApiException, UserDto } from "../../service-proxies";
import { API_BASE_URL } from "@shared/service-proxies/service-proxies";
import { extend } from "lodash-es";
import { GetDepartmentDto } from "@app/departments/department.service";

// export const API_BASE_URL = new InjectionToken<string>("API_BASE_URL");

@Injectable()
export class ProjectResourcingServices {
  private http: HttpClient;
  private baseUrl: string;
  protected jsonParseReviver: ((key: string, value: any) => any) | undefined =
    undefined;

  constructor(
    @Inject(HttpClient) http: HttpClient,
    @Optional() @Inject(API_BASE_URL) baseUrl?: string
  ) {
    this.http = http;
    this.baseUrl = baseUrl !== undefined && baseUrl !== null ? baseUrl : "";
  }

  // GetSiteAllowances Starts
  GetSiteAllowances(
    projectCode: string
  ): Observable<ISummaryofGetSiteAllowances> {
    let url_ =
      this.baseUrl + "/api/services/app/SiteAllowances/GetAllSiteAllowancesSP?";
    if (projectCode !== undefined) {
      url_ += "ProjectCode=" + encodeURIComponent("" + projectCode) + "&";
    }
    url_ = url_.replace(/[?&]$/, "");
    let options_: any = {
      observe: "response",
      responseType: "blob",
      headers: new HttpHeaders({
        Accept: "text/plain",
      }),
    };

    return this.http
      .request("get", url_, options_)
      .pipe(
        _observableMergeMap((response_: any) => {
          return this.processGetSiteAllowances(response_);
        })
      )
      .pipe(
        _observableCatch((response_: any) => {
          if (response_ instanceof HttpResponseBase) {
            try {
              return this.processGetSiteAllowances(response_ as any);
            } catch (e) {
              return _observableThrow(
                e
              ) as any as Observable<ISummaryofGetSiteAllowances>;
            }
          } else
            return _observableThrow(
              response_
            ) as any as Observable<ISummaryofGetSiteAllowances>;
        })
      );
  }
  protected processGetSiteAllowances(
    response: HttpResponseBase
  ): Observable<ISummaryofGetSiteAllowances> {
    const status = response.status;
    const responseBlob =
      response instanceof HttpResponse
        ? response.body
        : (response as any).error instanceof Blob
        ? (response as any).error
        : undefined;

    let _headers: any = {};
    if (response.headers) {
      for (let key of response.headers.keys()) {
        _headers[key] = response.headers.get(key);
      }
    }
    if (status === 200) {
      return blobToText(responseBlob).pipe(
        _observableMergeMap((_responseText: string) => {
          let result200: any = null;
          let resultData200 =
            _responseText === ""
              ? null
              : JSON.parse(_responseText, this.jsonParseReviver);
          return _observableOf(resultData200);
        })
      );
    } else if (status !== 200 && status !== 204) {
      return blobToText(responseBlob).pipe(
        _observableMergeMap((_responseText: string) => {
          return throwException(
            "An unexpected server error occurred.",
            status,
            _responseText,
            _headers
          );
        })
      );
    }
    return _observableOf(null as any);
  }
  // GetSiteAllowances Ends

  // GetAllPayRollDataForWeekSP Starts
  GetAllPayRollDataForWeekSP(
    projectCode: string
  ): Observable<ISummaryofGetAllPayRollDataForWeekSP> {
    let url_ =
      this.baseUrl +
      "/api/services/app/SiteAllowances/GetAllPayRollDataForWeekSP?";
    if (projectCode !== undefined) {
      url_ += "ProjectCode=" + encodeURIComponent("" + projectCode) + "&";
    }
    url_ = url_.replace(/[?&]$/, "");
    let options_: any = {
      observe: "response",
      responseType: "blob",
      headers: new HttpHeaders({
        Accept: "text/plain",
      }),
    };

    return this.http
      .request("get", url_, options_)
      .pipe(
        _observableMergeMap((response_: any) => {
          return this.processGetAllPayRollDataForWeekSP(response_);
        })
      )
      .pipe(
        _observableCatch((response_: any) => {
          if (response_ instanceof HttpResponseBase) {
            try {
              return this.processGetAllPayRollDataForWeekSP(response_ as any);
            } catch (e) {
              return _observableThrow(
                e
              ) as any as Observable<ISummaryofGetAllPayRollDataForWeekSP>;
            }
          } else
            return _observableThrow(
              response_
            ) as any as Observable<ISummaryofGetAllPayRollDataForWeekSP>;
        })
      );
  }
  protected processGetAllPayRollDataForWeekSP(
    response: HttpResponseBase
  ): Observable<ISummaryofGetAllPayRollDataForWeekSP> {
    const status = response.status;
    const responseBlob =
      response instanceof HttpResponse
        ? response.body
        : (response as any).error instanceof Blob
        ? (response as any).error
        : undefined;

    let _headers: any = {};
    if (response.headers) {
      for (let key of response.headers.keys()) {
        _headers[key] = response.headers.get(key);
      }
    }
    if (status === 200) {
      return blobToText(responseBlob).pipe(
        _observableMergeMap((_responseText: string) => {
          let result200: any = null;
          let resultData200 =
            _responseText === ""
              ? null
              : JSON.parse(_responseText, this.jsonParseReviver);
          return _observableOf(resultData200);
        })
      );
    } else if (status !== 200 && status !== 204) {
      return blobToText(responseBlob).pipe(
        _observableMergeMap((_responseText: string) => {
          return throwException(
            "An unexpected server error occurred.",
            status,
            _responseText,
            _headers
          );
        })
      );
    }
    return _observableOf(null as any);
  }
  // GetAllPayRollDataForWeekSP Ends

  // GetAllPayRollDataForWeekSP Starts
  NotesCreate(obj): Observable<ISummaryofNotesCreate> {
    let url_ = this.baseUrl + "/api/services/app/EmployeeNote/Create";
    url_ = url_.replace(/[?&]$/, "");
    let options_: any = {
      body: obj,
      observe: "response",
      responseType: "blob",
      headers: new HttpHeaders({
        Accept: "text/plain",
      }),
    };

    return this.http
      .request("post", url_, options_)
      .pipe(
        _observableMergeMap((response_: any) => {
          return this.processNotesCreate(response_);
        })
      )
      .pipe(
        _observableCatch((response_: any) => {
          if (response_ instanceof HttpResponseBase) {
            try {
              return this.processNotesCreate(response_ as any);
            } catch (e) {
              return _observableThrow(
                e
              ) as any as Observable<ISummaryofNotesCreate>;
            }
          } else
            return _observableThrow(
              response_
            ) as any as Observable<ISummaryofNotesCreate>;
        })
      );
  }
  protected processNotesCreate(
    response: HttpResponseBase
  ): Observable<ISummaryofNotesCreate> {
    const status = response.status;
    const responseBlob =
      response instanceof HttpResponse
        ? response.body
        : (response as any).error instanceof Blob
        ? (response as any).error
        : undefined;

    let _headers: any = {};
    if (response.headers) {
      for (let key of response.headers.keys()) {
        _headers[key] = response.headers.get(key);
      }
    }
    if (status === 200) {
      return blobToText(responseBlob).pipe(
        _observableMergeMap((_responseText: string) => {
          let result200: any = null;
          let resultData200 =
            _responseText === ""
              ? null
              : JSON.parse(_responseText, this.jsonParseReviver);
          return _observableOf(resultData200);
        })
      );
    } else if (status !== 200 && status !== 204) {
      return blobToText(responseBlob).pipe(
        _observableMergeMap((_responseText: string) => {
          return throwException(
            "An unexpected server error occurred.",
            status,
            _responseText,
            _headers
          );
        })
      );
    }
    return _observableOf(null as any);
  }
  // GetAllPayRollDataForWeekSP Ends
}

//Interface for GetSiteAllowances Api
export interface ISummaryofGetSiteAllowances {
  items: ISummaryofGetSiteAllowancesItems[];
  totalCount: number;
}
export interface ISummaryofGetSiteAllowancesItems {
  id: number;
  employeeNo: number;
  employeeSurname: string;
  employeeGivenNames: string;
  allowance: string;
  aor: string;
  austel: true;
  deleted: string;
  projectCode: string;
  firstAider: true;
  temp: true;
  projectName: string;
}
//Interface for GetSiteAllowances Api

//Interface for GetAllPayRollDataForWeekSP Api
export interface ISummaryofGetAllPayRollDataForWeekSP {
  items: ISummaryofGetAllPayRollDataForWeekSPItems[];
  totalCount: number;
}
export interface ISummaryofGetAllPayRollDataForWeekSPItems {
  cat: string;
  seq: number;
  emWorkRateDescrip: string;
  emSurname: string;
  emGivenNames: string;
  employeeNo: string;
  alTaken12Mnths: number;
  alRemaining: number;
  slTaken12Mnths: number;
  slRemaining: number;
  rdoTaken12Mnths: number;
  rdoRemaining: number;
  emEmploymentClass: string;
  jobCode: string;
  slLastWeek: number;
  plwopTaken12Mnths: number;
  protectTaken: number;
  commencementDate: moment.Moment;
  employeeNotes: string;
}
//Interface for GetAllPayRollDataForWeekSP Api

//Interface for NotesCreate Api
export interface ISummaryofNotesCreate {
  items: ISummaryofNotesCreateItems[];
  totalCount: number;
}
export interface ISummaryofNotesCreateItems {
  employeeNo: string;
  emSurname: string;
  emGivenNames: string;
  ltype: string;
  leaveFrom: moment.Moment;
  leaveTo: moment.Moment;
  comments: string;
  empId: number;
}
//Interface for NotesCreate Api

//blob and exception
function throwException(
  message: string,
  status: number,
  response: string,
  headers: { [key: string]: any },
  result?: any
): Observable<any> {
  if (result !== null && result !== undefined) return _observableThrow(result);
  else
    return _observableThrow(
      new ApiException(message, status, response, headers, null)
    );
}
function blobToText(blob: any): Observable<string> {
  return new Observable<string>((observer: any) => {
    if (!blob) {
      observer.next("");
      observer.complete();
    } else {
      let reader = new FileReader();
      reader.onload = (event) => {
        observer.next((event.target as any).result);
        observer.complete();
      };
      reader.readAsText(blob);
    }
  });
}
