import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { SelectDropDownService } from 'ngx-select-dropdown';
interface dropdownI {
    id: number,
    description: string
};

@Component({
  selector: "app-searchable",
  templateUrl: "./search-dropdown.component.html",
  styleUrls: ["./search-dropdown.component.css"],
})
export class SearchDropDownComponent implements OnInit {
  @Output() selectedValue: EventEmitter<string> = new EventEmitter<string>();
  @Output() statusChanged: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() placeholderText: string;
  @Input() customClass: string;
  @Input() dropdownOptions;
  @Input() dataModel: dropdownI;
  config: any;
  apply_ellipsis: boolean = false;
  private _clearSelection: boolean;

  @Input()
  set clearSelection(value: boolean) {
    this._clearSelection = value;
    if(value){
      this.clearSelectionMethod();
    }
  }
  
  get clearSelection(): boolean {
    return this._clearSelection;
  }

  // private _unSelect: boolean;

  // get makeItUnSelect(): boolean {
  //   return this._unSelect;
  // }

  // @Input()
  // set makeItUnSelect(value: boolean) {
  //   this._unSelect = value;
  //   if (this._unSelect) {
  //     this.selectionChanged(this.dataModel,this._unSelect);
  //   }
  // }
  constructor(private dropdownService: SelectDropDownService) {}

  ngOnInit() {
    this.config = {
      placeholder: this.placeholderText,
      moreText: "more options selected",
      noResultsFound: "No data",
      search: true,
      height: "210px",
      displayKey: "",
    };
  }

  selectionChanged(val: any, check?: boolean) {
    this.selectedValue.emit(val);
      this.onDropdownClose(val);
  }

  onDropdownClose(val) {
    if (!val?.value?.id) {
      this.dropdownService.closeDropdown('instance1');
    }
  }

  clearSelectionMethod(): void {
    this.dataModel = null;
    this.statusChanged.emit(false);
  }

  // onBooleanChanged(value: boolean) {
  //   debugger;
  //   if (value) {
  //     this.clearSelection();
  //   }
  // }

}
