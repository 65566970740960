import {
  mergeMap as _observableMergeMap,
  catchError as _observableCatch,
  mergeMap,
  catchError,
} from "rxjs/operators";
import {
  Observable,
  throwError as _observableThrow,
  of as _observableOf,
  throwError,
} from "rxjs";
import { Injectable, Inject, Optional } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponseBase,
} from "@angular/common/http";

import { ApiException, UserDto } from "../../service-proxies";
import { API_BASE_URL } from "@shared/service-proxies/service-proxies";
import { GenericService } from "@shared/service-proxies/generic.service";

@Injectable()
export class RebateServices extends GenericService {
  private http: HttpClient;
  private baseUrl: string;
  protected jsonParseReviver: ((key: string, value: any) => any) | undefined =
    undefined;

  constructor(
    @Inject(HttpClient) http: HttpClient,
    @Optional() @Inject(API_BASE_URL) baseUrl?: string
  ) {
    super();
    this.http = http;
    this.baseUrl = baseUrl !== undefined && baseUrl !== null ? baseUrl : "";
  }

  // get all data of rebate 
  GetAllRebates(data: IForRebate): Observable<IGetAllRebate> {
    let url_ = `${this.baseUrl}/api/services/app/Rebates/GetAllRebates?`;
    const params = new HttpParams()
      .set("Keyword", data.Keyword || "")
      .set("Sorting", data.Sorting || "")
      .set("MaxResultCount", data.MaxResultCount || 15)
      .set("SkipCount", data.SkipCount || 0)
      .set("ProjectCode", data.ProjectCode || "");
    let options_: any = {
      observe: "response",
      responseType: "blob",
      headers: new HttpHeaders({
        Accept: "text/plain",
      }),
      params: params,
    };
    return this.http.get(url_, options_).pipe(
      mergeMap((response: any) => this.processGeneric<IGetAllRebate>(response)),
      catchError((error: any) => {
        if (error instanceof HttpResponseBase) {
          return this.processGeneric<IGetAllRebate>(error as any);
        } else {
          return throwError(() => error);
        }
      })
    );
  }

  // delete rebate data
  DeleteRebateById(id: number): Observable<boolean> {
    let url_ = `${this.baseUrl}/api/services/app/Rebates/DeleteRebate?`;
    const params = new HttpParams().set("RebateID", id);
    let options_: any = {
      observe: "response",
      responseType: "blob",
      headers: new HttpHeaders({
        Accept: "text/plain",
      }),
      params: params,
    };

    return this.http.delete(url_, options_).pipe(
      mergeMap((response: any) => this.processGeneric<boolean>(response)),
      catchError((error: any) => {
        if (error instanceof HttpResponseBase) {
          return this.processGeneric<boolean>(error as any);
        } else {
          return throwError(() => error);
        }
      })
    );
  }

  // create new rebate 
  CreateRebate(jsonData: string): Observable<any> {
    let url_ = `${this.baseUrl}/api/services/app/Rebates/CreateRebates`;
    let data = {
      inputJson: jsonData,
    };
    let options_: any = {
      observe: "response",
      responseType: "blob",
      headers: new HttpHeaders({
        Accept: "text/plain",
      }),
    };
    return this.http.post(url_, data, options_).pipe(
      mergeMap((response: any) => this.processGeneric<any>(response)),
      catchError((error: any) => {
        if (error instanceof HttpResponseBase) {
          return this.processGeneric<any>(error as any);
        } else {
          return throwError(() => error);
        }
      })
    );
  }

  //update rebate
  UpdateRebate(jsonData: string): Observable<any> {
    let url_ = `${this.baseUrl}/api/services/app/Rebates/UpdateRebates`;
    let body = { jsonParameters: jsonData };

    let options_: any = {
      observe: "response",
      responseType: "blob",
      headers: new HttpHeaders({
        Accept: "text/plain",
      }),
    };

    return this.http.put(url_, body, options_).pipe(
      mergeMap((response: any) => this.processGeneric<any>(response)),
      catchError((error: any) => {
        if (error instanceof HttpResponseBase) {
          return this.processGeneric<any>(error as any);
        } else {
          return throwError(() => error);
        }
      })
    );
  }

}

//rebate Data Interface
export interface IForRebate {
  Keyword?: string;
  Sorting?: string;
  MaxResultCount: number;
  SkipCount: number;
  ProjectCode?: string;
}

// get all rebates interface
export interface IGetAllRebate {
  items: IGetAllRebateItems[];
  totalCount: number;
}
export interface IGetAllRebateItems {
  id: number;
  projCode: string;
  rebDate: string;
  amount: number;
}

//blob and exception
function throwException(
  message: string,
  status: number,
  response: string,
  headers: { [key: string]: any },
  result?: any
): Observable<any> {
  if (result !== null && result !== undefined) return _observableThrow(result);
  else
    return _observableThrow(
      new ApiException(message, status, response, headers, null)
    );
}

function blobToText(blob: any): Observable<string> {
  return new Observable<string>((observer: any) => {
    if (!blob) {
      observer.next("");
      observer.complete();
    } else {
      let reader = new FileReader();
      reader.onload = (event) => {
        observer.next((event.target as any).result);
        observer.complete();
      };
      reader.readAsText(blob);
    }
  });
}
