import { Component } from '@angular/core';

@Component({
    selector: 'app-root',
    template: `
    <ngx-spinner  color = "#28a745"  [name]="'common_spinner'"  type="ball-scale-multiple">
    <p style="color: white" > Just a Moment... </p></ngx-spinner>
    <!-- <ngx-spinner bdColor = "rgba(40,167,69,0.2)" size="medium" color ="#28a745" type="ball-atom" [fullScreen]="true">
     <p style="color: black" > Just a Moment... </p></ngx-spinner> -->
    <router-outlet></router-outlet>
    `
})
export class RootComponent {

}
