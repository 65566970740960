import { Directive, HostListener, HostBinding, Output, EventEmitter } from '@angular/core';

@Directive({
  selector: '[fileDragDrop]'
})
export class FileDragNDropDirective {
  @Output() private filesChangeEmiter: EventEmitter<File[]> = new EventEmitter<File[]>();
 @HostBinding('style.background') private background = '';
  @HostBinding('style.border') private borderStyle = '';
  @HostBinding('style.border-color') private borderColor = '';
  constructor() { 
  }
  

  @HostListener('dragover', ['$event']) public onDragOver(evt: Event): void {
    evt.preventDefault();
    this.updateStyles('lightgray', 'cadetblue', '3px solid');
  }

  @HostListener('dragleave', ['$event']) public onDragLeave(evt: Event): void {
    evt.preventDefault();
    this.updateStyles('#eee', '#696D7D', '2px dashed');
  }

  @HostListener('drop', ['$event']) public onDrop(evt: Event): void {
    evt.preventDefault();
    this.updateStyles('#fff', '#696D7D', 'none');
    const files: File[] = this.getFileListAsArray((evt as DragEvent).dataTransfer?.files);
    this.filesChangeEmiter.emit(files);
  }

  private getFileListAsArray(fileList: FileList | undefined): File[] {
    const filesArray: File[] = [];
    if (fileList) {
      for (let i = 0; i < fileList.length; i++) {
        filesArray.push(fileList[i]);
      }
    }
    return filesArray;
  }

  private updateStyles(background: string, borderColor: string, borderStyle: string): void {
    this.background = background;
    this.borderColor = borderColor;
    this.borderStyle = borderStyle;
  }

}
