import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})
export class SearchComponent implements OnInit {
  @Output() search: EventEmitter<string> = new EventEmitter<string>();
  @Output() clear: EventEmitter<void> = new EventEmitter<void>();
  keyword: string = '';
  @Input() placeholderText: string = 'Search';
  showClearIcon: boolean = false;

  constructor() { }

  ngOnInit() {
  }



  onSearch(): void {
    this.search.emit(this.keyword);
    this.showClearIcon = true;
  }


  clearSearch(): void {
    this.keyword = '';
    this.clear.emit();
    this.showClearIcon = false;
  }

}
