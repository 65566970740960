  <div>
    <span>
      <i class="fa-solid fa-magnifying-glass searchIcon"></i>
    </span>
    <span>
      <input
        type="text"
        class="form-control w-60 searchStyle"
        name="keyword"
        [placeholder]="placeholderText"
        [(ngModel)]="keyword"
        (keyup.enter)="onSearch()"
      />
    </span>
    <span [ngClass]="showClearIcon ? 'd-block' : 'd-none'">
      <i class="fa-solid fa-xmark crossIcon" (click)="clearSearch()"></i>
    </span>
  </div>

