import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
interface ISelectedListItem {
  disabled: boolean,
    group: null,
    selected: boolean,
    text: string,
    value: string
}


@Component({
  selector: 'app-searchable-dropdown',
  templateUrl: './searchable-dropdown.component.html',
  styleUrls: ['./searchable-dropdown.component.css']
})
export class SearchableDropdownComponent implements OnInit {
  @Input() placeHolder:string='Select'
  private _options: ISelectedListItem[] = [];
  isFocus:boolean=false;
  
  @Input()
  set options(value: ISelectedListItem[]) {
    this._options = value;
    this.filteredOptions = value; 
  }

  get options(): ISelectedListItem[] {
    return this._options;
  }
  @Output() optionSelected = new EventEmitter<string>();

  filteredOptions: ISelectedListItem[] = [];
  searchText = '';

  constructor() { }

  ngOnInit(): void {
  }

  openDropdown(isFocus:boolean){
    if(isFocus){
      this.isFocus=isFocus
    }
    else{
      setTimeout(()=>{
        this.isFocus=isFocus;
      },350)
    }
  }

  selectOption(option: ISelectedListItem): void {
    this.optionSelected.emit(option.value);
    this.searchText=option.text;
  }

  filterOptions(): void {
    this.filteredOptions = this.options.filter(option =>
      option.text.toLowerCase().includes(this.searchText.toLowerCase())
    );
  }
}
