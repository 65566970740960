<section>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 px-4 pt-3 hide-on-mobile">
        <img src="assets/img/logo-vector.svg" class="logo" alt="" />
      </div>

      <!-- mobile view -->
      <div class="col-12 py-4 hide-on-web text-center green-background">
        <img
          src="assets/img/light-appselec-logo.png"
          class="logo-light"
          alt=""
        />
      </div>
      <!-- mobile view -->
    </div>
  </div>
</section>

<section>
  <div class="container mt-5">
    <div class="row">
      <div class="col-12 text-center mobile-mt-5">
        <!-- <img src="assets/img/success-vector.svg" class="success-img" alt=""> -->
        <div>
          <svg
            width="240"
            height="240"
            viewBox="0 0 160 161"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g opacity="0.1">
              <path
                opacity="0.01"
                d="M135.831 145.718C136.06 140.213 122.183 135.163 104.836 134.438C87.4882 133.714 73.2391 137.589 73.0095 143.094C72.7799 148.6 86.6567 153.65 104.004 154.375C121.352 155.099 135.601 151.224 135.831 145.718Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.02"
                d="M135.809 145.703C136.037 140.225 122.229 135.2 104.966 134.479C87.704 133.758 73.5249 137.614 73.2965 143.091C73.068 148.569 86.8767 153.594 104.139 154.315C121.401 155.036 135.581 151.18 135.809 145.703Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.03"
                d="M135.78 145.686C136.007 140.235 122.266 135.234 105.089 134.517C87.9121 133.8 73.803 137.637 73.5757 143.087C73.3483 148.538 87.0889 153.539 104.266 154.256C121.443 154.974 135.552 151.137 135.78 145.686Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.04"
                d="M135.756 145.671C135.983 140.247 122.311 135.271 105.219 134.557C88.1277 133.843 74.0889 137.662 73.8627 143.085C73.6365 148.509 87.3085 153.485 104.4 154.199C121.491 154.913 135.53 151.095 135.756 145.671Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.05"
                d="M135.735 145.654C135.96 140.257 122.356 135.306 105.35 134.596C88.3436 133.885 74.3748 137.685 74.1497 143.082C73.9247 148.478 87.5285 153.429 104.535 154.14C121.541 154.85 135.51 151.051 135.735 145.654Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.06"
                d="M135.713 145.638C135.937 140.269 122.402 135.343 105.481 134.636C88.5594 133.929 74.6607 137.709 74.4367 143.078C74.2128 148.448 87.7485 153.374 104.67 154.081C121.591 154.787 135.489 151.007 135.713 145.638Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.07"
                d="M135.69 145.621C135.913 140.279 122.446 135.378 105.61 134.674C88.775 133.971 74.9466 137.732 74.7237 143.075C74.5009 148.417 87.9681 153.318 104.803 154.021C121.639 154.725 135.467 150.964 135.69 145.621Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.08"
                d="M135.669 145.606C135.89 140.291 122.491 135.414 105.741 134.715C88.9909 134.015 75.2325 137.757 75.0108 143.073C74.7891 148.388 88.1881 153.264 104.938 153.964C121.688 154.664 135.447 150.922 135.669 145.606Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.09"
                d="M135.639 145.589C135.86 140.301 122.529 135.449 105.864 134.753C89.199 134.057 75.5106 137.78 75.29 143.069C75.0694 148.357 88.4003 153.209 105.065 153.905C121.73 154.601 135.419 150.878 135.639 145.589Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.1"
                d="M135.618 145.573C135.837 140.312 122.574 135.486 105.995 134.793C89.4148 134.101 75.7964 137.804 75.577 143.066C75.3576 148.327 88.6202 153.153 105.2 153.846C121.78 154.538 135.398 150.835 135.618 145.573Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.11"
                d="M135.594 145.557C135.813 140.322 122.619 135.521 106.124 134.832C89.6304 134.143 76.0823 137.827 75.864 143.062C75.6457 148.296 88.8398 153.098 105.334 153.787C121.828 154.475 135.376 150.791 135.594 145.557Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.12"
                d="M135.573 145.54C135.79 140.333 122.664 135.556 106.255 134.87C89.8463 134.185 76.3682 137.85 76.151 143.058C75.9339 148.265 89.0598 153.042 105.469 153.727C121.878 154.413 135.356 150.747 135.573 145.54Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.13"
                d="M135.551 145.523C135.767 140.343 122.71 135.59 106.386 134.909C90.0622 134.227 76.6541 137.874 76.4381 143.054C76.222 148.234 89.2798 152.986 105.603 153.668C121.927 154.35 135.335 150.703 135.551 145.523Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.14"
                d="M135.522 145.509C135.737 140.356 122.747 135.629 106.509 134.951C90.2702 134.272 76.9321 137.9 76.7172 143.053C76.5023 148.206 89.492 152.933 105.73 153.611C121.969 154.289 135.307 150.662 135.522 145.509Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.15"
                d="M135.499 145.492C135.712 140.366 122.791 135.664 106.639 134.989C90.4858 134.314 77.218 137.923 77.0043 143.049C76.7905 148.175 89.7116 152.877 105.864 153.552C122.017 154.226 135.285 150.618 135.499 145.492Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.16"
                d="M135.477 145.475C135.69 140.376 122.837 135.699 106.769 135.028C90.7017 134.356 77.5039 137.946 77.2913 143.045C77.0786 148.144 89.9316 152.821 105.999 153.493C122.067 154.164 135.264 150.574 135.477 145.475Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.17"
                d="M135.456 145.46C135.667 140.388 122.882 135.736 106.9 135.068C90.9176 134.4 77.7899 137.971 77.5783 143.043C77.3668 148.115 90.1516 152.768 106.134 153.435C122.116 154.103 135.244 150.532 135.456 145.46Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.18"
                d="M135.434 145.443C135.644 140.398 122.928 135.77 107.031 135.106C91.1335 134.442 78.0757 137.993 77.8653 143.038C77.6549 148.082 90.3716 152.71 106.269 153.374C122.166 154.038 135.224 150.487 135.434 145.443Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.19"
                d="M135.403 145.426C135.612 140.408 122.964 135.805 107.153 135.145C91.3412 134.484 78.3538 138.016 78.1445 143.034C77.9353 148.052 90.5834 152.655 106.395 153.315C122.206 153.975 135.194 150.443 135.403 145.426Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.2"
                d="M135.381 145.411C135.59 140.42 123.01 135.842 107.283 135.185C91.5571 134.528 78.6397 138.041 78.4316 143.032C78.2234 148.023 90.8033 152.601 106.53 153.258C122.256 153.915 135.173 150.401 135.381 145.411Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.21"
                d="M135.36 145.395C135.567 140.432 123.055 135.879 107.414 135.225C91.7729 134.572 78.9255 138.066 78.7185 143.029C78.5116 147.992 91.0233 152.545 106.664 153.199C122.305 153.852 135.153 150.358 135.36 145.395Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.22"
                d="M135.338 145.378C135.544 140.442 123.101 135.913 107.545 135.264C91.9888 134.614 79.2114 138.089 79.0056 143.025C78.7997 147.961 91.2433 152.49 106.799 153.139C122.355 153.789 135.132 150.314 135.338 145.378Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.23"
                d="M135.315 145.361C135.52 140.452 123.145 135.948 107.675 135.302C92.2044 134.656 79.4973 138.112 79.2926 143.021C79.0879 147.93 91.4629 152.434 106.933 153.08C122.403 153.726 135.11 150.27 135.315 145.361Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.24"
                d="M135.294 145.346C135.497 140.464 123.19 135.985 107.805 135.342C92.4203 134.7 79.7832 138.137 79.5796 143.019C79.376 147.901 91.6829 152.38 107.068 153.023C122.453 153.666 135.09 150.229 135.294 145.346Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.25"
                d="M135.264 145.328C135.467 140.473 123.228 136.02 107.928 135.381C92.6283 134.742 80.0613 138.159 79.8588 143.014C79.6563 147.869 91.8951 152.323 107.195 152.962C122.495 153.601 135.062 150.183 135.264 145.328Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.26"
                d="M135.135 144.821C135.328 139.989 123.14 135.578 107.912 134.968C92.6846 134.359 80.1835 137.782 79.9903 142.614C79.7972 147.446 91.9852 151.857 107.213 152.467C122.441 153.077 134.942 149.654 135.135 144.821Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.27"
                d="M135.219 145.297C135.42 140.496 123.318 136.091 108.189 135.459C93.0598 134.827 80.6331 138.207 80.4329 143.008C80.2326 147.809 92.3347 152.213 107.464 152.845C122.592 153.477 135.019 150.098 135.219 145.297Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.28"
                d="M135.198 145.28C135.397 140.506 123.363 136.126 108.319 135.498C93.2757 134.869 80.919 138.23 80.7199 143.004C80.5208 147.778 92.5547 152.158 107.598 152.786C122.642 153.415 134.999 150.054 135.198 145.28Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.29"
                d="M135.176 145.264C135.374 140.517 123.408 136.163 108.45 135.538C93.4915 134.913 81.2048 138.255 81.0069 143.001C80.8089 147.748 92.7747 152.102 107.733 152.727C122.692 153.352 134.978 150.01 135.176 145.264Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.3"
                d="M135.147 145.249C135.344 140.529 123.446 136.2 108.573 135.578C93.6997 134.957 81.4829 138.28 81.2861 142.999C81.0893 147.719 92.9869 152.049 107.86 152.67C122.733 153.291 134.95 149.969 135.147 145.249Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.31"
                d="M135.124 145.232C135.319 140.539 123.49 136.234 108.703 135.617C93.9152 134.999 81.7688 138.303 81.5731 142.995C81.3774 147.688 93.2064 151.993 107.994 152.611C122.782 153.228 134.928 149.925 135.124 145.232Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.32"
                d="M135.102 145.215C135.297 140.55 123.536 136.269 108.833 135.655C94.1311 135.041 82.0547 138.326 81.8601 142.991C81.6656 147.657 93.4264 151.937 108.129 152.551C122.831 153.166 134.908 149.881 135.102 145.215Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.33"
                d="M135.081 145.199C135.274 140.561 123.581 136.306 108.964 135.695C94.3469 135.085 82.3406 138.35 82.1471 142.988C81.9537 147.627 93.6464 151.882 108.264 152.492C122.881 153.103 134.887 149.838 135.081 145.199Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.34"
                d="M135.059 145.183C135.251 140.571 123.627 136.341 109.095 135.734C94.5629 135.127 82.6265 138.373 82.4342 142.984C82.2418 147.596 93.8664 151.826 108.398 152.433C122.93 153.04 134.867 149.794 135.059 145.183Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.35"
                d="M135.036 145.168C135.227 140.583 123.671 136.378 109.225 135.774C94.7784 135.171 82.9124 138.398 82.7212 142.982C82.53 147.567 94.086 151.772 108.532 152.376C122.979 152.979 134.845 149.752 135.036 145.168Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.36"
                d="M135.006 145.151C135.197 140.593 123.709 136.412 109.348 135.813C94.9865 135.213 83.1905 138.421 83.0004 142.978C82.8103 147.536 94.2982 151.717 108.659 152.317C123.02 152.916 134.816 149.708 135.006 145.151Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.37"
                d="M134.985 145.135C135.174 140.605 123.754 136.449 109.478 135.853C95.2023 135.256 83.4763 138.445 83.2874 142.975C83.0985 147.505 94.5182 151.661 108.794 152.257C123.07 152.854 134.796 149.665 134.985 145.135Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.38"
                d="M134.963 145.118C135.151 140.615 123.8 136.484 109.609 135.891C95.4183 135.298 83.7622 138.468 83.5744 142.971C83.3866 147.475 94.7382 151.606 108.929 152.198C123.12 152.791 134.776 149.621 134.963 145.118Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.39"
                d="M134.94 145.101C135.127 140.625 123.844 136.519 109.739 135.93C95.6338 135.34 84.0481 138.491 83.8614 142.968C83.6748 147.444 94.9578 151.55 109.063 152.139C123.168 152.728 134.753 149.577 134.94 145.101Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.4"
                d="M134.919 145.086C135.104 140.637 123.889 136.556 109.869 135.97C95.8497 135.384 84.334 138.516 84.1485 142.966C83.9629 147.415 95.1778 151.496 109.198 152.082C123.217 152.667 134.733 149.535 134.919 145.086Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.41"
                d="M134.889 145.068C135.074 140.647 123.927 136.59 109.992 136.008C96.0578 135.426 84.6121 138.539 84.4277 142.961C84.2432 147.382 95.39 151.439 109.325 152.021C123.259 152.603 134.705 149.49 134.889 145.068Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.42"
                d="M134.868 145.053C135.051 140.659 123.972 136.627 110.123 136.049C96.2737 135.47 84.898 138.564 84.7147 142.959C84.5314 147.353 95.6099 151.385 109.459 151.963C123.309 152.542 134.684 149.448 134.868 145.053Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.43"
                d="M134.844 145.037C135.027 140.669 124.017 136.662 110.253 136.087C96.4892 135.512 85.1839 138.587 85.0017 142.955C84.8195 147.322 95.8295 151.329 109.593 151.904C123.357 152.479 134.662 149.404 134.844 145.037Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.44"
                d="M134.823 145.02C135.004 140.679 124.062 136.697 110.384 136.125C96.7051 135.554 85.4698 138.61 85.2887 142.951C85.1077 147.292 96.0495 151.274 109.728 151.845C123.406 152.416 134.642 149.361 134.823 145.02Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.45"
                d="M134.801 145.004C134.981 140.69 124.107 136.733 110.514 136.166C96.921 135.598 85.7556 138.634 85.5757 142.948C85.3958 147.261 96.2695 151.218 109.863 151.786C123.456 152.354 134.621 149.317 134.801 145.004Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.46"
                d="M134.778 144.987C134.957 140.7 124.152 136.768 110.644 136.204C97.1365 135.64 86.0415 138.657 85.8628 142.944C85.684 147.23 96.4891 151.162 109.997 151.727C123.504 152.291 134.599 149.273 134.778 144.987Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.47"
                d="M134.637 144.484C134.808 140.221 124.055 136.329 110.621 135.791C97.1872 135.253 86.1586 138.273 85.9882 142.537C85.8178 146.8 96.5701 150.692 110.004 151.23C123.438 151.767 134.467 148.747 134.637 144.484Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.48"
                d="M134.727 144.955C134.904 140.723 124.235 136.84 110.898 136.283C97.5605 135.726 86.6055 138.705 86.429 142.938C86.2525 147.17 96.9213 151.053 110.258 151.61C123.596 152.167 134.551 149.188 134.727 144.955Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.49"
                d="M134.706 144.939C134.881 140.734 124.28 136.877 111.028 136.323C97.7764 135.77 86.8914 138.73 86.716 142.935C86.5406 147.14 97.1413 150.998 110.393 151.551C123.645 152.105 134.53 149.144 134.706 144.939Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.5"
                d="M134.682 144.922C134.857 140.744 124.324 136.911 111.158 136.361C97.9919 135.811 87.1773 138.753 87.003 142.931C86.8288 147.109 97.3609 150.942 110.527 151.492C123.693 152.042 134.508 149.101 134.682 144.922Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.5"
                d="M134.541 144.42C134.707 140.265 124.228 136.472 111.135 135.948C98.043 135.424 87.2949 138.367 87.1288 142.522C86.9627 146.677 97.4417 150.47 110.534 150.994C123.627 151.518 134.375 148.575 134.541 144.42Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.51"
                d="M134.607 144.855C134.778 140.731 124.382 136.949 111.386 136.409C98.39 135.868 87.7158 138.773 87.5445 142.898C87.3732 147.022 97.7697 150.803 110.766 151.344C123.762 151.885 134.436 148.979 134.607 144.855Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.52"
                d="M134.49 144.388C134.653 140.288 124.311 136.545 111.389 136.027C98.4672 135.51 87.8591 138.415 87.6952 142.515C87.5313 146.615 97.8737 150.359 110.796 150.876C123.718 151.393 134.326 148.489 134.49 144.388Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.53"
                d="M134.562 144.822C134.731 140.753 124.471 137.021 111.646 136.487C98.8214 135.954 88.2876 138.821 88.1185 142.891C87.9495 146.96 98.2093 150.692 111.034 151.226C123.859 151.759 134.393 148.892 134.562 144.822Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.54"
                d="M134.533 144.808C134.701 140.765 124.509 137.058 111.769 136.528C99.0295 135.998 88.5657 138.846 88.3978 142.889C88.2299 146.932 98.4215 150.639 111.161 151.168C123.901 151.698 134.365 148.851 134.533 144.808Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.55"
                d="M134.544 144.826C134.711 140.81 124.588 137.126 111.934 136.598C99.2791 136.069 88.8848 138.896 88.7173 142.912C88.5498 146.928 98.6726 150.612 111.327 151.141C123.982 151.669 134.376 148.842 134.544 144.826Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.56"
                d="M134.49 144.775C134.655 140.786 124.6 137.129 112.031 136.606C99.4613 136.084 89.1375 138.893 88.9718 142.882C88.8062 146.87 98.8614 150.527 111.431 151.05C124 151.573 134.324 148.763 134.49 144.775Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.57"
                d="M134.467 144.758C134.631 140.797 124.644 137.164 112.161 136.645C99.6768 136.126 89.4234 138.916 89.2588 142.878C89.0943 146.839 99.081 150.472 111.565 150.991C124.049 151.51 134.302 148.72 134.467 144.758Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.58"
                d="M134.445 144.741C134.608 140.807 124.69 137.199 112.291 136.683C99.8928 136.168 89.7093 138.939 89.5459 142.874C89.3825 146.808 99.301 150.416 111.7 150.932C124.098 151.448 134.282 148.676 134.445 144.741Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.59"
                d="M134.416 144.727C134.578 140.819 124.728 137.236 112.414 136.724C100.101 136.212 89.9874 138.964 89.8251 142.872C89.6628 146.779 99.5132 150.362 111.827 150.875C124.14 151.387 134.253 148.634 134.416 144.727Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.6"
                d="M134.394 144.709C134.555 140.828 124.773 137.271 112.545 136.762C100.317 136.253 90.2732 138.987 90.1121 142.867C89.9509 146.747 99.7332 150.305 111.961 150.814C124.189 151.322 134.233 148.589 134.394 144.709Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.61"
                d="M134.273 144.244C134.427 140.387 124.7 136.866 112.547 136.38C100.394 135.893 90.4176 138.625 90.2635 142.482C90.1093 146.338 99.8363 149.859 111.989 150.346C124.142 150.832 134.119 148.1 134.273 144.244Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.62"
                d="M134.349 144.677C134.508 140.851 124.863 137.342 112.805 136.841C100.748 136.339 90.8451 139.035 90.6862 142.861C90.5273 146.687 100.173 150.196 112.23 150.697C124.287 151.199 134.19 148.503 134.349 144.677Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.63"
                d="M134.328 144.66C134.486 140.861 124.908 137.377 112.936 136.879C100.964 136.381 91.131 139.058 90.9732 142.857C90.8154 146.656 100.393 150.14 112.365 150.638C124.337 151.136 134.17 148.46 134.328 144.66Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.64"
                d="M134.298 144.644C134.455 140.872 124.946 137.414 113.059 136.919C101.172 136.425 91.409 139.082 91.2524 142.854C91.0957 146.626 100.605 150.084 112.492 150.579C124.379 151.073 134.142 148.416 134.298 144.644Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.65"
                d="M134.275 144.628C134.431 140.883 124.99 137.449 113.189 136.958C101.388 136.467 91.695 139.105 91.5394 142.85C91.3839 146.595 100.825 150.029 112.626 150.52C124.427 151.011 134.12 148.373 134.275 144.628Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.66"
                d="M134.254 144.613C134.408 140.895 125.036 137.486 113.32 136.998C101.604 136.511 91.9809 139.13 91.8265 142.848C91.6721 146.566 101.045 149.975 112.76 150.462C124.476 150.95 134.099 148.331 134.254 144.613Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.67"
                d="M134.232 144.596C134.385 140.905 125.081 137.521 113.45 137.037C101.82 136.553 92.2668 139.153 92.1135 142.844C91.9602 146.535 101.265 149.92 112.895 150.403C124.526 150.887 134.079 148.287 134.232 144.596Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.68"
                d="M134.211 144.58C134.363 140.916 125.127 137.557 113.581 137.077C102.036 136.597 92.5527 139.177 92.4005 142.841C92.2483 146.505 101.485 149.864 113.03 150.344C124.576 150.824 134.058 148.244 134.211 144.58Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.69"
                d="M134.187 144.563C134.338 140.927 125.171 137.592 113.711 137.115C102.251 136.639 92.8386 139.2 92.6875 142.837C92.5365 146.474 101.704 149.808 113.164 150.285C124.624 150.762 134.036 148.2 134.187 144.563Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.7"
                d="M134.158 144.546C134.308 140.937 125.208 137.627 113.834 137.154C102.459 136.681 93.1167 139.223 92.9668 142.833C92.8168 146.443 101.916 149.753 113.291 150.226C124.666 150.699 134.008 148.156 134.158 144.546Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.71"
                d="M134.136 144.531C134.285 140.948 125.254 137.664 113.964 137.194C102.675 136.724 93.4025 139.248 93.2538 142.83C93.105 146.412 102.136 149.697 113.426 150.167C124.715 150.636 133.988 148.113 134.136 144.531Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.72"
                d="M134.115 144.514C134.263 140.959 125.299 137.699 114.095 137.233C102.891 136.766 93.6884 139.271 93.5408 142.826C93.3931 146.382 102.356 149.641 113.561 150.108C124.765 150.574 133.967 148.069 134.115 144.514Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.73"
                d="M134.092 144.499C134.238 140.971 125.344 137.735 114.225 137.273C103.107 136.81 93.9744 139.296 93.8278 142.824C93.6813 146.353 102.576 149.588 113.694 150.05C124.813 150.513 133.945 148.027 134.092 144.499Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.74"
                d="M134.07 144.482C134.216 140.981 125.389 137.77 114.356 137.311C103.322 136.852 94.2603 139.319 94.1149 142.82C93.9694 146.322 102.796 149.532 113.829 149.991C124.863 150.45 133.925 147.984 134.07 144.482Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.75"
                d="M134.041 144.466C134.185 140.992 125.427 137.807 114.479 137.352C103.531 136.896 94.5383 139.343 94.394 142.817C94.2498 146.291 103.008 149.477 113.956 149.932C124.904 150.387 133.896 147.94 134.041 144.466Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.76"
                d="M134.019 144.45C134.162 141.003 125.472 137.842 114.609 137.39C103.746 136.938 94.8242 139.366 94.6811 142.813C94.5379 146.26 103.228 149.421 114.091 149.873C124.954 150.325 133.876 147.897 134.019 144.45Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.77"
                d="M133.996 144.435C134.138 141.015 125.516 137.879 114.739 137.43C103.962 136.982 95.1102 139.391 94.9681 142.811C94.8261 146.231 103.448 149.367 114.225 149.816C125.002 150.264 133.854 147.855 133.996 144.435Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.78"
                d="M133.974 144.418C134.115 141.025 125.562 137.914 114.87 137.469C104.178 137.024 95.3961 139.414 95.2552 142.807C95.1142 146.201 103.668 149.312 114.36 149.756C125.052 150.201 133.834 147.811 133.974 144.418Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.79"
                d="M133.953 144.402C134.093 141.036 125.607 137.95 115.001 137.509C104.394 137.068 95.6819 139.439 95.5422 142.804C95.4024 146.17 103.888 149.256 114.494 149.697C125.101 150.138 133.813 147.768 133.953 144.402Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.8"
                d="M133.924 144.385C134.062 141.047 125.645 137.985 115.124 137.548C104.602 137.11 95.96 139.462 95.8214 142.8C95.6827 146.139 104.1 149.2 114.621 149.638C125.143 150.076 133.785 147.724 133.924 144.385Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.81"
                d="M133.9 144.368C134.038 141.057 125.689 138.02 115.253 137.586C104.817 137.152 96.246 139.485 96.1084 142.796C95.9709 146.108 104.319 149.145 114.755 149.579C125.191 150.013 133.763 147.68 133.9 144.368Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.82"
                d="M133.879 144.352C134.015 141.067 125.735 138.055 115.384 137.624C105.033 137.194 96.5319 139.508 96.3954 142.793C96.259 146.077 104.539 149.089 114.89 149.52C125.241 149.95 133.742 147.636 133.879 144.352Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.83"
                d="M133.857 144.336C133.993 141.078 125.78 138.092 115.515 137.665C105.249 137.238 96.8177 139.532 96.6824 142.789C96.5472 146.047 104.759 149.034 115.025 149.461C125.29 149.888 133.722 147.593 133.857 144.336Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.84"
                d="M133.834 144.321C133.968 141.09 125.824 138.128 115.645 137.705C105.465 137.282 97.1036 139.557 96.9695 142.788C96.8353 146.018 104.979 148.98 115.159 149.403C125.339 149.827 133.7 147.551 133.834 144.321Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.85"
                d="M133.812 144.304C133.945 141.101 125.87 138.163 115.775 137.743C105.681 137.324 97.3896 139.58 97.2565 142.784C97.1235 145.987 105.199 148.924 115.294 149.344C125.388 149.764 133.679 147.508 133.812 144.304Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.86"
                d="M133.783 144.287C133.915 141.111 125.908 138.198 115.898 137.782C105.889 137.366 97.6677 139.603 97.5357 142.78C97.4038 145.956 105.411 148.869 115.421 149.285C125.43 149.701 133.651 147.464 133.783 144.287Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.87"
                d="M133.762 144.271C133.892 141.122 125.953 138.235 116.029 137.822C106.105 137.409 97.9535 139.628 97.8227 142.777C97.692 145.926 105.631 148.813 115.555 149.226C125.48 149.639 133.631 147.421 133.762 144.271Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.88"
                d="M133.635 143.809C133.76 140.684 125.879 137.831 116.032 137.437C106.185 137.043 98.101 139.256 97.9761 142.381C97.8512 145.506 105.733 148.359 115.58 148.753C125.427 149.147 133.511 146.933 133.635 143.809Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.89"
                d="M133.717 144.24C133.845 141.145 126.043 138.307 116.29 137.901C106.536 137.495 98.5254 139.676 98.3968 142.771C98.2683 145.866 106.071 148.704 115.824 149.11C125.577 149.515 133.588 147.335 133.717 144.24Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.9"
                d="M133.695 144.223C133.823 141.155 126.088 138.342 116.42 137.939C106.752 137.537 98.8113 139.699 98.6838 142.767C98.5564 145.835 106.291 148.648 115.959 149.05C125.627 149.453 133.568 147.291 133.695 144.223Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.91"
                d="M133.666 144.207C133.792 141.166 126.126 138.378 116.543 137.98C106.96 137.581 99.0893 139.723 98.963 142.764C98.8367 145.805 106.503 148.593 116.086 148.991C125.669 149.39 133.54 147.248 133.666 144.207Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.92"
                d="M133.643 144.19C133.768 141.177 126.17 138.413 116.673 138.018C107.176 137.623 99.3752 139.746 99.2501 142.76C99.1249 145.774 106.722 148.537 116.22 148.932C125.717 149.327 133.517 147.204 133.643 144.19Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.93"
                d="M133.621 144.174C133.745 141.187 126.216 138.448 116.804 138.056C107.392 137.665 99.6612 139.769 99.5371 142.756C99.4131 145.743 106.942 148.481 116.355 148.873C125.767 149.264 133.497 147.16 133.621 144.174Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.94"
                d="M133.6 144.159C133.723 141.199 126.261 138.485 116.934 138.097C107.608 137.709 99.9471 139.794 99.8241 142.754C99.7012 145.714 107.162 148.428 116.489 148.816C125.816 149.204 133.477 147.119 133.6 144.159Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.95"
                d="M133.578 144.143C133.7 141.21 126.307 138.522 117.065 138.137C107.823 137.753 100.233 139.818 100.111 142.751C99.9894 145.683 107.382 148.372 116.624 148.757C125.866 149.141 133.456 147.075 133.578 144.143Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.96"
                d="M133.555 144.126C133.675 141.221 126.351 138.556 117.195 138.176C108.039 137.795 100.519 139.841 100.398 142.747C100.278 145.652 107.602 148.317 116.758 148.697C125.914 149.078 133.434 147.032 133.555 144.126Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.97"
                d="M133.525 144.109C133.645 141.231 126.389 138.591 117.318 138.214C108.247 137.837 100.797 139.864 100.677 142.743C100.558 145.622 107.814 148.261 116.885 148.638C125.956 149.016 133.406 146.988 133.525 144.109Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.98"
                d="M133.504 144.094C133.622 141.243 126.434 138.628 117.449 138.254C108.463 137.881 101.083 139.889 100.964 142.741C100.846 145.593 108.034 148.207 117.02 148.581C126.005 148.955 133.385 146.946 133.504 144.094Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.99"
                d="M133.482 144.079C133.6 141.254 126.48 138.665 117.579 138.295C108.679 137.924 101.369 139.914 101.251 142.738C101.134 145.562 108.254 148.152 117.155 148.522C126.055 148.892 133.365 146.903 133.482 144.079Z"
                fill="#0F0A26"
              />
              <path
                d="M133.459 144.062C133.575 141.265 126.524 138.7 117.709 138.333C108.894 137.966 101.655 139.937 101.538 142.734C101.422 145.531 108.474 148.096 117.288 148.463C126.103 148.829 133.343 146.859 133.459 144.062Z"
                fill="#0F0A26"
              />
            </g>
            <g opacity="0.1">
              <path
                opacity="0.01"
                d="M73.2134 150.541C73.443 145.036 59.5661 139.985 42.2186 139.261C24.871 138.536 10.6219 142.411 10.3923 147.917C10.1627 153.422 24.0396 158.472 41.3871 159.197C58.7347 159.921 72.9838 156.046 73.2134 150.541Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.02"
                d="M73.1919 150.525C73.4203 145.047 59.6116 140.022 42.3492 139.301C25.0868 138.58 10.9077 142.436 10.6793 147.914C10.4508 153.391 24.2595 158.417 41.5219 159.138C58.7843 159.859 72.9634 156.003 73.1919 150.525Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.03"
                d="M73.1703 150.508C73.3977 145.057 59.6571 140.057 42.4799 139.339C25.3028 138.622 11.1936 142.459 10.9663 147.91C10.739 153.361 24.4795 158.361 41.6567 159.078C58.8339 159.796 72.943 155.959 73.1703 150.508Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.04"
                d="M73.147 150.493C73.3733 145.069 59.7012 140.094 42.6098 139.38C25.5183 138.666 11.4795 142.484 11.2533 147.908C11.0271 153.332 24.6991 158.307 41.7906 159.021C58.8821 159.735 72.9208 155.917 73.147 150.493Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.05"
                d="M73.1255 150.476C73.3506 145.079 59.7468 140.128 42.7405 139.418C25.7342 138.708 11.7654 142.507 11.5404 147.904C11.3153 153.301 24.9191 158.252 41.9254 158.962C58.9317 159.672 72.9004 155.873 73.1255 150.476Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.06"
                d="M73.0962 150.46C73.3201 145.091 59.7844 140.165 42.8633 139.458C25.9422 138.751 12.0435 142.531 11.8195 147.901C11.5956 153.27 25.1313 158.196 42.0524 158.903C58.9734 159.61 72.8722 155.83 73.0962 150.46Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.07"
                d="M73.086 149.995C73.2998 144.648 59.8134 139.766 42.9633 139.092C26.1133 138.417 12.2804 142.205 12.0667 147.552C11.8529 152.9 25.3393 157.781 42.1894 158.456C59.0394 159.13 72.8723 155.342 73.086 149.995Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.08"
                d="M73.0513 150.429C73.273 145.113 59.874 140.237 43.1239 139.537C26.3737 138.837 12.6153 142.579 12.3936 147.895C12.1719 153.21 25.5709 158.087 42.3211 158.786C59.0712 159.486 72.8296 155.744 73.0513 150.429Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.09"
                d="M73.0298 150.412C73.2504 145.123 59.9195 140.272 43.2546 139.575C26.5896 138.879 12.9012 142.602 12.6806 147.891C12.4601 153.18 25.7909 158.031 42.4558 158.727C59.1208 159.423 72.8093 155.7 73.0298 150.412Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.1"
                d="M73.0131 149.947C73.2235 144.682 59.9419 139.874 43.3477 139.21C26.7535 138.546 13.1306 142.276 12.9201 147.542C12.7097 152.807 25.9913 157.615 42.5855 158.279C59.1797 158.943 72.8026 155.213 73.0131 149.947Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.11"
                d="M72.985 150.379C73.2033 145.145 60.0091 140.343 43.5151 139.654C27.021 138.965 13.4729 142.65 13.2546 147.884C13.0363 153.118 26.2305 157.92 42.7245 158.609C59.2186 159.298 72.7667 155.613 72.985 150.379Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.12"
                d="M72.9556 150.362C73.1728 145.155 60.0468 140.378 43.638 139.692C27.2291 139.007 13.751 142.673 13.5338 147.88C13.3167 153.087 26.4426 157.864 42.8515 158.55C59.2604 159.235 72.7385 155.569 72.9556 150.362Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.13"
                d="M72.9341 150.345C73.1502 145.165 60.0924 140.413 43.7687 139.731C27.445 139.049 14.0369 142.696 13.8209 147.876C13.6048 153.056 26.6626 157.809 42.9863 158.49C59.31 159.172 72.7181 155.526 72.9341 150.345Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.14"
                d="M72.9126 150.329C73.1275 145.176 60.1378 140.449 43.8993 139.771C27.6609 139.093 14.3228 142.72 14.1079 147.873C13.893 153.026 26.8826 157.753 43.1211 158.431C59.3596 159.11 72.6977 155.482 72.9126 150.329Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.15"
                d="M72.8893 150.314C73.103 145.188 60.1819 140.486 44.0292 139.811C27.8764 139.137 14.6087 142.745 14.3949 147.871C14.1811 152.997 27.1022 157.699 43.255 158.374C59.4078 159.049 72.6755 155.44 72.8893 150.314Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.16"
                d="M72.8678 150.298C73.0804 145.199 60.2275 140.521 44.1599 139.85C28.0923 139.179 14.8946 142.768 14.6819 147.867C14.4693 152.966 27.3222 157.644 43.3898 158.315C59.4574 158.986 72.6551 155.397 72.8678 150.298Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.17"
                d="M72.8384 150.281C73.05 145.209 60.2652 140.556 44.2828 139.888C28.3004 139.221 15.1727 142.791 14.9611 147.863C14.7496 152.935 27.5344 157.588 43.5168 158.256C59.4991 158.923 72.6269 155.353 72.8384 150.281Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.18"
                d="M72.8169 150.265C73.0272 145.22 60.3106 140.592 44.4134 139.928C28.5163 139.264 15.4585 142.816 15.2481 147.86C15.0377 152.905 27.7544 157.532 43.6516 158.196C59.5487 158.86 72.6065 155.309 72.8169 150.265Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.19"
                d="M72.7936 150.248C73.0028 145.23 60.3547 140.627 44.5433 139.967C28.7318 139.306 15.7444 142.839 15.5352 147.856C15.3259 152.874 27.974 157.477 43.7855 158.137C59.5969 158.798 72.5843 155.265 72.7936 150.248Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.2"
                d="M72.772 150.233C72.9802 145.242 60.4003 140.664 44.674 140.007C28.9477 139.35 16.0303 142.864 15.8222 147.854C15.614 152.845 28.194 157.423 43.9202 158.08C59.6465 158.737 72.5639 155.224 72.772 150.233Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.21"
                d="M72.7505 150.217C72.9575 145.254 60.4457 140.701 44.8046 140.047C29.1636 139.394 16.3162 142.888 16.1092 147.851C15.9022 152.814 28.414 157.368 44.055 158.021C59.6961 158.674 72.5435 155.18 72.7505 150.217Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.22"
                d="M72.7237 149.755C72.9212 144.814 60.4598 140.304 44.8904 139.681C29.321 139.057 16.5394 142.557 16.3419 147.498C16.1445 152.438 28.6059 156.949 44.1753 157.572C59.7447 158.195 72.5263 154.695 72.7237 149.755Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.23"
                d="M72.6979 150.185C72.9026 145.276 60.5275 140.772 45.0574 140.126C29.5872 139.48 16.8802 142.936 16.6754 147.845C16.4707 152.755 28.8457 157.258 44.3159 157.904C59.7861 158.551 72.4931 155.095 72.6979 150.185Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.24"
                d="M72.6763 150.169C72.88 145.286 60.573 140.807 45.1881 140.165C29.8031 139.522 17.1661 142.959 16.9624 147.841C16.7588 152.724 29.0657 157.203 44.4507 157.845C59.8357 158.488 72.4727 155.051 72.6763 150.169Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.25"
                d="M72.6548 150.153C72.8573 145.298 60.6185 140.844 45.3187 140.205C30.019 139.566 17.4519 142.983 17.2494 147.838C17.047 152.693 29.2857 157.147 44.5855 157.786C59.8853 158.425 72.4523 155.007 72.6548 150.153Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.26"
                d="M72.6333 150.136C72.8346 145.308 60.664 140.879 45.4494 140.243C30.2349 139.608 17.7378 143.006 17.5365 147.834C17.3351 152.662 29.5057 157.091 44.7203 157.727C59.9348 158.362 72.4319 154.964 72.6333 150.136Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.27"
                d="M72.61 150.119C72.8102 145.318 60.7081 140.914 45.5793 140.282C30.4504 139.65 18.0237 143.029 17.8235 147.83C17.6233 152.631 29.7253 157.036 44.8542 157.668C59.9831 158.3 72.4097 154.92 72.61 150.119Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.28"
                d="M72.5807 150.104C72.7798 145.33 60.7459 140.95 45.7022 140.322C30.6585 139.694 18.3018 143.054 18.1027 147.828C17.9036 152.603 29.9375 156.982 44.9812 157.61C60.0248 158.239 72.3816 154.878 72.5807 150.104Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.29"
                d="M72.5591 150.086C72.757 145.339 60.7913 140.985 45.8328 140.36C30.8744 139.735 18.5876 143.077 18.3897 147.823C18.1917 152.57 30.1575 156.924 45.116 157.549C60.0744 158.174 72.3611 154.833 72.5591 150.086Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.3"
                d="M72.5376 150.071C72.7344 145.352 60.8368 141.022 45.9635 140.401C31.0903 139.779 18.8735 143.102 18.6767 147.821C18.4799 152.541 30.3775 156.871 45.2507 157.492C60.124 158.113 72.3407 154.791 72.5376 150.071Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.31"
                d="M72.5143 150.054C72.71 145.362 60.881 141.057 46.0934 140.439C31.3058 139.821 19.1595 143.125 18.9637 147.818C18.768 152.51 30.5971 156.815 45.3846 157.433C60.1722 158.051 72.3186 154.747 72.5143 150.054Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.1"
                d="M72.4927 150.038C72.6873 145.372 60.9265 141.092 46.2241 140.477C31.5217 139.863 19.4454 143.148 19.2508 147.814C19.0562 152.479 30.8171 156.76 45.5194 157.374C60.2218 157.988 72.2982 154.703 72.4927 150.038Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.33"
                d="M72.4634 150.022C72.6568 145.383 60.9641 141.128 46.3469 140.518C31.7298 139.907 19.7234 143.172 19.5299 147.811C19.3365 152.449 31.0292 156.704 45.6464 157.314C60.2636 157.925 72.2699 154.66 72.4634 150.022Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.34"
                d="M72.4419 150.005C72.6342 145.393 61.0096 141.163 46.4776 140.556C31.9457 139.949 20.0093 143.195 19.817 147.807C19.6247 152.418 31.2492 156.648 45.7812 157.255C60.3132 157.862 72.2496 154.616 72.4419 150.005Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.35"
                d="M72.4186 149.99C72.6098 145.405 61.0538 141.2 46.6075 140.596C32.1612 139.993 20.2952 143.22 20.104 147.805C19.9128 152.389 31.4688 156.595 45.9151 157.198C60.3614 157.801 72.2274 154.574 72.4186 149.99Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.36"
                d="M72.3971 149.973C72.5871 145.416 61.0993 141.235 46.7382 140.635C32.3771 140.035 20.5811 143.243 20.391 147.801C20.201 152.358 31.6888 156.539 46.0499 157.139C60.411 157.739 72.207 154.53 72.3971 149.973Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.37"
                d="M72.3755 149.957C72.5644 145.427 61.1447 141.271 46.8688 140.675C32.593 140.079 20.8669 143.268 20.678 147.798C20.4891 152.328 31.9088 156.483 46.1847 157.08C60.4606 157.676 72.1866 154.487 72.3755 149.957Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.38"
                d="M72.3458 149.498C72.5259 144.991 61.1581 140.876 46.9551 140.308C32.7521 139.739 21.0923 142.932 20.9121 147.439C20.732 151.946 32.0998 156.061 46.3028 156.629C60.5058 157.198 72.1657 154.005 72.3458 149.498Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.39"
                d="M72.3229 149.925C72.5096 145.449 61.2266 141.343 47.1216 140.754C33.0166 140.165 21.4309 143.316 21.2443 147.792C21.0576 152.268 32.3406 156.374 46.4456 156.963C60.5505 157.552 72.1362 154.401 72.3229 149.925Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.4"
                d="M72.3014 149.908C72.4869 145.459 61.2721 141.378 47.2523 140.792C33.2325 140.207 21.7168 143.339 21.5313 147.788C21.3457 152.237 32.5606 156.318 46.5804 156.904C60.6001 157.49 72.1158 154.358 72.3014 149.908Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.41"
                d="M72.2798 149.893C72.4642 145.471 61.3175 141.415 47.3829 140.832C33.4484 140.25 22.0027 143.363 21.8183 147.785C21.6339 152.207 32.7806 156.263 46.7151 156.845C60.6497 157.427 72.0954 154.314 72.2798 149.893Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.42"
                d="M72.2583 149.876C72.4416 145.481 61.363 141.449 47.5137 140.871C33.6643 140.292 22.2886 143.386 22.1053 147.781C21.922 152.176 33.0006 156.207 46.8499 156.786C60.6993 157.364 72.075 154.271 72.2583 149.876Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.43"
                d="M72.235 149.859C72.4172 145.491 61.4072 141.484 47.6435 140.909C33.8798 140.334 22.5745 143.409 22.3923 147.777C22.2102 152.145 33.2202 156.152 46.9838 156.727C60.7475 157.301 72.0528 154.227 72.235 149.859Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.44"
                d="M72.2057 149.842C72.3867 145.501 61.4449 141.519 47.7664 140.948C34.0879 140.376 22.8526 143.432 22.6715 147.773C22.4905 152.114 33.4323 156.096 47.1108 156.667C60.7893 157.239 72.0246 154.183 72.2057 149.842Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.45"
                d="M72.1841 149.828C72.364 145.515 61.4903 141.558 47.897 140.99C34.3038 140.422 23.1384 143.458 22.9585 147.772C22.7786 152.085 33.6523 156.042 47.2456 156.61C60.8389 157.178 72.0042 154.141 72.1841 149.828Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.46"
                d="M72.1626 149.811C72.3414 145.525 61.5358 141.593 48.0278 141.028C34.5197 140.464 23.4243 143.482 23.2456 147.768C23.0668 152.054 33.8723 155.987 47.3804 156.551C60.8884 157.115 71.9838 154.098 72.1626 149.811Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.47"
                d="M72.1393 149.794C72.3169 145.535 61.58 141.627 48.1576 141.067C34.7352 140.506 23.7102 143.505 23.5326 147.764C23.3549 152.024 34.0919 155.931 47.5143 156.492C60.9367 157.052 71.9616 154.054 72.1393 149.794Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.48"
                d="M72.1178 149.779C72.2943 145.547 61.6255 141.664 48.2883 141.107C34.9511 140.55 23.9961 143.53 23.8196 147.762C23.6431 151.995 34.3119 155.877 47.6491 156.434C60.9862 156.992 71.9413 154.012 72.1178 149.779Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.49"
                d="M72.0962 149.762C72.2716 145.556 61.6709 141.699 48.419 141.145C35.167 140.592 24.282 143.552 24.1066 147.757C23.9312 151.962 34.5319 155.82 47.7839 156.373C61.0358 156.927 71.9208 153.967 72.0962 149.762Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.5"
                d="M72.0651 149.745C72.2394 145.567 61.7072 141.734 48.541 141.184C35.3747 140.634 24.5601 143.575 24.3858 147.753C24.2116 151.931 34.7437 155.764 47.91 156.314C61.0762 156.864 71.8909 153.923 72.0651 149.745Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.5"
                d="M72.0436 149.73C72.2167 145.579 61.7528 141.77 48.6717 141.224C35.5906 140.678 24.846 143.6 24.6729 147.751C24.4997 151.902 34.9637 155.711 48.0447 156.257C61.1258 156.803 71.8705 153.881 72.0436 149.73Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.51"
                d="M72.0221 149.713C72.1941 145.589 61.7983 141.805 48.8024 141.262C35.8066 140.72 25.1319 143.623 24.9599 147.747C24.7879 151.871 35.1837 155.655 48.1795 156.198C61.1754 156.741 71.8501 153.837 72.0221 149.713Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.52"
                d="M72.0005 149.697C72.1714 145.6 61.8437 141.842 48.9331 141.303C36.0224 140.763 25.4177 143.647 25.2469 147.744C25.076 151.841 35.4037 155.599 48.3143 156.139C61.225 156.678 71.8297 153.794 72.0005 149.697Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.53"
                d="M71.9772 149.68C72.147 145.61 61.8879 141.877 49.0629 141.341C36.2379 140.805 25.7036 143.67 25.5339 147.74C25.3642 151.81 35.6233 155.544 48.4482 156.079C61.2732 156.615 71.8075 153.75 71.9772 149.68Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.54"
                d="M71.9479 149.665C72.1165 145.622 61.9256 141.914 49.1858 141.381C36.446 140.849 25.9817 143.695 25.8131 147.738C25.6445 151.781 35.8354 155.49 48.5752 156.022C61.315 156.554 71.7793 153.708 71.9479 149.665Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.55"
                d="M71.9098 149.611C72.0766 145.595 61.9532 141.913 49.2985 141.386C36.6439 140.86 26.25 143.689 26.0832 147.705C25.9165 151.721 36.0399 155.403 48.6945 155.93C61.3492 156.456 71.743 153.627 71.9098 149.611Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.56"
                d="M71.9048 149.633C72.0712 145.644 62.0165 141.985 49.4472 141.46C36.8778 140.935 26.5535 143.743 26.3871 147.731C26.2208 151.72 36.2754 155.379 48.8448 155.904C61.4142 156.429 71.7385 153.621 71.9048 149.633Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.57"
                d="M71.8815 149.616C72.0467 145.654 62.0607 142.02 49.577 141.498C37.0933 140.977 26.8394 143.766 26.6742 147.727C26.5089 151.689 36.495 155.323 48.9787 155.845C61.4624 156.366 71.7163 153.577 71.8815 149.616Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.58"
                d="M71.8435 149.564C72.0069 145.629 62.0883 142.021 49.6898 141.506C37.2912 140.99 27.1077 143.761 26.9443 147.696C26.7809 151.631 36.6995 155.238 49.098 155.754C61.4966 156.27 71.68 153.498 71.8435 149.564Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.59"
                d="M71.8141 149.547C71.9764 145.639 62.126 142.056 49.8127 141.544C37.4993 141.032 27.3858 143.784 27.2235 147.692C27.0612 151.6 36.9116 155.183 49.225 155.695C61.5383 156.207 71.6519 153.455 71.8141 149.547Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.6"
                d="M71.7926 149.531C71.9537 145.651 62.1715 142.093 49.9433 141.584C37.7152 141.076 27.6717 143.809 27.5105 147.689C27.3494 151.569 37.1316 155.127 49.3598 155.636C61.5879 156.144 71.6314 153.411 71.7926 149.531Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.61"
                d="M71.7693 149.514C71.9293 145.661 62.2157 142.128 50.0732 141.623C37.9307 141.118 27.9576 143.832 27.7976 147.685C27.6375 151.538 37.3512 155.072 49.4937 155.577C61.6361 156.082 71.6093 153.367 71.7693 149.514Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.62"
                d="M71.7478 149.499C71.9067 145.673 62.2612 142.165 50.2039 141.663C38.1467 141.162 28.2435 143.857 28.0846 147.683C27.9257 151.509 37.5712 155.018 49.6285 155.519C61.6857 156.021 71.5889 153.326 71.7478 149.499Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.63"
                d="M71.7263 149.483C71.8841 145.683 62.3067 142.199 50.3347 141.701C38.3626 141.204 28.5294 143.88 28.3716 147.679C28.2139 151.479 37.7912 154.962 49.7633 155.46C61.7353 155.958 71.5685 153.282 71.7263 149.483Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.64"
                d="M71.6969 149.467C71.8536 145.695 62.3444 142.236 50.4575 141.742C38.5707 141.247 28.8075 143.904 28.6508 147.676C28.4942 151.448 38.0034 154.907 49.8902 155.401C61.7771 155.896 71.5403 153.239 71.6969 149.467Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.65"
                d="M71.6736 149.45C71.8292 145.705 62.3885 142.271 50.5874 141.78C38.7862 141.289 29.0934 143.927 28.9379 147.672C28.7823 151.417 38.223 154.851 50.0241 155.342C61.8253 155.833 71.5181 153.195 71.6736 149.45Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.66"
                d="M71.6521 149.433C71.8065 145.715 62.4341 142.306 50.7181 141.819C39.0021 141.331 29.3793 143.95 29.2249 147.668C29.0705 151.386 38.443 154.795 50.1589 155.283C61.8749 155.77 71.4977 153.151 71.6521 149.433Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.67"
                d="M71.6306 149.418C71.7839 145.727 62.4796 142.343 50.8488 141.859C39.2181 141.375 29.6652 143.975 29.5119 147.666C29.3586 151.357 38.663 154.742 50.2937 155.226C61.9245 155.709 71.4773 153.109 71.6306 149.418Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.68"
                d="M71.6091 149.4C71.7612 145.737 62.525 142.378 50.9795 141.897C39.434 141.417 29.9511 143.998 29.7989 147.661C29.6468 151.325 38.883 154.684 50.4285 155.164C61.974 155.645 71.4569 153.064 71.6091 149.4Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.69"
                d="M71.5858 149.386C71.7368 145.749 62.5692 142.414 51.1093 141.938C39.6495 141.461 30.237 144.023 30.086 147.659C29.9349 151.296 39.1025 154.631 50.5624 155.107C62.0223 155.584 71.4347 153.022 71.5858 149.386Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.7"
                d="M71.5564 149.369C71.7064 145.759 62.6069 142.449 51.2323 141.976C39.8576 141.503 30.5151 144.046 30.3652 147.655C30.2153 151.265 39.3147 154.575 50.6894 155.048C62.064 155.521 71.4065 152.978 71.5564 149.369Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.71"
                d="M71.5369 148.953C71.6802 145.367 62.6365 142.094 51.3373 141.642C40.0381 141.19 30.7622 143.729 30.6189 147.315C30.4755 150.9 39.5192 154.173 50.8184 154.626C62.1176 155.078 71.3936 152.538 71.5369 148.953Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.72"
                d="M71.5134 149.338C71.661 145.783 62.6979 142.523 51.4937 142.057C40.2894 141.591 31.0869 144.095 30.9392 147.65C30.7916 151.206 39.7547 154.466 50.959 154.932C62.1632 155.398 71.3657 152.893 71.5134 149.338Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.73"
                d="M71.4901 149.321C71.6366 145.793 62.7421 142.558 51.6235 142.095C40.505 141.633 31.3728 144.118 31.2263 147.646C31.0797 151.175 39.9743 154.41 51.0929 154.873C62.2114 155.335 71.3436 152.85 71.4901 149.321Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.74"
                d="M71.4686 149.304C71.614 145.803 62.7876 142.593 51.7542 142.134C40.7209 141.675 31.6587 144.141 31.5133 147.643C31.3679 151.144 40.1943 154.354 51.2276 154.813C62.261 155.272 71.3232 152.806 71.4686 149.304Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.75"
                d="M71.4392 149.289C71.5835 145.815 62.8252 142.629 51.8771 142.174C40.9289 141.718 31.9368 144.165 31.7925 147.639C31.6482 151.113 40.4065 154.299 51.3546 154.754C62.3028 155.21 71.2949 152.763 71.4392 149.289Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.76"
                d="M71.4177 149.272C71.5609 145.825 62.8708 142.664 52.0078 142.212C41.1449 141.76 32.2227 144.189 32.0795 147.636C31.9364 151.083 40.6265 154.243 51.4894 154.695C62.3523 155.147 71.2746 152.719 71.4177 149.272Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.77"
                d="M71.3944 149.257C71.5365 145.837 62.9149 142.701 52.1377 142.253C41.3604 141.804 32.5086 144.213 32.3666 147.634C32.2245 151.054 40.8461 154.19 51.6233 154.638C62.4006 155.086 71.2524 152.677 71.3944 149.257Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.78"
                d="M71.3735 148.84C71.5093 145.444 62.9442 142.343 52.2429 141.915C41.5416 141.487 32.7565 143.892 32.6208 147.288C32.485 150.684 41.0501 153.785 51.7514 154.213C62.4526 154.642 71.2378 152.236 71.3735 148.84Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.79"
                d="M71.3514 149.224C71.4911 145.859 63.0059 142.773 52.3991 142.331C41.7922 141.89 33.0804 144.261 32.9406 147.627C32.8008 150.992 41.286 154.078 51.8929 154.52C62.4997 154.961 71.2116 152.59 71.3514 149.224Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.8"
                d="M71.322 149.208C71.4607 145.869 63.0436 142.807 52.522 142.37C42.0004 141.932 33.3585 144.284 33.2198 147.623C33.0812 150.961 41.4982 154.023 52.0199 154.46C62.5415 154.898 71.1834 152.546 71.322 149.208Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.81"
                d="M71.2987 149.191C71.4363 145.879 63.0878 142.842 52.6518 142.408C42.2159 141.974 33.6444 144.307 33.5069 147.619C33.3693 150.931 41.7178 153.967 52.1538 154.401C62.5897 154.835 71.1612 152.502 71.2987 149.191Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.82"
                d="M71.2772 149.174C71.4137 145.889 63.1333 142.877 52.7826 142.447C42.4318 142.016 33.9303 144.33 33.7939 147.615C33.6575 150.9 41.9378 153.912 52.2885 154.342C62.6393 154.773 71.1408 152.459 71.2772 149.174Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.83"
                d="M71.2557 149.158C71.391 145.901 63.1788 142.914 52.9132 142.487C42.6477 142.06 34.2162 144.354 34.0809 147.612C33.9456 150.869 42.1578 153.856 52.4233 154.283C62.6889 154.71 71.1204 152.415 71.2557 149.158Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.84"
                d="M71.2342 149.143C71.3683 145.913 63.2243 142.951 53.044 142.527C42.8636 142.104 34.5021 144.379 34.3679 147.61C34.2338 150.84 42.3778 153.802 52.5581 154.226C62.7385 154.649 71.1 152.374 71.2342 149.143Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.85"
                d="M71.2109 149.126C71.3439 145.923 63.2685 142.986 53.1738 142.566C43.0792 142.146 34.788 144.402 34.655 147.606C34.5219 150.809 42.5974 153.747 52.692 154.167C62.7867 154.586 71.0778 152.33 71.2109 149.126Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.86"
                d="M71.1816 149.11C71.3135 145.933 63.3062 143.021 53.2967 142.604C43.2873 142.188 35.0661 144.425 34.9342 147.602C34.8023 150.778 42.8096 153.691 52.819 154.107C62.8284 154.524 71.0496 152.286 71.1816 149.11Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.87"
                d="M71.16 149.094C71.2908 145.945 63.3516 143.057 53.4274 142.644C43.5032 142.232 35.352 144.45 35.2212 147.599C35.0904 150.748 43.0296 153.635 52.9538 154.048C62.878 154.461 71.0292 152.243 71.16 149.094Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.88"
                d="M71.1367 149.077C71.2664 145.955 63.3958 143.092 53.5573 142.683C43.7187 142.274 35.6379 144.473 35.5082 147.595C35.3786 150.717 43.2492 153.58 53.0877 153.989C62.9262 154.398 71.0071 152.199 71.1367 149.077Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.89"
                d="M71.1152 149.062C71.2438 145.967 63.4413 143.129 53.688 142.723C43.9346 142.318 35.9238 144.498 35.7953 147.593C35.6667 150.688 43.4691 153.526 53.2225 153.932C62.9758 154.338 70.9867 152.157 71.1152 149.062Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.9"
                d="M71.0859 149.045C71.2133 145.977 63.479 143.164 53.8109 142.762C44.1428 142.36 36.2019 144.521 36.0745 147.589C35.947 150.657 43.6813 153.471 53.3495 153.873C63.0176 154.275 70.9585 152.114 71.0859 149.045Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.91"
                d="M71.0643 149.029C71.1906 145.989 63.5245 143.201 53.9416 142.802C44.3586 142.403 36.4878 144.545 36.3615 147.586C36.2352 150.627 43.9013 153.415 53.4842 153.814C63.0672 154.212 70.9381 152.07 71.0643 149.029Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.92"
                d="M71.041 149.013C71.1662 145.999 63.5686 143.235 54.0714 142.84C44.5742 142.445 36.7737 144.568 36.6485 147.582C36.5233 150.596 44.1209 153.359 53.6181 153.754C63.1154 154.149 70.9159 152.026 71.041 149.013Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.93"
                d="M71.0195 148.996C71.1436 146.009 63.6142 143.27 54.2021 142.879C44.7901 142.487 37.0596 144.591 36.9355 147.578C36.8115 150.565 44.3409 153.304 53.7529 153.695C63.165 154.087 70.8955 151.983 71.0195 148.996Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.94"
                d="M70.998 148.981C71.1209 146.021 63.6597 143.307 54.3329 142.919C45.006 142.531 37.3455 144.616 37.2226 147.576C37.0997 150.536 44.5609 153.25 53.8877 153.638C63.2146 154.026 70.8751 151.941 70.998 148.981Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.95"
                d="M70.9765 148.963C71.0983 146.031 63.7052 143.342 54.4635 142.957C45.2219 142.573 37.6314 144.639 37.5096 147.571C37.3878 150.504 44.7809 153.192 54.0225 153.577C63.2641 153.961 70.8547 151.896 70.9765 148.963Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.96"
                d="M70.9454 148.948C71.066 146.043 63.7415 143.379 54.5856 142.998C45.4296 142.617 37.9095 144.664 37.7888 147.569C37.6681 150.475 44.9927 153.139 54.1486 153.52C63.3045 153.9 70.8247 151.854 70.9454 148.948Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.97"
                d="M70.9239 148.932C71.0434 146.053 63.787 143.414 54.7163 143.036C45.6456 142.659 38.1954 144.687 38.0758 147.565C37.9563 150.444 45.2127 153.083 54.2834 153.46C63.3541 153.838 70.8043 151.81 70.9239 148.932Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.98"
                d="M70.9023 148.915C71.0208 146.063 63.8326 143.448 54.847 143.075C45.8615 142.701 38.4813 144.71 38.3629 147.561C38.2444 150.413 45.4326 153.028 54.4182 153.401C63.4037 153.775 70.7839 151.766 70.9023 148.915Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.99"
                d="M70.8696 148.501C70.9825 145.675 63.8528 143.094 54.9447 142.738C46.0367 142.381 38.7238 144.383 38.6108 147.21C38.4978 150.037 45.6276 152.617 54.5356 152.974C63.4436 153.33 70.7566 151.328 70.8696 148.501Z"
                fill="#0F0A26"
              />
              <path
                d="M70.8575 148.884C70.9737 146.087 63.9222 143.522 55.1076 143.155C46.2929 142.789 39.0531 144.759 38.9369 147.556C38.8207 150.354 45.8722 152.918 54.6869 153.285C63.5015 153.652 70.7414 151.681 70.8575 148.884Z"
                fill="#0F0A26"
              />
            </g>
            <g opacity="0.1">
              <path
                opacity="0.01"
                d="M48.2292 134.667C48.4034 130.488 37.8713 126.656 24.705 126.106C11.5388 125.556 0.724137 128.497 0.549886 132.675C0.375634 136.853 10.9077 140.686 24.074 141.236C37.2403 141.786 48.0549 138.845 48.2292 134.667Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.02"
                d="M48.2105 134.653C48.3839 130.495 37.9039 126.681 24.8028 126.134C11.7016 125.587 0.940475 128.514 0.767081 132.671C0.593688 136.829 11.0737 140.643 24.1748 141.19C37.276 141.737 48.0371 138.811 48.2105 134.653Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.03"
                d="M48.1937 134.641C48.3662 130.504 37.9379 126.709 24.9014 126.165C11.8649 125.62 1.15681 128.532 0.984277 132.669C0.811742 136.806 11.2401 140.602 24.2766 141.146C37.3131 141.691 48.0211 138.778 48.1937 134.641Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.04"
                d="M48.1768 134.63C48.3485 130.513 37.9718 126.737 25 126.195C12.0281 125.653 1.37315 128.551 1.20147 132.668C1.0298 136.784 11.4064 140.56 24.3783 141.102C37.3502 141.644 48.0051 138.746 48.1768 134.63Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.05"
                d="M48.1599 134.616C48.3307 130.52 38.0058 126.763 25.0986 126.224C12.1913 125.685 1.58949 128.568 1.41867 132.664C1.24785 136.76 11.5728 140.517 24.48 141.056C37.3872 141.595 47.9891 138.712 48.1599 134.616Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.06"
                d="M48.1413 134.605C48.3112 130.529 38.0384 126.791 25.1963 126.254C12.3542 125.718 1.80582 128.587 1.63586 132.662C1.4659 136.737 11.7387 140.476 24.5808 141.012C37.4229 141.549 47.9713 138.68 48.1413 134.605Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.07"
                d="M48.1244 134.591C48.2935 130.536 38.0724 126.817 25.2949 126.283C12.5174 125.749 2.02216 128.604 1.85306 132.658C1.68396 136.713 11.9051 140.433 24.6825 140.966C37.46 141.5 47.9553 138.646 48.1244 134.591Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.08"
                d="M48.0997 134.58C48.2679 130.545 38.0985 126.845 25.3857 126.314C12.6729 125.783 2.23069 128.623 2.06244 132.657C1.8942 136.691 12.0636 140.391 24.7764 140.922C37.4893 141.453 47.9315 138.614 48.0997 134.58Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.09"
                d="M48.0828 134.568C48.2502 130.554 38.1325 126.873 25.4843 126.344C12.8361 125.816 2.44702 128.641 2.27964 132.655C2.11225 136.668 12.23 140.35 24.8782 140.878C37.5264 141.407 47.9154 138.581 48.0828 134.568Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.1"
                d="M48.0642 134.554C48.2307 130.561 38.1651 126.898 25.582 126.373C12.999 125.847 2.66336 128.658 2.49684 132.651C2.33031 136.644 12.3959 140.307 24.979 140.833C37.5621 141.358 47.8976 138.547 48.0642 134.554Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.11"
                d="M48.0473 134.543C48.213 130.57 38.1991 126.926 25.6806 126.403C13.1622 125.881 2.8797 128.677 2.71403 132.649C2.54836 136.622 12.5623 140.266 25.0807 140.789C37.5991 141.311 47.8816 138.515 48.0473 134.543Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.12"
                d="M48.0305 134.53C48.1953 130.578 38.2331 126.952 25.7793 126.432C13.3255 125.912 3.09609 128.694 2.93126 132.647C2.76643 136.599 12.7286 140.224 25.1824 140.745C37.6362 141.265 47.8656 138.483 48.0305 134.53Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.13"
                d="M48.0136 134.519C48.1776 130.587 38.2671 126.98 25.8779 126.463C13.4888 125.945 3.31243 128.713 3.14846 132.645C2.98449 136.576 12.895 140.183 25.2841 140.701C37.6733 141.218 47.8496 138.45 48.0136 134.519Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.14"
                d="M47.9949 134.505C48.1581 130.594 38.2997 127.006 25.9756 126.491C13.6516 125.977 3.52877 128.73 3.36565 132.641C3.20254 136.552 13.0609 140.14 25.385 140.655C37.709 141.17 47.8318 138.416 47.9949 134.505Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.15"
                d="M47.9781 134.494C48.1403 130.603 38.3337 127.034 26.0742 126.522C13.8148 126.01 3.74511 128.749 3.58285 132.639C3.4206 136.53 13.2273 140.099 25.4867 140.611C37.7461 141.123 47.8158 138.384 47.9781 134.494Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.16"
                d="M47.9799 134.088C48.1347 130.215 38.3658 126.679 26.1604 126.19C13.955 125.702 3.93512 128.446 3.78031 132.319C3.6255 136.192 13.3944 139.728 25.5998 140.217C37.8052 140.705 47.8251 137.961 47.9799 134.088Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.17"
                d="M47.9443 134.469C48.1049 130.619 38.4016 127.088 26.2715 126.581C14.1413 126.075 4.17778 128.784 4.01724 132.634C3.8567 136.483 13.56 140.014 25.6901 140.521C37.8202 141.028 47.7838 138.318 47.9443 134.469Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.18"
                d="M47.9257 134.457C48.0854 130.628 38.4342 127.116 26.3692 126.612C14.3042 126.108 4.39412 128.803 4.23444 132.632C4.07476 136.461 13.7259 139.973 25.7909 140.477C37.8559 140.981 47.766 138.286 47.9257 134.457Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.19"
                d="M47.9088 134.443C48.0676 130.635 38.4682 127.142 26.4678 126.64C14.4674 126.139 4.61046 128.82 4.45163 132.628C4.29281 136.436 13.8923 139.93 25.8927 140.431C37.893 140.932 47.75 138.252 47.9088 134.443Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.2"
                d="M47.8919 134.432C48.0499 130.644 38.5021 127.17 26.5664 126.671C14.6307 126.172 4.82679 128.839 4.66883 132.626C4.51087 136.414 14.0586 139.889 25.9944 140.387C37.9301 140.886 47.734 138.219 47.8919 134.432Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.21"
                d="M47.875 134.42C48.0321 130.653 38.5361 127.197 26.665 126.702C14.7939 126.206 5.04313 128.858 4.88603 132.625C4.72892 136.392 14.225 139.847 26.0961 140.343C37.9672 140.839 47.7179 138.187 47.875 134.42Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.22"
                d="M47.8564 134.407C48.0127 130.66 38.5687 127.223 26.7627 126.73C14.9568 126.237 5.25947 128.874 5.10322 132.621C4.94697 136.367 14.3909 139.804 26.1969 140.297C38.0029 140.79 47.7002 138.153 47.8564 134.407Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.23"
                d="M47.8395 134.395C47.9949 130.669 38.6026 127.251 26.8613 126.761C15.12 126.27 5.4758 128.893 5.32042 132.619C5.16503 136.345 14.5573 139.763 26.2986 140.253C38.0399 140.744 47.6842 138.121 47.8395 134.395Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.24"
                d="M47.8227 134.383C47.9772 130.678 38.6366 127.279 26.9599 126.791C15.2832 126.304 5.69214 128.912 5.53761 132.617C5.38308 136.322 14.7236 139.722 26.4003 140.209C38.077 140.697 47.6681 138.089 47.8227 134.383Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.25"
                d="M47.8058 134.37C47.9595 130.685 38.6706 127.305 27.0585 126.82C15.4465 126.335 5.90848 128.929 5.75481 132.613C5.60114 136.298 14.89 139.678 26.5021 140.163C38.1141 140.648 47.6521 138.055 47.8058 134.37Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.26"
                d="M47.7871 134.358C47.9399 130.694 38.7032 127.333 27.1563 126.851C15.6093 126.368 6.12482 128.948 5.972 132.612C5.81919 136.276 15.0559 139.637 26.6029 140.119C38.1498 140.602 47.6343 138.022 47.7871 134.358Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.27"
                d="M47.7625 134.346C47.9145 130.702 38.7294 127.359 27.2471 126.879C15.7648 126.4 6.3334 128.965 6.18142 132.609C6.02945 136.253 15.2145 139.596 26.6968 140.075C38.1791 140.555 47.6105 137.99 47.7625 134.346Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.28"
                d="M47.7456 134.332C47.8967 130.709 38.7634 127.385 27.3457 126.908C15.9281 126.431 6.54974 128.982 6.39862 132.605C6.2475 136.229 15.3808 139.553 26.7985 140.03C38.2162 140.506 47.5945 137.956 47.7456 134.332Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.29"
                d="M47.7456 133.931C47.8897 130.325 38.7951 127.033 27.4322 126.578C16.0693 126.123 6.74103 128.677 6.5969 132.283C6.45278 135.889 15.5474 139.181 26.9103 139.636C38.2732 140.091 47.6015 137.536 47.7456 133.931Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.3"
                d="M47.7101 134.309C47.8595 130.727 38.83 127.441 27.5421 126.969C16.2542 126.498 6.98241 129.019 6.83301 132.602C6.68361 136.184 15.7131 139.47 27.001 139.942C38.2889 140.413 47.5607 137.891 47.7101 134.309Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.31"
                d="M47.6932 134.296C47.8418 130.734 38.8639 127.467 27.6407 126.998C16.4174 126.529 7.19875 129.036 7.05021 132.598C6.90167 136.16 15.8795 139.427 27.1028 139.896C38.326 140.364 47.5447 137.857 47.6932 134.296Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.32"
                d="M47.6763 134.284C47.824 130.743 38.8979 127.494 27.7393 127.028C16.5807 126.562 7.41509 129.055 7.2674 132.596C7.11972 136.137 16.0459 139.386 27.2045 139.852C38.3631 140.318 47.5287 137.825 47.6763 134.284Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.33"
                d="M47.6595 134.272C47.8063 130.752 38.9319 127.522 27.8379 127.059C16.7439 126.596 7.63142 129.074 7.4846 132.594C7.33777 136.115 16.2122 139.344 27.3062 139.808C38.4002 140.271 47.5126 137.793 47.6595 134.272Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.34"
                d="M47.6408 134.259C47.7868 130.759 38.9645 127.548 27.9356 127.087C16.9067 126.627 7.84776 129.091 7.70179 132.591C7.55583 136.09 16.3782 139.301 27.407 139.762C38.4359 140.223 47.4948 137.759 47.6408 134.259Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.35"
                d="M47.6239 134.247C47.7691 130.768 38.9984 127.576 28.0342 127.118C17.07 126.66 8.0641 129.109 7.91899 132.589C7.77388 136.068 16.5445 139.26 27.5087 139.718C38.4729 140.176 47.4788 137.727 47.6239 134.247Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.36"
                d="M47.6071 134.234C47.7513 130.775 39.0324 127.602 28.1328 127.147C17.2332 126.691 8.28044 129.126 8.13619 132.585C7.99194 136.044 16.7109 139.217 27.6104 139.672C38.51 140.127 47.4628 137.692 47.6071 134.234Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.37"
                d="M47.5902 134.222C47.7336 130.784 39.0664 127.63 28.2314 127.177C17.3965 126.725 8.49677 129.145 8.35338 132.583C8.20999 136.021 16.8772 139.175 27.7122 139.628C38.5471 140.081 47.4468 137.66 47.5902 134.222Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.38"
                d="M47.5716 134.21C47.7141 130.793 39.099 127.658 28.3291 127.208C17.5593 126.758 8.71311 129.164 8.57058 132.581C8.42805 135.999 17.0432 139.134 27.813 139.584C38.5828 140.034 47.429 137.628 47.5716 134.21Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.39"
                d="M47.5547 134.197C47.6964 130.8 39.1329 127.684 28.4278 127.236C17.7226 126.789 8.92945 129.181 8.78777 132.578C8.6461 135.975 17.2095 139.091 27.9147 139.538C38.6199 139.985 47.413 137.594 47.5547 134.197Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.4"
                d="M47.5378 134.185C47.6786 130.809 39.1669 127.711 28.5264 127.267C17.8858 126.823 9.14578 129.199 9.00497 132.576C8.86415 135.952 17.3759 139.05 28.0164 139.494C38.657 139.939 47.397 137.562 47.5378 134.185Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.41"
                d="M47.5209 134.174C47.6609 130.818 39.2009 127.739 28.625 127.298C18.049 126.856 9.36212 129.218 9.22217 132.574C9.08221 135.93 17.5422 139.009 28.1181 139.45C38.694 139.892 47.381 137.53 47.5209 134.174Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.42"
                d="M47.5023 134.16C47.6414 130.825 39.2335 127.765 28.7227 127.326C18.2119 126.887 9.57846 129.235 9.43936 132.57C9.30026 135.906 17.7082 138.965 28.219 139.404C38.7298 139.843 47.3632 137.495 47.5023 134.16Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.43"
                d="M47.4776 134.15C47.6159 130.835 39.2597 127.793 28.8136 127.357C18.3674 126.921 9.78704 129.254 9.64878 132.57C9.51052 135.885 17.8667 138.926 28.3129 139.362C38.759 139.799 47.3394 137.465 47.4776 134.15Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.44"
                d="M47.4608 134.136C47.5982 130.842 39.2937 127.819 28.9121 127.386C18.5306 126.952 10.0034 129.271 9.86598 132.566C9.72857 135.86 18.0331 138.883 28.4146 139.316C38.7961 139.75 47.3234 137.431 47.4608 134.136Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.45"
                d="M47.4439 134.125C47.5804 130.851 39.3276 127.847 29.0108 127.416C18.6939 126.985 10.2197 129.29 10.0832 132.564C9.94663 135.838 18.1994 138.842 28.5163 139.272C38.8332 139.703 47.3073 137.399 47.4439 134.125Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.46"
                d="M47.4252 134.111C47.5609 130.858 39.3602 127.873 29.1085 127.445C18.8567 127.017 10.4361 129.307 10.3004 132.56C10.1647 135.814 18.3654 138.798 28.6171 139.227C38.8689 139.655 47.2896 137.364 47.4252 134.111Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.47"
                d="M47.4084 134.099C47.5432 130.867 39.3942 127.901 29.2071 127.475C19.02 127.05 10.6524 129.326 10.5176 132.559C10.3827 135.791 18.5317 138.757 28.7188 139.183C38.9059 139.608 47.2735 137.332 47.4084 134.099Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.48"
                d="M47.3915 134.086C47.5255 130.874 39.4282 127.927 29.3057 127.504C19.1832 127.081 10.8687 129.342 10.7348 132.555C10.6008 135.767 18.6981 138.714 28.8206 139.137C38.943 139.559 47.2575 137.298 47.3915 134.086Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.49"
                d="M47.3746 134.074C47.5077 130.883 39.4621 127.955 29.4043 127.535C19.3465 127.114 11.0851 129.361 10.952 132.553C10.8188 135.745 18.8644 138.673 28.9223 139.093C38.9801 139.513 47.2415 137.266 47.3746 134.074Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.5"
                d="M47.356 134.063C47.4882 130.892 39.4947 127.983 29.502 127.565C19.5093 127.148 11.3014 129.38 11.1692 132.551C11.0369 135.722 19.0304 138.631 29.0231 139.049C39.0158 139.466 47.2237 137.234 47.356 134.063Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.5"
                d="M47.3227 134.017C47.4536 130.867 39.5113 127.978 29.5832 127.565C19.655 127.152 11.5006 129.371 11.3697 132.522C11.2389 135.673 19.1812 138.561 29.1093 138.974C39.0375 139.387 47.1919 137.168 47.3227 134.017Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.51"
                d="M47.3222 134.038C47.4528 130.908 39.5627 128.036 29.6992 127.624C19.8358 127.212 11.7341 129.416 11.6035 132.546C11.473 135.676 19.3631 138.547 29.2265 138.959C39.09 139.371 47.1917 137.168 47.3222 134.038Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.52"
                d="M47.289 133.992C47.4181 130.883 39.5793 128.032 29.7804 127.624C19.9815 127.217 11.9333 129.407 11.8041 132.516C11.675 135.626 19.5139 138.477 29.3128 138.884C39.1116 139.292 47.1599 137.102 47.289 133.992Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.53"
                d="M47.2867 134.012C47.4155 130.924 39.6293 128.09 29.8956 127.684C20.1619 127.277 12.1668 129.451 12.0379 132.54C11.9091 135.629 19.6954 138.462 29.4291 138.869C39.1628 139.276 47.1579 137.101 47.2867 134.012Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.54"
                d="M47.2535 133.969C47.3809 130.901 39.6459 128.088 29.9767 127.686C20.3076 127.283 12.366 129.445 12.2385 132.513C12.1111 135.581 19.8462 138.394 29.5153 138.797C39.1844 139.199 47.1261 137.038 47.2535 133.969Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.55"
                d="M47.2366 133.956C47.3632 130.908 39.6798 128.114 30.0754 127.714C20.4709 127.315 12.5823 129.461 12.4557 132.509C12.3292 135.557 20.0125 138.351 29.617 138.751C39.2215 139.15 47.11 137.003 47.2366 133.956Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.56"
                d="M47.2198 133.944C47.3455 130.917 39.7138 128.142 30.174 127.745C20.6341 127.348 12.7987 129.48 12.6729 132.507C12.5472 135.534 20.1789 138.31 29.7187 138.707C39.2586 139.104 47.094 136.971 47.2198 133.944Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.57"
                d="M47.2174 133.964C47.3428 130.958 39.7637 128.2 30.2891 127.804C20.8145 127.408 13.0321 129.524 12.9067 132.531C12.7813 135.537 20.3604 138.295 29.835 138.691C39.3097 139.087 47.092 136.971 47.2174 133.964Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.58"
                d="M47.1843 133.918C47.3083 130.932 39.7805 128.194 30.3704 127.802C20.9603 127.411 13.2314 129.515 13.1074 132.501C12.9834 135.487 20.5112 138.226 29.9213 138.617C39.3313 139.008 47.0603 136.905 47.1843 133.918Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.59"
                d="M47.1674 133.907C47.2906 130.941 39.8145 128.222 30.469 127.833C21.1236 127.444 13.4478 129.533 13.3246 132.499C13.2014 135.465 20.6775 138.184 30.023 138.573C39.3684 138.962 47.0442 136.873 47.1674 133.907Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.6"
                d="M47.159 133.927C47.2819 130.981 39.8579 128.28 30.5772 127.892C21.2965 127.504 13.6734 129.578 13.5505 132.523C13.4277 135.468 20.8516 138.17 30.1324 138.557C39.4131 138.945 47.0362 136.872 47.159 133.927Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.61"
                d="M47.1319 133.882C47.2534 130.957 39.8811 128.275 30.6654 127.892C21.4497 127.509 13.8805 129.569 13.759 132.494C13.6375 135.418 21.0099 138.1 30.2255 138.483C39.4412 138.866 47.0104 136.806 47.1319 133.882Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.62"
                d="M47.115 133.87C47.2356 130.966 39.915 128.303 30.764 127.923C21.613 127.542 14.0968 129.588 13.9762 132.492C13.8556 135.396 21.1762 138.059 30.3272 138.439C39.4783 138.82 46.9944 136.774 47.115 133.87Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.63"
                d="M47.0903 133.859C47.2101 130.975 39.9412 128.331 30.8548 127.953C21.7684 127.575 14.3053 129.606 14.1856 132.49C14.0658 135.373 21.3347 138.017 30.4211 138.395C39.5075 138.773 46.9706 136.742 47.0903 133.859Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.64"
                d="M47.0898 133.878C47.2092 131.015 39.9924 128.389 30.9707 128.012C21.949 127.635 14.5387 129.651 14.4193 132.514C14.2999 135.376 21.5167 138.003 30.5384 138.38C39.5601 138.756 46.9704 136.741 47.0898 133.878Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.65"
                d="M47.0548 133.834C47.1729 130.991 40.0078 128.385 31.0512 128.013C22.0945 127.64 14.738 129.642 14.62 132.484C14.5019 135.327 21.667 137.933 30.6237 138.305C39.5803 138.678 46.9368 136.676 47.0548 133.834Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.66"
                d="M47.038 133.82C47.1552 130.998 40.0418 128.411 31.1498 128.041C22.2578 127.671 14.9544 129.659 14.8372 132.481C14.72 135.302 21.8334 137.89 30.7254 138.26C39.6174 138.629 46.9208 136.642 47.038 133.82Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.67"
                d="M47.0211 133.809C47.1374 131.007 40.0757 128.439 31.2484 128.072C22.421 127.705 15.1707 129.678 15.0544 132.479C14.9381 135.28 21.9997 137.848 30.8271 138.216C39.6545 138.583 46.9048 136.61 47.0211 133.809Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.68"
                d="M47.0025 133.797C47.1179 131.016 40.1084 128.467 31.3461 128.102C22.5839 127.738 15.3871 129.697 15.2716 132.477C15.1561 135.258 22.1657 137.807 30.9279 138.172C39.6902 138.536 46.887 136.577 47.0025 133.797Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.69"
                d="M46.9856 133.783C47.1002 131.023 40.1423 128.493 31.4447 128.131C22.7471 127.769 15.6034 129.713 15.4888 132.473C15.3742 135.233 22.332 137.764 31.0296 138.126C39.7272 138.488 46.871 136.543 46.9856 133.783Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.7"
                d="M46.9687 133.772C47.0825 131.033 40.1763 128.521 31.5434 128.162C22.9104 127.803 15.8198 129.732 15.706 132.472C15.5922 135.211 22.4984 137.723 31.1314 138.082C39.7643 138.441 46.8549 136.511 46.9687 133.772Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.71"
                d="M46.9518 133.76C47.0648 131.042 40.2103 128.549 31.642 128.192C23.0736 127.836 16.0361 129.751 15.9232 132.47C15.8103 135.189 22.6647 137.681 31.2331 138.038C39.8014 138.394 46.8389 136.479 46.9518 133.76Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.72"
                d="M46.9332 133.747C47.0453 131.049 40.2429 128.575 31.7397 128.221C23.2365 127.867 16.2525 129.768 16.1404 132.466C16.0283 135.164 22.8307 137.638 31.3339 137.992C39.8371 138.346 46.8212 136.445 46.9332 133.747Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.73"
                d="M46.9164 133.734C47.0276 131.056 40.277 128.601 31.8384 128.25C23.3998 127.899 16.4689 129.785 16.3576 132.463C16.2464 135.141 22.9971 137.597 31.4356 137.948C39.8742 138.299 46.8052 136.413 46.9164 133.734Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.74"
                d="M46.8995 133.723C47.0099 131.065 40.3109 128.629 31.937 128.28C23.5631 127.932 16.6852 129.804 16.5748 132.462C16.4645 135.119 23.1634 137.556 31.5373 137.904C39.9113 138.252 46.7891 136.38 46.8995 133.723Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.75"
                d="M46.8826 133.709C46.9922 131.072 40.3449 128.655 32.0356 128.309C23.7264 127.963 16.9016 129.821 16.792 132.458C16.6825 135.095 23.3298 137.513 31.6391 137.858C39.9483 138.204 46.7731 136.346 46.8826 133.709Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.76"
                d="M46.864 133.698C46.9727 131.081 40.3775 128.682 32.1334 128.339C23.8892 127.997 17.1179 129.84 17.0092 132.456C16.9006 135.072 23.4957 137.471 31.7399 137.814C39.984 138.157 46.7553 136.314 46.864 133.698Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.77"
                d="M46.8471 133.686C46.9549 131.09 40.4115 128.71 32.232 128.37C24.0525 128.03 17.3343 129.858 17.2264 132.454C17.1186 135.05 23.6621 137.43 31.8416 137.77C40.0211 138.111 46.7393 136.282 46.8471 133.686Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.78"
                d="M46.8302 133.673C46.9372 131.098 40.4455 128.736 32.3306 128.399C24.2157 128.061 17.5506 129.875 17.4437 132.45C17.3367 135.026 23.8284 137.387 31.9433 137.724C40.0582 138.062 46.7233 136.248 46.8302 133.673Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.79"
                d="M46.8056 133.661C46.9117 131.107 40.4717 128.764 32.4214 128.429C24.3712 128.094 17.7591 129.894 17.653 132.449C17.5469 135.003 23.987 137.346 32.0372 137.681C40.0875 138.015 46.6995 136.216 46.8056 133.661Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.8"
                d="M46.8049 133.297C46.9063 130.76 40.5096 128.445 32.5176 128.125C24.5256 127.805 17.9646 129.602 17.8632 132.138C17.7619 134.674 24.1585 136.99 32.1505 137.31C40.1425 137.63 46.7035 135.833 46.8049 133.297Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.81"
                d="M46.7701 133.636C46.8745 131.123 40.5382 128.818 32.6178 128.489C24.6973 128.159 18.1918 129.93 18.0874 132.443C17.9831 134.957 24.3193 137.261 32.2397 137.591C40.1602 137.92 46.6657 136.15 46.7701 133.636Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.82"
                d="M46.7532 133.625C46.8567 131.132 40.5722 128.846 32.7164 128.519C24.8605 128.192 18.4082 129.948 18.3046 132.441C18.2011 134.934 24.4856 137.22 32.3415 137.547C40.1973 137.873 46.6497 136.117 46.7532 133.625Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.83"
                d="M46.7363 133.613C46.839 131.141 40.6062 128.874 32.815 128.55C25.0238 128.226 18.6245 129.967 18.5219 132.439C18.4192 134.912 24.652 137.179 32.4432 137.503C40.2344 137.827 46.6336 136.085 46.7363 133.613Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.84"
                d="M46.7177 133.6C46.8195 131.148 40.6388 128.9 32.9127 128.578C25.1866 128.257 18.8409 129.984 18.7391 132.436C18.6372 134.887 24.8179 137.136 32.544 137.457C40.2701 137.778 46.6159 136.051 46.7177 133.6Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.85"
                d="M46.7008 133.588C46.8018 131.157 40.6728 128.928 33.0114 128.609C25.3499 128.29 19.0572 130.003 18.9563 132.434C18.8553 134.865 24.9843 137.094 32.6457 137.413C40.3072 137.732 46.5998 136.019 46.7008 133.588Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.86"
                d="M46.6839 133.574C46.7841 131.164 40.7068 128.954 33.11 128.638C25.5132 128.322 19.2736 130.02 19.1735 132.43C19.0733 134.841 25.1506 137.051 32.7474 137.367C40.3442 137.683 46.5838 135.985 46.6839 133.574Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.87"
                d="M46.6762 133.211C46.7718 130.819 40.738 128.635 33.1993 128.333C25.6607 128.031 19.4718 129.726 19.3762 132.118C19.2806 134.51 25.3144 136.694 32.8531 136.996C40.3918 137.297 46.5806 135.603 46.6762 133.211Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.88"
                d="M46.6574 133.198C46.7522 130.826 40.7706 128.661 33.2971 128.362C25.8236 128.063 19.6883 129.743 19.5935 132.114C19.4987 134.486 25.4803 136.651 32.9538 136.95C40.4273 137.249 46.5626 135.569 46.6574 133.198Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.89"
                d="M46.6316 133.539C46.7292 131.19 40.8074 129.036 33.405 128.728C26.0026 128.42 19.9227 130.075 19.8251 132.424C19.7275 134.773 25.6493 136.927 33.0517 137.235C40.4541 137.543 46.534 135.888 46.6316 133.539Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.9"
                d="M46.6147 133.525C46.7114 131.197 40.8414 129.061 33.5036 128.756C26.1659 128.451 20.139 130.091 20.0423 132.42C19.9456 134.749 25.8156 136.884 33.1534 137.189C40.4912 137.495 46.518 135.854 46.6147 133.525Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.91"
                d="M46.5979 133.514C46.6937 131.206 40.8754 129.089 33.6023 128.787C26.3291 128.484 20.3554 130.11 20.2595 132.418C20.1636 134.727 25.982 136.843 33.2551 137.145C40.5283 137.448 46.502 135.822 46.5979 133.514Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.92"
                d="M46.5792 133.502C46.6742 131.215 40.908 129.117 33.7 128.817C26.492 128.518 20.5717 130.129 20.4767 132.417C20.3817 134.704 26.1479 136.802 33.3559 137.101C40.5639 137.401 46.4842 135.79 46.5792 133.502Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.93"
                d="M46.5624 133.489C46.6565 131.222 40.942 129.143 33.7986 128.846C26.6552 128.549 20.7881 130.146 20.6939 132.413C20.5998 134.68 26.3143 136.758 33.4577 137.056C40.601 137.353 46.4682 135.756 46.5624 133.489Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.94"
                d="M46.5455 133.477C46.6388 131.231 40.976 129.171 33.8972 128.877C26.8185 128.582 21.0044 130.165 20.9111 132.411C20.8178 134.657 26.4806 136.717 33.5594 137.012C40.6381 137.306 46.4522 135.724 46.5455 133.477Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.95"
                d="M46.5286 133.466C46.621 131.24 41.0099 129.199 33.9958 128.907C26.9817 128.616 21.2208 130.183 21.1283 132.409C21.0359 134.635 26.647 136.676 33.6611 136.968C40.6752 137.259 46.4362 135.692 46.5286 133.466Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.96"
                d="M46.51 133.452C46.6016 131.247 41.0425 129.225 34.0936 128.936C27.1446 128.647 21.4371 130.2 21.3455 132.406C21.2539 134.611 26.8129 136.633 33.7619 136.922C40.7109 137.211 46.4184 135.657 46.51 133.452Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.97"
                d="M46.4853 133.439C46.576 131.254 41.0687 129.251 34.1844 128.965C27.3001 128.678 21.6456 130.217 21.5549 132.402C21.4642 134.586 26.9715 136.59 33.8558 136.876C40.7402 137.162 46.3946 135.623 46.4853 133.439Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.98"
                d="M46.4684 133.427C46.5583 131.263 41.1027 129.279 34.283 128.995C27.4633 128.712 21.862 130.236 21.7721 132.4C21.6822 134.564 27.1378 136.548 33.9575 136.832C40.7772 137.116 46.3785 135.591 46.4684 133.427Z"
                fill="#0F0A26"
              />
              <path
                opacity="0.99"
                d="M46.4515 133.416C46.5406 131.272 41.1367 129.307 34.3816 129.026C27.6266 128.745 22.0783 130.255 21.9893 132.398C21.9003 134.542 27.3042 136.507 34.0592 136.788C40.8143 137.069 46.3625 135.559 46.4515 133.416Z"
                fill="#0F0A26"
              />
              <path
                d="M46.4329 133.402C46.5211 131.279 41.1693 129.333 34.4793 129.054C27.7894 128.776 22.2947 130.272 22.2065 132.394C22.1183 134.517 27.4701 136.464 34.1601 136.742C40.85 137.02 46.3447 135.525 46.4329 133.402Z"
                fill="#0F0A26"
              />
            </g>
            <path
              d="M80.2271 26.3846L57.7289 115.467C56.7687 119.761 61.4205 125.603 65.5708 127.055L124.147 147.553C125.226 147.931 126.373 148.076 127.513 147.978C128.652 147.88 129.757 147.543 130.757 146.987C131.756 146.431 132.627 145.67 133.311 144.754C133.996 143.837 134.479 142.786 134.729 141.67L159.744 29.7709C160.686 25.5587 157.187 18.8865 152.88 18.6698L91.1173 18.1077C88.618 17.9795 86.154 18.7401 84.161 20.2548C82.1681 21.7695 80.7746 23.9408 80.2271 26.3846Z"
              fill="url(#paint0_linear_1671_2900)"
            />
            <path
              d="M78.1759 23.4819L57.9201 114.072C57.4562 116.147 57.7699 118.319 58.8015 120.178C59.8331 122.036 61.5109 123.451 63.5161 124.153L122.091 144.653C123.17 145.031 124.317 145.175 125.456 145.078C126.595 144.98 127.701 144.642 128.7 144.087C129.7 143.531 130.57 142.77 131.255 141.853C131.939 140.936 132.422 139.885 132.672 138.769L157.688 26.8699C157.909 25.8804 157.912 24.8545 157.696 23.8638C157.48 22.8732 157.05 21.9416 156.437 21.1343C155.824 20.3269 155.042 19.6632 154.147 19.1894C153.251 18.7157 152.262 18.4433 151.251 18.3914L89.0625 15.2032C86.5636 15.076 84.1002 15.8372 82.108 17.3522C80.1157 18.8672 78.7229 21.0384 78.1759 23.4819Z"
              fill="url(#paint1_linear_1671_2900)"
            />
            <path
              d="M125.746 141.418C124.925 141.6 124.07 141.548 123.277 141.269L64.7031 120.771C63.5264 120.359 62.5419 119.528 61.9366 118.437C61.3314 117.347 61.1474 116.071 61.4197 114.854L81.6702 24.2628C82.031 22.6425 82.9537 21.2025 84.2746 20.1983C85.5954 19.1941 87.2291 18.6906 88.8856 18.7771L151.074 21.9645C151.565 21.99 152.046 22.1225 152.481 22.3528C152.916 22.5832 153.296 22.9058 153.594 23.2981C153.891 23.6904 154.1 24.1431 154.205 24.6244C154.31 25.1058 154.309 25.6043 154.202 26.0852L129.185 137.984C129.001 138.826 128.58 139.597 127.97 140.206C127.361 140.814 126.59 141.235 125.748 141.417L125.746 141.418Z"
              fill="url(#paint2_linear_1671_2900)"
            />
            <path
              d="M148.284 36.8867L146.841 43.5802L138.977 32.2012L145.272 32.7802C145.751 32.8247 146.215 32.9703 146.633 33.2074C147.052 33.4445 147.416 33.7678 147.7 34.1558C147.985 34.5438 148.184 34.9878 148.285 35.4586C148.386 35.9293 148.386 36.416 148.284 36.8867Z"
              fill="url(#paint3_linear_1671_2900)"
            />
            <path
              d="M146.841 43.5799L146.607 44.6696L127.281 134.337C127.147 134.958 126.883 135.543 126.507 136.054C126.13 136.566 125.65 136.992 125.098 137.305C124.545 137.617 123.933 137.81 123.301 137.869C122.669 137.928 122.032 137.853 121.431 137.649L74.5849 121.688L67.1583 119.164C66.1022 118.805 65.2161 118.068 64.6707 117.094C64.1253 116.121 63.9591 114.979 64.2041 113.891L66.01 105.816C66.0224 105.777 66.0277 105.755 66.0277 105.755L82.5884 31.7578C82.8779 30.4727 83.6243 29.3367 84.6886 28.5614C85.7528 27.7862 87.0624 27.4245 88.3733 27.5438L138.314 32.1432L138.976 32.2045L146.841 43.5799Z"
              fill="#FEFEFE"
            />
            <path
              d="M130.858 28.7571L102.258 26.1665C102.031 26.1502 101.811 26.0864 101.611 25.9794C101.411 25.8723 101.236 25.7243 101.097 25.5451C100.958 25.3658 100.858 25.1593 100.804 24.9389C100.75 24.7186 100.743 24.4893 100.784 24.266L101.45 20.5742C101.521 20.1814 101.734 19.8287 102.049 19.5839C102.364 19.3392 102.759 19.2196 103.156 19.2483L131.819 21.3185C132.046 21.3348 132.266 21.3985 132.466 21.5056C132.666 21.6127 132.841 21.7606 132.98 21.9399C133.119 22.1191 133.219 22.3256 133.273 22.546C133.327 22.7664 133.334 22.9957 133.293 23.219L132.562 27.432C132.491 27.8244 132.278 28.1768 131.964 28.4214C131.649 28.666 131.255 28.7855 130.858 28.7571Z"
              fill="url(#paint4_linear_1671_2900)"
            />
            <path
              d="M125.983 21.8766L131.958 8.05004C132.109 7.70119 132.173 7.32114 132.147 6.94199C132.12 6.56284 132.002 6.1957 131.804 5.87158C131.606 5.54746 131.332 5.27586 131.007 5.07971C130.681 4.88356 130.314 4.76862 129.935 4.74459L117.406 3.94531L111.148 20.6333L125.983 21.8766Z"
              fill="url(#paint5_linear_1671_2900)"
            />
            <path
              d="M130.639 28.3362L102.039 25.7448C101.813 25.7284 101.592 25.6647 101.393 25.5576C101.193 25.4505 101.017 25.3026 100.878 25.1233C100.739 24.9441 100.64 24.7376 100.586 24.5172C100.532 24.2968 100.524 24.0676 100.565 23.8443L101.231 20.1525C101.302 19.7594 101.516 19.4064 101.831 19.1616C102.147 18.9168 102.541 18.7974 102.939 18.8266L131.602 20.8967C131.829 20.9132 132.049 20.977 132.249 21.0841C132.448 21.1912 132.623 21.3392 132.762 21.5184C132.901 21.6976 133.001 21.9041 133.055 22.1244C133.109 22.3447 133.117 22.5739 133.076 22.7972L132.345 27.0103C132.274 27.4031 132.061 27.7558 131.746 28.0006C131.431 28.2454 131.037 28.3649 130.639 28.3362Z"
              fill="url(#paint6_linear_1671_2900)"
            />
            <path
              d="M125.406 21.7594L131.38 7.93285C131.531 7.58406 131.595 7.20409 131.569 6.82501C131.542 6.44592 131.424 6.07883 131.226 5.75473C131.028 5.43063 130.754 5.15901 130.429 4.96281C130.104 4.76661 129.736 4.65157 129.357 4.6274L116.829 3.82812L110.57 20.5161L125.406 21.7594Z"
              fill="url(#paint7_linear_1671_2900)"
            />
            <path
              d="M109.48 20.625L108.97 4.17763C108.957 3.75353 109.03 3.33114 109.183 2.93569C109.337 2.54025 109.569 2.17984 109.865 1.87598C110.161 1.57212 110.515 1.33104 110.906 1.16712C111.297 1.0032 111.717 0.919806 112.141 0.921914L122.863 0.97875C123.313 0.981149 123.757 1.07279 124.171 1.24836C124.584 1.42392 124.959 1.67992 125.273 2.00152C125.587 2.32311 125.835 2.70391 126 3.12182C126.166 3.53973 126.248 3.98644 126.24 4.43607L125.935 22.0077L109.48 20.625Z"
              fill="url(#paint8_linear_1671_2900)"
            />
            <path
              d="M108.957 20.3769L108.447 3.92871C108.434 3.50461 108.506 3.08221 108.66 2.68676C108.813 2.2913 109.045 1.9309 109.341 1.62709C109.637 1.32327 109.991 1.08228 110.382 0.918501C110.773 0.754724 111.194 0.671525 111.618 0.673877L122.34 0.729826C122.789 0.732344 123.234 0.824064 123.647 0.999672C124.061 1.17528 124.436 1.43129 124.75 1.75286C125.064 2.07443 125.311 2.45517 125.477 2.87303C125.643 3.29088 125.724 3.73755 125.716 4.18714L125.411 21.7579L108.957 20.3769Z"
              fill="url(#paint9_linear_1671_2900)"
            />
            <path
              d="M125.678 36.6696C125.559 36.6696 125.441 36.6624 125.323 36.6482L103.531 33.9902C103.287 33.9599 103.051 33.8798 102.839 33.7548C102.627 33.6298 102.443 33.4626 102.298 33.2634C102.153 33.0643 102.05 32.8375 101.997 32.5971C101.943 32.3567 101.939 32.1079 101.985 31.8659L103.066 26.2168L103.568 26.3127L102.487 31.9627C102.454 32.136 102.457 32.3143 102.495 32.4865C102.534 32.6587 102.607 32.8211 102.711 32.9637C102.815 33.1063 102.947 33.226 103.099 33.3154C103.251 33.4047 103.42 33.4618 103.595 33.4831L125.389 36.1403C125.994 36.2142 126.604 36.0533 127.093 35.6909C127.583 35.3284 127.915 34.7919 128.021 34.1918L128.998 28.6723L129.502 28.7612L128.521 34.2815C128.402 34.95 128.052 35.5555 127.533 35.992C127.013 36.4286 126.356 36.6684 125.678 36.6696Z"
              fill="url(#paint10_linear_1671_2900)"
            />
            <path
              d="M104.839 23.464C105.439 23.4397 105.906 22.933 105.882 22.3322C105.858 21.7313 105.352 21.2639 104.751 21.2881C104.151 21.3124 103.684 21.8191 103.708 22.4199C103.732 23.0208 104.238 23.4882 104.839 23.464Z"
              fill="url(#paint11_linear_1671_2900)"
            />
            <path
              d="M129.514 24.703C129.758 24.1539 129.51 23.5111 128.962 23.2673C128.413 23.0235 127.771 23.271 127.527 23.8201C127.283 24.3693 127.531 25.0121 128.079 25.2559C128.628 25.4997 129.271 25.2522 129.514 24.703Z"
              fill="url(#paint12_linear_1671_2900)"
            />
            <path
              d="M140.102 40.7961C138.611 40.5723 137.085 40.693 135.946 40.868C135.758 40.8976 135.565 40.878 135.387 40.8109C135.208 40.7437 135.05 40.6315 134.928 40.4851C134.806 40.3387 134.724 40.163 134.69 39.9754C134.656 39.7877 134.671 39.5943 134.733 39.4142C135.663 36.7556 137.105 34.305 138.976 32.2012L146.84 43.5802C146.84 43.5802 142.146 41.1016 140.102 40.7961Z"
              fill="url(#paint13_linear_1671_2900)"
            />
            <path
              d="M74.5849 121.689L67.1583 119.165C66.1022 118.806 65.2161 118.069 64.6707 117.095C64.1253 116.122 63.9591 114.981 64.2041 113.892L66.01 105.817C66.0224 105.778 66.0277 105.756 66.0277 105.756L74.5849 121.689Z"
              fill="url(#paint14_linear_1671_2900)"
            />
            <path
              d="M99.2969 47.5254C107.79 48.8335 116.304 50.0049 124.817 51.1745"
              stroke="#4A9385"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M98.6016 50.5215C112.101 52.5747 125.635 54.3784 139.143 56.3704"
              stroke="#00AE42"
              stroke-miterlimit="10"
            />
            <path
              d="M95.0469 38.832C108.546 40.8862 122.081 42.6899 135.589 44.681"
              stroke="#00AE42"
              stroke-miterlimit="10"
            />
            <path
              d="M97.9062 53.5488C111.404 55.6474 124.936 57.499 138.441 59.5478"
              stroke="#00AE42"
              stroke-miterlimit="10"
            />
            <path
              d="M97.2031 56.6055C110.696 58.7742 124.226 60.7004 137.724 62.8309"
              stroke="#00AE42"
              stroke-miterlimit="10"
            />
            <path
              d="M91.4525 56.4779C88.7015 55.9895 87.3199 55.7373 84.5609 55.2044C83.5493 55.0099 82.9219 54.0161 83.1597 52.9913L84.5645 46.9523C84.6804 46.4585 84.9793 46.027 85.4009 45.7453C85.8224 45.4636 86.3351 45.3525 86.8353 45.4346C89.604 45.9364 90.9839 46.1735 93.742 46.6308C94.7536 46.7987 95.3792 47.7614 95.1387 48.7907C94.5726 51.2169 94.2886 52.4407 93.7189 54.9025C93.4784 55.9486 92.4632 56.6573 91.4525 56.4779Z"
              fill="#8EBA72"
            />
            <path
              d="M92.1821 49.4898C91.9652 48.9755 91.6096 48.5316 91.1551 48.2079C90.7007 47.8842 90.1652 47.6934 89.6086 47.6568C88.0113 47.528 86.4548 48.6221 86.0066 50.1958C85.4804 52.0421 86.5834 53.8618 88.3973 54.1922C90.0017 54.4826 91.6115 53.4933 92.2007 51.9507"
              stroke="#F1F2F2"
              stroke-width="7.96"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M87.7734 49.457L88.3059 51.2137C88.345 51.342 88.4105 51.4607 88.498 51.5622C88.5856 51.6638 88.6933 51.746 88.8144 51.8036C88.9355 51.8612 89.0672 51.8928 89.2012 51.8966C89.3352 51.9004 89.4685 51.8762 89.5926 51.8256C91.1677 51.235 91.9558 50.9375 93.53 50.3309"
              stroke="#00AE42"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M96.0859 61.4961C104.586 62.989 113.11 64.3468 121.631 65.7198"
              stroke="#4A9385"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M95.3828 64.6094C108.857 67.0623 122.373 69.2789 135.848 71.7212"
              stroke="#00AE42"
              stroke-miterlimit="10"
            />
            <path
              d="M94.6719 67.7383C108.137 70.3315 121.649 72.6911 135.112 75.287"
              stroke="#00AE42"
              stroke-miterlimit="10"
            />
            <path
              d="M93.9609 70.8809C107.416 73.6268 120.917 76.141 134.37 78.8976"
              stroke="#00AE42"
              stroke-miterlimit="10"
            />
            <path
              d="M88.2179 70.5287C85.4723 69.9186 84.1013 69.6051 81.3619 68.9524C80.8753 68.8301 80.4566 68.5205 80.1968 68.091C79.9371 67.6614 79.8573 67.1466 79.9749 66.6585C80.5366 64.2438 80.817 63.0368 81.3787 60.6195C81.4925 60.1289 81.7912 59.7012 82.2125 59.4257C82.6338 59.1501 83.1451 59.0481 83.6398 59.1408C86.3908 59.7003 87.7689 59.9667 90.5225 60.4827C91.5324 60.6719 92.1553 61.6772 91.9131 62.7358C91.3416 65.2313 91.0558 66.4844 90.4834 68.9968C90.2447 70.0643 89.226 70.7525 88.2179 70.5287Z"
              fill="#8EBA72"
            />
            <path
              d="M88.962 63.3937C88.7533 62.8661 88.4032 62.4063 87.9502 62.0649C87.4973 61.7235 86.9591 61.5137 86.3948 61.4585C84.8046 61.2916 83.2534 62.3608 82.8044 63.9399C82.2781 65.7924 83.3705 67.676 85.1764 68.0792C86.7737 68.4345 88.3799 67.4824 88.9709 65.9176"
              stroke="#F1F2F2"
              stroke-width="7.96"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M84.5781 63.2383C84.7884 63.9567 84.8932 64.3173 85.1026 65.0402C85.1388 65.1707 85.2016 65.2923 85.2868 65.3975C85.3721 65.5026 85.4782 65.5891 85.5983 65.6514C85.7185 65.7137 85.8502 65.7506 85.9852 65.7597C86.1202 65.7689 86.2557 65.7501 86.3831 65.7045C87.9538 65.1521 88.74 64.8679 90.3134 64.2836"
              stroke="#00AE42"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M92.8359 75.8809C101.324 77.8036 109.84 79.5966 118.351 81.4136"
              stroke="#4A9385"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M92.125 79.043C105.552 82.2188 119.03 85.1708 132.448 88.3847"
              stroke="#00AE42"
              stroke-miterlimit="10"
            />
            <path
              d="M91.4219 82.207C104.838 85.5551 118.31 88.6803 131.71 92.0799"
              stroke="#00AE42"
              stroke-miterlimit="10"
            />
            <path
              d="M90.7109 85.3711C104.111 88.8897 117.576 92.1872 130.964 95.7697"
              stroke="#00AE42"
              stroke-miterlimit="10"
            />
            <path
              d="M84.9869 84.6987C82.2528 83.9296 80.8898 83.5371 78.1726 82.7253C77.176 82.4278 76.5628 81.3515 76.8015 80.3275L78.2054 74.2885C78.2584 74.0479 78.3591 73.8204 78.5016 73.6194C78.6441 73.4184 78.8254 73.2482 79.0349 73.1186C79.2443 72.9891 79.4776 72.903 79.7209 72.8653C79.9643 72.8277 80.2127 72.8394 80.4514 72.8996C83.1837 73.5967 84.553 73.9324 87.2959 74.5869C88.3022 74.8267 88.9216 75.8862 88.6785 76.9572C88.1052 79.4803 87.8177 80.7431 87.2453 83.2626C87.0013 84.339 85.9906 84.9811 84.9869 84.6987Z"
              fill="#8EBA72"
            />
            <path
              d="M85.7327 77.4766C85.5304 76.9326 85.1878 76.4519 84.7397 76.0835C84.2916 75.715 83.7539 75.4719 83.1814 75.3789C81.6001 75.132 80.0587 76.1391 79.6096 77.7173C79.0843 79.5671 80.1651 81.536 81.9595 82.0422C83.547 82.4863 85.1479 81.6097 85.738 80.0476"
              stroke="#F1F2F2"
              stroke-width="7.96"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M81.3672 77.1016C81.5748 77.8422 81.6787 78.2134 81.8854 78.9586C82.0443 79.5296 82.6166 79.8662 83.1571 79.7001C84.7207 79.2214 85.5033 78.9736 87.0723 78.4568"
              stroke="#00AE42"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M89.5859 90.3711C98.0624 92.7689 106.562 95.0779 115.054 97.4003"
              stroke="#4A9385"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M88.875 93.5156C102.253 97.4552 115.697 101.153 129.045 105.196"
              stroke="#00AE42"
              stroke-miterlimit="10"
            />
            <path
              d="M88.1641 96.6484C101.534 100.728 114.972 104.572 128.306 108.767"
              stroke="#00AE42"
              stroke-miterlimit="10"
            />
            <path
              d="M87.4609 99.7637C100.823 103.97 114.26 107.941 127.578 112.279"
              stroke="#00AE42"
              stroke-miterlimit="10"
            />
            <path
              d="M81.7558 98.798C79.0324 97.8913 77.6738 97.4304 74.9796 96.4828C73.9911 96.1355 73.3823 95.0183 73.6228 93.9961C74.1845 91.5814 74.4658 90.3745 75.0276 87.9572C75.0773 87.7192 75.1768 87.4945 75.3195 87.2978C75.4621 87.101 75.6447 86.9367 75.8553 86.8155C76.0658 86.6943 76.2996 86.6191 76.5413 86.5946C76.783 86.5702 77.0271 86.5973 77.2576 86.6739C79.9677 87.5291 81.329 87.9438 84.0595 88.7573C85.0614 89.0548 85.6781 90.1596 85.4359 91.2253C84.8635 93.7368 84.5769 94.9899 84.0063 97.4837C83.764 98.5414 82.755 99.1302 81.7558 98.798Z"
              fill="#8EBA72"
            />
            <path
              d="M82.5007 91.5864C82.0871 90.4745 81.1607 89.5855 79.9645 89.3298C78.3929 88.9941 76.8586 89.9292 76.4149 91.4994C75.8904 93.3412 76.9598 95.3821 78.7443 95.9815C80.323 96.5144 81.9177 95.7151 82.506 94.1743"
              stroke="#F1F2F2"
              stroke-width="7.96"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M78.1641 90.9707C78.3691 91.73 78.4711 92.1101 78.6761 92.8721C78.8341 93.4573 79.402 93.8259 79.9406 93.6874C81.4954 93.2886 82.2754 93.0808 83.8381 92.6501"
              stroke="#00AE42"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M86.3438 104.66C94.814 107.431 103.312 110.116 111.801 112.831"
              stroke="#4A9385"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M85.6406 107.721C98.9924 112.161 112.418 116.373 125.713 120.987"
              stroke="#00AE42"
              stroke-miterlimit="10"
            />
            <path
              d="M84.9375 110.752C98.2893 115.242 111.715 119.501 125.002 124.181"
              stroke="#00AE42"
              stroke-miterlimit="10"
            />
            <path
              d="M84.2422 113.754C97.5958 118.264 111.022 122.546 124.306 127.259"
              stroke="#00AE42"
              stroke-miterlimit="10"
            />
            <path
              d="M78.5422 112.64C75.8232 111.678 74.4708 111.189 71.7891 110.186C70.805 109.819 70.2042 108.686 70.4429 107.661L71.8468 101.622C72.0855 100.597 73.0758 100.06 74.0653 100.417C76.7568 101.394 78.1109 101.866 80.8326 102.799C81.8309 103.142 82.4468 104.266 82.2054 105.31C81.6375 107.77 81.3535 108.992 80.7856 111.416C80.546 112.447 79.5397 112.994 78.5422 112.64Z"
              fill="#8EBA72"
            />
            <path
              d="M79.279 105.549C78.8663 104.429 77.9479 103.506 76.7534 103.198C75.1898 102.797 73.6662 103.678 73.2198 105.241C72.6962 107.07 73.7584 109.138 75.5359 109.783C77.1084 110.354 78.6986 109.614 79.2843 108.115"
              stroke="#F1F2F2"
              stroke-width="7.96"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M74.9609 104.768C75.1642 105.535 75.2662 105.922 75.4703 106.685C75.6265 107.273 76.1927 107.662 76.7286 107.538C78.278 107.195 79.0545 107.018 80.6128 106.655"
              stroke="#00AE42"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M66.0234 105.754L74.5833 121.694C74.5833 121.694 72.962 112.045 75.1157 107.668C75.6178 106.619 76.2538 105.639 77.0077 104.754C77.8729 103.759 77.1426 102.217 75.8257 102.206C72.8733 102.178 68.8986 102.803 66.0234 105.754Z"
              fill="url(#paint15_linear_1671_2900)"
            />
            <path
              d="M36.8279 53.0684C36.8279 53.0684 37.4154 55.0666 37.3275 55.4777C37.2696 55.7393 37.1876 55.995 37.0826 56.2415C37.0826 56.2415 37.5032 56.8001 37.5032 57.2113C37.5032 57.6225 37.2982 61.03 36.8279 61.5744C36.3576 62.1188 34.4497 62.0593 34.1569 62.4119C33.864 62.7645 32.8941 64.3808 32.8941 64.3808L26.5234 62.1179L28.2556 56.9759L36.8279 53.0684Z"
              fill="url(#paint16_linear_1671_2900)"
            />
            <path
              d="M21.8941 129.798C21.8941 129.798 21.3341 134.719 21.4992 135.037C21.832 135.676 26.5725 136.309 27.6293 135.186C28.6862 134.062 32.3246 128.304 31.9918 127.548C31.659 126.792 28.9507 125.991 27.6959 127.548C26.4411 129.105 21.8941 129.798 21.8941 129.798Z"
              fill="url(#paint17_linear_1671_2900)"
            />
            <path
              d="M32.6617 134.099C32.6617 134.099 31.863 137.365 32.3218 137.762C32.7806 138.158 35.0302 139.811 36.8795 139.282C38.7288 138.753 42.8907 135.316 44.4109 134.39C45.931 133.465 45.5334 130.622 43.6175 131.016C41.7016 131.409 38.759 131.875 38.759 131.875L32.6617 134.099Z"
              fill="url(#paint18_linear_1671_2900)"
            />
            <path
              d="M20.675 93.329C20.675 93.329 19.4867 95.6424 19.9651 98.5029C20.4434 101.363 21.1542 102.379 21.1542 102.379L23.1189 114.355L21.4852 129.37C21.4852 129.37 21.6902 131.873 24.7268 131.634C25.451 131.586 26.1709 131.488 26.8815 131.341C27.2179 131.266 27.5242 131.092 27.7614 130.842C27.9985 130.591 28.1556 130.276 28.2126 129.936C28.7006 127.005 30.2314 117.902 30.9759 114.474C31.8695 110.363 30.9759 92.9648 30.9759 92.9648L20.675 93.329Z"
              fill="url(#paint19_linear_1671_2900)"
            />
            <path
              d="M25.6134 96.4427C25.6134 96.4427 25.467 101.58 26.9339 103.631C28.4008 105.681 29.0636 106.198 29.0636 106.198L32.5085 117.789C32.5085 117.789 31.7755 123.73 31.9956 127.545C32.2157 131.36 31.9956 133.12 31.9956 133.12C31.9956 133.12 32.3621 134.954 35.3677 134.733C36.7423 134.633 37.6412 134.333 38.2127 134.036C38.4833 133.893 38.711 133.68 38.8727 133.42C39.0343 133.16 39.1242 132.861 39.133 132.555C39.2457 129.468 39.7293 118.179 40.8652 115.148C42.1847 111.628 38.8481 93.7891 38.8481 93.7891C38.8481 93.7891 33.088 97.7615 25.6134 96.4427Z"
              fill="url(#paint20_linear_1671_2900)"
            />
            <path
              d="M17.5607 64.6289C17.5607 64.6289 15.3902 67.5543 14.1558 73.5328C12.9214 79.5114 12.1307 83.5638 12.6747 84.4039C13.2187 85.244 18.6984 91.9153 18.6984 91.9153C18.6984 91.9153 20.5744 91.8167 21.6117 90.0867C22.6491 88.3567 22.2036 88.3576 22.2036 88.3576L18.0559 81.9723L20.6729 74.7193L17.5607 64.6289Z"
              fill="url(#paint21_linear_1671_2900)"
            />
            <path
              d="M26.5759 61.5742C26.5759 61.5742 29.8513 62.3735 31.6341 63.1728C33.4169 63.972 33.818 64.5209 33.818 64.5209V66.234C33.818 66.234 37.0934 67.5821 38.4032 68.6745C39.713 69.7668 40.296 70.8947 40.296 70.8947L39.2409 93.4405C39.2409 93.4405 34.1454 98.285 26.9753 97.5567C19.8051 96.8285 20.4972 91.5462 20.4972 91.5462C20.4972 91.5462 20.3881 83.7524 19.0419 80.1787C17.6957 76.6051 15.7292 67.7588 17.1855 65.1363C18.6417 62.5138 23.5907 63.4241 24.7923 62.9508C25.5066 62.673 26.1259 62.1951 26.5759 61.5742Z"
              fill="white"
            />
            <path
              d="M40.2924 70.6163C38.5362 70.5275 33.6023 72.3925 35.1828 77.9297C36.7632 83.4669 39.6269 92.7545 41.6013 93.2483C43.5758 93.742 55.3773 91.8158 55.3773 91.8158C55.3773 91.8158 56.3153 90.9277 56.0198 89.0973C55.7243 87.267 54.8342 86.6764 54.8342 86.6764C54.8342 86.6764 45.6505 87.8132 45.0577 87.5645C44.465 87.3158 43.2297 70.7637 40.2924 70.6163Z"
              fill="url(#paint22_linear_1671_2900)"
            />
            <path
              d="M37.5045 49.647C38.3413 50.2393 38.0467 53.0217 37.028 53.7908C36.0092 54.5599 35.8575 54.5599 35.8575 54.5599C35.8575 54.5599 36.295 56.0643 35.5531 56.3884C34.8112 56.7126 35.4581 58.0447 34.9825 58.1779C34.5069 58.3111 33.9735 56.9026 33.213 56.979C32.4525 57.0554 32.0905 58.5971 32.8128 59.1495C33.5352 59.7019 33.1749 60.4257 31.4622 60.6157C29.7495 60.8058 27.523 61.0535 26.3818 59.7587C25.2406 58.4639 23.6948 55.9888 24.4216 54.2748C25.1483 52.5608 26.191 52.1434 26.191 52.1434C26.191 52.1434 26.6755 48.5067 31.0664 49.0591C35.4573 49.6115 36.697 49.0759 37.5045 49.647Z"
              fill="url(#paint23_linear_1671_2900)"
            />
            <path
              d="M47.9128 145.483C47.9128 145.483 47.3289 150.965 47.5002 151.317C47.8481 152.027 52.7935 152.733 53.8957 151.482C54.9978 150.231 58.795 143.816 58.4472 142.972C58.0993 142.129 55.2747 141.239 53.9649 142.972C52.6551 144.706 47.9128 145.483 47.9128 145.483Z"
              fill="url(#paint24_linear_1671_2900)"
            />
            <path
              d="M59.1455 150.272C59.1455 150.272 58.3078 153.914 58.7905 154.352C59.2733 154.791 61.616 156.636 63.5452 156.046C65.4744 155.456 69.8173 151.628 71.4022 150.597C72.9871 149.566 72.5736 146.399 70.5752 146.841C68.5768 147.283 65.5064 147.798 65.5064 147.798L59.1455 150.272Z"
              fill="url(#paint25_linear_1671_2900)"
            />
            <path
              d="M46.6425 104.858C46.6425 104.858 45.4001 107.433 45.8997 110.62C46.3993 113.808 47.1421 114.935 47.1421 114.935L49.192 128.275L47.4864 145.001C47.4864 145.001 47.7011 147.788 50.8683 147.523C51.6249 147.471 52.3766 147.361 53.1169 147.196C53.4776 147.103 53.8023 146.904 54.0498 146.625C54.2973 146.347 54.4563 146.001 54.5066 145.631C55.0169 142.367 56.6133 132.227 57.3906 128.408C58.3224 123.828 57.3906 104.447 57.3906 104.447L46.6425 104.858Z"
              fill="url(#paint26_linear_1671_2900)"
            />
            <path
              d="M51.7932 108.324C51.7932 108.324 51.6423 114.044 53.1713 116.331C54.7003 118.619 55.3898 119.192 55.3898 119.192L58.052 132.103C58.052 132.103 58.2153 138.722 58.4442 142.97C58.6732 147.219 58.4442 149.181 58.4442 149.181C58.4442 149.181 58.8267 151.223 61.9619 150.979C63.3959 150.866 64.3348 150.535 64.9302 150.202C65.5079 149.882 65.8664 149.247 65.8904 148.552C66.0075 145.113 65.5851 132.537 66.7698 129.161C68.1462 125.239 65.5931 105.367 65.5931 105.367C65.5931 105.367 59.5925 109.795 51.7932 108.324Z"
              fill="url(#paint27_linear_1671_2900)"
            />
            <path
              d="M62.3898 64.9141C62.3898 64.9141 63.0776 67.2542 62.9746 67.7355C62.9068 68.0422 62.8105 68.3419 62.6871 68.6307C62.6871 68.6307 63.1805 69.2843 63.1805 69.7657C63.1805 70.247 62.94 74.239 62.3898 74.8757C61.8396 75.5125 59.6043 75.4441 59.2609 75.8526C58.9174 76.2611 57.5429 79.2922 57.5429 79.2922L50.3203 75.5089L52.3489 69.4859L62.3898 64.9141Z"
              fill="url(#paint28_linear_1671_2900)"
            />
            <path
              d="M62.7832 66.3916C62.7832 66.3916 65.4143 65.5311 64.6334 63.1945C63.8525 60.858 56.4693 60.0178 53.6758 60.7345C50.8823 61.4512 50.514 62.7771 50.514 62.7771C50.514 62.7771 47.8997 61.7221 46.7993 65.5302C45.6989 69.3383 50.4075 72.102 50.5415 73.2858C50.6755 74.4696 54.2073 76.0105 55.5686 74.8071C56.9299 73.6038 57.6185 70.2095 57.2059 69.1997C56.7932 68.19 59.088 68.436 58.4438 71.1997C57.7995 73.9634 61.8931 71.9528 60.9933 69.5212C60.0935 67.0896 62.7832 66.3916 62.7832 66.3916Z"
              fill="url(#paint29_linear_1671_2900)"
            />
            <path
              d="M43.0435 76.375C43.0435 76.375 40.589 82.0587 39.5116 86.9112C38.4343 91.7637 46.6321 102.719 46.6321 102.719L43.0435 76.375Z"
              fill="#00230D"
            />
            <path
              d="M44.1415 74.9139C45.2224 73.7035 46.9812 73.3615 48.4632 74.0178C53.0111 76.0293 63.1709 80.8046 64.3121 83.8019C65.787 87.6775 65.5137 108.075 65.5137 108.075C65.5137 108.075 63.2393 112.559 55.9067 111.361C48.5741 110.163 46.2793 106.767 46.2793 106.767C46.2793 106.767 43.1095 89.76 41.8529 83.0878C41.135 79.2709 42.6871 76.548 44.1415 74.9139Z"
              fill="#00AE42"
            />
            <path
              d="M63.4235 82.2902C67.3946 79.3772 72.9808 75.2956 72.9808 75.2956L80.5494 64.2807C80.5494 64.2807 81.6347 62.9486 84.1248 64.8952C86.6148 66.8419 85.9546 67.6687 85.9546 67.6687L78.6264 81.3088L66.8542 91.4392C66.3946 91.8349 65.8441 92.1101 65.252 92.2402C64.6599 92.3704 64.0447 92.3513 63.4618 92.1847C62.8789 92.0181 62.3464 91.7093 61.9123 91.2859C61.4781 90.8625 61.1558 90.3378 60.9743 89.759C60.5519 88.414 60.5611 86.9704 61.0003 85.6309C61.4396 84.2914 62.287 83.1231 63.4235 82.2902Z"
              fill="#00230D"
            />
            <path
              d="M81.5679 63.8428C81.5679 63.8428 80.4444 60.7114 81.0115 59.1626C81.5785 57.6138 83.285 52.4975 84.1085 52.502C84.932 52.5064 84.7767 53.9637 84.3126 55.5055L83.6914 57.5632C83.6914 57.5632 87.4566 59.5764 88.0237 61.4343C88.5907 63.2922 85.8141 66.6634 85.8141 66.6634C85.8141 66.6634 84.1875 64.7034 81.5679 63.8428Z"
              fill="url(#paint30_linear_1671_2900)"
            />
            <defs>
              <linearGradient
                id="paint0_linear_1671_2900"
                x1="87.9692"
                y1="128.673"
                x2="126.118"
                y2="46.8744"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#00230D" />
                <stop offset="1" stop-color="#00AE42" />
              </linearGradient>
              <linearGradient
                id="paint1_linear_1671_2900"
                x1="99.8127"
                y1="27.1551"
                x2="117.643"
                y2="113.94"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#00230D" />
                <stop offset="1" stop-color="#00AE42" />
              </linearGradient>
              <linearGradient
                id="paint2_linear_1671_2900"
                x1="100.396"
                y1="29.9603"
                x2="117.279"
                y2="112.138"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#E1FFEC" />
                <stop offset="1" stop-color="#B4FCCE" />
              </linearGradient>
              <linearGradient
                id="paint3_linear_1671_2900"
                x1="143.836"
                y1="38.376"
                x2="147.303"
                y2="35.5908"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#B4FCCE" />
                <stop offset="1" stop-color="#F3FFF7" />
              </linearGradient>
              <linearGradient
                id="paint4_linear_1671_2900"
                x1="118.895"
                y1="19.9874"
                x2="117.124"
                y2="29.0661"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#00230D" />
                <stop offset="1" stop-color="#00AE42" />
              </linearGradient>
              <linearGradient
                id="paint5_linear_1671_2900"
                x1="125.619"
                y1="4.57527"
                x2="122.416"
                y2="15.2361"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#00230D" />
                <stop offset="1" stop-color="#00AE42" />
              </linearGradient>
              <linearGradient
                id="paint6_linear_1671_2900"
                x1="118.675"
                y1="19.5637"
                x2="116.904"
                y2="28.6415"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#8AD794" />
                <stop offset="1" stop-color="#B4FCCE" />
              </linearGradient>
              <linearGradient
                id="paint7_linear_1671_2900"
                x1="117.908"
                y1="22.1624"
                x2="124.244"
                y2="8.57483"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#00230D" />
                <stop offset="1" stop-color="#00AE42" />
              </linearGradient>
              <linearGradient
                id="paint8_linear_1671_2900"
                x1="118.092"
                y1="16.6203"
                x2="119.753"
                y2="2.52057"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#DBDCED" />
                <stop offset="1" stop-color="#989ACB" />
              </linearGradient>
              <linearGradient
                id="paint9_linear_1671_2900"
                x1="119.957"
                y1="2.65647"
                x2="116.25"
                y2="21.659"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#B4FCCE" />
                <stop offset="1" stop-color="#00AE42" />
              </linearGradient>
              <linearGradient
                id="paint10_linear_1671_2900"
                x1="103.352"
                y1="34.4656"
                x2="130.481"
                y2="28.3801"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#00AE42" />
                <stop offset="0.7" stop-color="#8EBA72" />
                <stop offset="1" stop-color="#B7FFDA" />
              </linearGradient>
              <linearGradient
                id="paint11_linear_1671_2900"
                x1="103.744"
                y1="22.6061"
                x2="105.867"
                y2="22.132"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#00230D" />
                <stop offset="1" stop-color="#00AE42" />
              </linearGradient>
              <linearGradient
                id="paint12_linear_1671_2900"
                x1="128.047"
                y1="23.1856"
                x2="130.167"
                y2="22.6992"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#00230D" />
                <stop offset="1" stop-color="#00AE42" />
              </linearGradient>
              <linearGradient
                id="paint13_linear_1671_2900"
                x1="137.158"
                y1="37.8902"
                x2="149.328"
                y2="37.8902"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#F3FFF7" />
                <stop offset="0.65" stop-color="#B4FCCE" />
              </linearGradient>
              <linearGradient
                id="paint14_linear_1671_2900"
                x1="71.9669"
                y1="114.051"
                x2="67.3773"
                y2="116.277"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#DBDCED" />
                <stop offset="1" stop-color="#FEFEFE" />
              </linearGradient>
              <linearGradient
                id="paint15_linear_1671_2900"
                x1="77.9571"
                y1="108.848"
                x2="71.509"
                y2="114.135"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#F3FFF7" />
                <stop offset="1" stop-color="#B4FCCE" />
              </linearGradient>
              <linearGradient
                id="paint16_linear_1671_2900"
                x1="36.5794"
                y1="58.3898"
                x2="29.329"
                y2="59.8015"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#FAC7B3" />
                <stop offset="1" stop-color="#F19470" />
              </linearGradient>
              <linearGradient
                id="paint17_linear_1671_2900"
                x1="29.6402"
                y1="130.935"
                x2="19.26"
                y2="132.436"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#00230D" />
                <stop offset="1" stop-color="#00AE42" />
              </linearGradient>
              <linearGradient
                id="paint18_linear_1671_2900"
                x1="42.2793"
                y1="134.866"
                x2="31.2069"
                y2="136.468"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#00230D" />
                <stop offset="1" stop-color="#00AE42" />
              </linearGradient>
              <linearGradient
                id="paint19_linear_1671_2900"
                x1="30.022"
                y1="111.444"
                x2="24.4507"
                y2="112.249"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#00230D" />
                <stop offset="1" stop-color="#00AE42" />
              </linearGradient>
              <linearGradient
                id="paint20_linear_1671_2900"
                x1="41.0382"
                y1="113.533"
                x2="32.3891"
                y2="115.217"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#00230D" />
                <stop offset="1" stop-color="#00AE42" />
              </linearGradient>
              <linearGradient
                id="paint21_linear_1671_2900"
                x1="21.8992"
                y1="78.3134"
                x2="14.3492"
                y2="78.2309"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#8F92C7" />
                <stop offset="1" stop-color="#CFD5EB" />
              </linearGradient>
              <linearGradient
                id="paint22_linear_1671_2900"
                x1="55.0525"
                y1="82.1223"
                x2="38.6933"
                y2="81.9423"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#8F92C7" />
                <stop offset="1" stop-color="#CFD5EB" />
              </linearGradient>
              <linearGradient
                id="paint23_linear_1671_2900"
                x1="36.8691"
                y1="53.5874"
                x2="28.1118"
                y2="55.2935"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#00230D" />
                <stop offset="1" stop-color="#00AE42" />
              </linearGradient>
              <linearGradient
                id="paint24_linear_1671_2900"
                x1="56.0113"
                y1="146.766"
                x2="45.4873"
                y2="148.289"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#00230D" />
                <stop offset="1" stop-color="#00AE42" />
              </linearGradient>
              <linearGradient
                id="paint25_linear_1671_2900"
                x1="69.1953"
                y1="151.146"
                x2="57.0464"
                y2="152.904"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#00230D" />
                <stop offset="1" stop-color="#00AE42" />
              </linearGradient>
              <linearGradient
                id="paint26_linear_1671_2900"
                x1="56.4979"
                y1="125.089"
                x2="50.5451"
                y2="125.95"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#00230D" />
                <stop offset="1" stop-color="#00AE42" />
              </linearGradient>
              <linearGradient
                id="paint27_linear_1671_2900"
                x1="68.0982"
                y1="127.367"
                x2="58.7516"
                y2="129.187"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#00230D" />
                <stop offset="1" stop-color="#00AE42" />
              </linearGradient>
              <linearGradient
                id="paint28_linear_1671_2900"
                x1="62.1999"
                y1="71.6697"
                x2="53.7079"
                y2="73.3235"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#FAC7B3" />
                <stop offset="1" stop-color="#F19470" />
              </linearGradient>
              <linearGradient
                id="paint29_linear_1671_2900"
                x1="64.7789"
                y1="67.8632"
                x2="46.6334"
                y2="67.8632"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#00230D" />
                <stop offset="1" stop-color="#00AE42" />
              </linearGradient>
              <linearGradient
                id="paint30_linear_1671_2900"
                x1="88.1027"
                y1="59.5818"
                x2="80.8526"
                y2="59.5818"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#FAC7B3" />
                <stop offset="1" stop-color="#F19470" />
              </linearGradient>
            </defs>
          </svg>
        </div>
      </div>
      <div class="col-12 text-center mt-5">
        <p class="title mb-0">Thank You!!!</p>
      </div>
      <div class="col-12 text-center margin-top">
        <p class="title">Your form has been submitted successfully</p>
      </div>
      <div class="col-12 text-center mt-1 hide-on-mobile">
        <p class="title-detail mb-0">
          Thank you for choosing Appselec as your service partner. Our service
          team will contact you soon.
        </p>
      </div>
      <div class="col-12 text-center hide-on-mobile">
        <p class="title-detail">
          In case of any query, please write to us:
          <span class="bluetext">service@appselec.com.au</span>
        </p>
      </div>

      <!-- mobile view -->
      <div class="col-12 hide-on-web mt-1 mobile-px-4">
        <p class="title-detail mb-0">
          Thank you for choosing Appselec as your service partner.<br />
          Our service team will contact you soon.
        </p>
      </div>
      <div class="col-12 hide-on-web mobile-px-4 mobile-pt-3">
        <p class="title-detail">
          In case of any query,<br />
          please write to us:
          <span class="bluetext">service@appselec.com.au</span>
        </p>
      </div>
      <!-- mobile view -->

      <div class="col-12 text-center mt-4 mobile-pt-5 mb-3">
        <span class="mr-4">
          <button class="another-btn" (click)="moveToForm()">
            Submit Another Form
          </button>
        </span>
        <!-- <span>
          <button class="submit-btn" (click)="moveForward()">Visit Site</button>
        </span> -->
      </div>
    </div>
  </div>
</section>
