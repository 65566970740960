import { Observable, throwError, of as _observableOf } from 'rxjs';
import { mergeMap } from "rxjs/operators";
import { ApiException } from './service-proxies';
import { HttpResponse, HttpResponseBase } from '@angular/common/http';

export class GenericService {

    constructor() { }

    protected processGeneric<T>(
        response: HttpResponseBase
    ): Observable<T> {
        const status = response.status;
        const responseBlob =
            response instanceof HttpResponse
                ? response.body
                : (response as any).error instanceof Blob
                    ? (response as any).error
                    : undefined;

        let _headers: any = {};
        if (response.headers) {
            for (let key of response.headers.keys()) {
                _headers[key] = response.headers.get(key);
            }
        }
        if (status === 200 || status === 500) {
            return this._blobToText(responseBlob).pipe(
                mergeMap((_responseText: string) => {
                    let resultData200 =
                        _responseText === ""
                            ? null
                            : JSON.parse(_responseText);
                    return _observableOf(resultData200);
                })
            );
        } else if (status !== 200 && status !== 204) {
            return this._blobToText(responseBlob).pipe(
                mergeMap((_responseText: string) => {
                    return this._throwException(
                        "An unexpected server error occurred.",
                        status,
                        _responseText,
                        _headers
                    );
                })
            );
        }
        return _observableOf(null as any);
    }
    private _throwException(
        message: string,
        status: number,
        response: string,
        headers: { [key: string]: any },
        result?: any
    ): Observable<any> {
        if (result !== null && result !== undefined) return throwError(() => result);
        else
            return throwError(
                () => new ApiException(message, status, response, headers, null)
            );
    }

    private _blobToText(blob: any): Observable<string> {
        return new Observable<string>((observer: any) => {
            if (!blob) {
                observer.next("");
                observer.complete();
            } else {
                let reader = new FileReader();
                reader.onload = (event) => {
                    observer.next((event.target as any).result);
                    observer.complete();
                };
                reader.readAsText(blob);
            }
        });
    }


}
