<!-- <div class="main">
  <h1>404 - Not Found</h1>
  <p>Sorry, the page you're looking for doesn't exist.</p>
  <a href="/app/home">Go back to the homepage</a>
</div>
 -->
<style>
  @media (min-width: 73.59px) and (min-height: 81.98px) and (max-width: 73.59px) and (max-height: 81.98px) {
    .logo {
      opacity: 0.8;
      width: 35px;
    }
  }
</style>

<div class="container-fluid">
  <div class="row">
    <div class="col-3 mt-3 ml-2">
      <img src="assets/img/logo.png" alt="Logo" class="logo ml-3" />
    </div>
  </div>
  <div class="row d-flex justify-content-center align-items-center flex-column mt-5">

    <ng-container *ngIf="!access_forbidden">
      <svg width="190" height="185" viewBox="0 0 190 185" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M35.1356 54.0768L35.8956 55.6165L37.5946 55.8637L36.3651 57.0622L36.6556 58.7547L35.1356 57.9557L33.6156 58.7547L33.9061 57.0622L32.6761 55.8637L34.3756 55.6165L35.1356 54.0768Z"
          fill="#EBEBEB" />
        <path
          d="M187.252 126.568L188.012 128.107L189.711 128.355L188.481 129.553L188.772 131.246L187.252 130.447L185.732 131.246L186.022 129.553L184.792 128.355L186.492 128.107L187.252 126.568Z"
          fill="#F5F5F5" />
        <path
          d="M157.122 171.257L157.882 172.796L159.581 173.043L158.351 174.242L158.642 175.935L157.122 175.136L155.602 175.935L155.892 174.242L154.662 173.043L156.362 172.796L157.122 171.257Z"
          fill="#F5F5F5" />
        <path
          d="M88.1943 26.7731L88.4763 27.3446L89.1071 27.4362C89.2645 27.4593 89.3275 27.6524 89.2133 27.7638L88.7569 28.2089L88.8646 28.8373C88.8914 28.9943 88.7269 29.1137 88.5863 29.0399L88.0219 28.7433L87.4574 29.0399C87.3164 29.1141 87.1519 28.9943 87.1791 28.8373L87.2868 28.2089L86.8304 27.7638C86.7167 27.6529 86.7792 27.4593 86.9366 27.4362L87.5674 27.3446L87.8494 26.7731C87.9203 26.6302 88.1238 26.6302 88.1943 26.7731Z"
          fill="#EBEBEB" />
        <path
          d="M10.5654 163.944L10.8474 164.516L11.4781 164.608C11.6356 164.631 11.6986 164.824 11.5843 164.935L11.128 165.38L11.2356 166.009C11.2624 166.166 11.0979 166.285 10.9574 166.211L10.3929 165.915L9.82842 166.211C9.68742 166.285 9.52292 166.166 9.55018 166.009L9.65781 165.38L9.20144 164.935C9.0877 164.824 9.15022 164.631 9.30767 164.608L9.9384 164.516L10.2204 163.944C10.2914 163.802 10.4949 163.802 10.5654 163.944Z"
          fill="#F5F5F5" />
        <path
          d="M23.245 20.7322L23.527 21.3037L24.1578 21.3953C24.3152 21.4184 24.3782 21.6115 24.264 21.7229L23.8076 22.168L23.9153 22.7964C23.9421 22.9534 23.7776 23.0728 23.6366 22.9985L23.0721 22.7019L22.5081 22.9985C22.3671 23.0728 22.2026 22.9529 22.2294 22.7964L22.337 22.168L21.8806 21.7229C21.7669 21.612 21.8294 21.4184 21.9869 21.3953L22.6176 21.3037L22.8996 20.7322C22.971 20.5893 23.1745 20.5893 23.245 20.7322Z"
          fill="#EBEBEB" />
        <path
          d="M22.4818 134.011C22.4818 134.355 22.2026 134.634 21.8581 134.634C21.5136 134.634 21.2344 134.355 21.2344 134.011C21.2344 133.666 21.5136 133.387 21.8581 133.387C22.2021 133.387 22.4818 133.666 22.4818 134.011Z"
          fill="#EBEBEB" />
        <path
          d="M111.566 55.7909C111.566 56.1354 111.286 56.4146 110.942 56.4146C110.597 56.4146 110.318 56.1354 110.318 55.7909C110.318 55.4464 110.597 55.1672 110.942 55.1672C111.286 55.1672 111.566 55.4464 111.566 55.7909Z"
          fill="#EBEBEB" />
        <path
          d="M79.1525 21.2487C79.1525 21.5932 78.8733 21.8724 78.5288 21.8724C78.1843 21.8724 77.9051 21.5932 77.9051 21.2487C77.9051 20.9042 78.1843 20.625 78.5288 20.625C78.8733 20.625 79.1525 20.9046 79.1525 21.2487Z"
          fill="#F5F5F5" />
        <path
          d="M80.1552 173.575C82.0114 173.136 83.1609 171.276 82.7226 169.42C82.2843 167.564 80.4242 166.414 78.5679 166.853C76.7117 167.291 75.5622 169.151 76.0006 171.007C76.4389 172.863 78.299 174.013 80.1552 173.575Z"
          fill="#F0F0F0" />
        <path
          d="M78.1608 168.213C77.4276 168.213 76.7461 168.448 76.1915 168.852C76.0082 169.275 75.9048 169.74 75.9048 170.229C75.9048 172.137 77.4511 173.679 79.3593 173.679C80.0032 173.679 80.6095 173.5 81.1265 173.191C81.1218 173.186 81.1218 173.186 81.1265 173.186C81.385 172.707 81.5307 172.161 81.5307 171.579C81.5307 169.722 80.022 168.213 78.1608 168.213Z"
          fill="#E6E6E6" />
        <path
          d="M51.4065 110.193H22.8047V97.2796L51.4065 63.2812H65.0906V98.0119H72.1829V110.193H65.0906V120.755H51.4065V110.193ZM51.4065 98.0119V80.228L36.2932 98.0119H51.4065Z"
          fill="#00AE42" />
        <path
          d="M77.0789 92.2685C77.0789 81.5271 79.012 74.0109 82.8801 69.7188C86.7477 65.4273 92.6382 63.2812 100.554 63.2812C104.357 63.2812 107.479 63.7508 109.921 64.6884C112.362 65.6265 114.354 66.8471 115.895 68.3502C117.437 69.8537 118.651 71.4339 119.538 73.0916C120.425 74.7488 121.138 76.6828 121.677 78.8932C122.73 83.1077 123.257 87.5022 123.257 92.0763C123.257 102.329 121.523 109.834 118.053 114.587C114.584 119.342 108.609 121.718 100.129 121.718C95.3746 121.718 91.5328 120.96 88.6037 119.444C85.6738 117.928 83.2707 115.705 81.3954 112.776C80.0324 110.694 78.9725 107.848 78.2153 104.238C77.4572 100.627 77.0789 96.6376 77.0789 92.2685ZM92.6519 92.307C92.6519 99.5027 93.2878 104.418 94.5596 107.052C95.8319 109.686 97.6757 111.003 100.091 111.003C101.684 111.003 103.066 110.444 104.235 109.326C105.404 108.208 106.265 106.442 106.818 104.026C107.37 101.611 107.647 97.8455 107.647 92.7314C107.647 85.2284 107.011 80.1848 105.739 77.6021C104.467 75.019 102.559 73.7279 100.014 73.7279C97.4186 73.7279 95.5428 75.0453 94.3862 77.6792C93.23 80.3131 92.6519 85.1894 92.6519 92.307Z"
          fill="#00AE42" />
        <path
          d="M156.717 110.193H128.115V97.2796L156.717 63.2812H170.401V98.0119H177.493V110.193H170.401V120.755H156.717V110.193ZM156.717 98.0119V80.228L141.604 98.0119H156.717Z"
          fill="#00AE42" />
        <g opacity="0.3">
          <path
            d="M76.4471 43.4407C76.4471 43.9248 76.0547 44.3172 75.5706 44.3172C75.0865 44.3172 74.694 43.9248 74.694 43.4407C74.694 42.9566 75.0865 42.5641 75.5706 42.5641C76.0547 42.5641 76.4471 42.9566 76.4471 43.4407Z"
            fill="#00AE42" />
          <path
            d="M16.2937 77.2027C16.6371 77.2027 16.9155 76.9243 16.9155 76.5808C16.9155 76.2374 16.6371 75.959 16.2937 75.959C15.9503 75.959 15.6719 76.2374 15.6719 76.5808C15.6719 76.9243 15.9503 77.2027 16.2937 77.2027Z"
            fill="#00AE42" />
          <path
            d="M118.795 167.806C119.286 167.806 119.685 167.408 119.685 166.917C119.685 166.425 119.286 166.027 118.795 166.027C118.304 166.027 117.905 166.425 117.905 166.917C117.905 167.408 118.304 167.806 118.795 167.806Z"
            fill="#00AE42" />
          <path
            d="M140.154 131.662C140.497 131.662 140.776 131.384 140.776 131.04C140.776 130.697 140.497 130.418 140.154 130.418C139.81 130.418 139.532 130.697 139.532 131.04C139.532 131.384 139.81 131.662 140.154 131.662Z"
            fill="#00AE42" />
          <path
            d="M181.361 19.9411C181.361 20.2847 181.083 20.563 180.739 20.563C180.396 20.563 180.117 20.2847 180.117 19.9411C180.117 19.5976 180.396 19.3193 180.739 19.3193C181.083 19.3193 181.361 19.5976 181.361 19.9411Z"
            fill="#00AE42" />
          <path
            d="M63.194 7.41282C63.194 7.75639 62.9158 8.03464 62.5722 8.03464C62.2287 8.03464 61.9504 7.75639 61.9504 7.41282C61.9504 7.06925 62.2287 6.79102 62.5722 6.79102C62.9158 6.79102 63.194 7.06925 63.194 7.41282Z"
            fill="#00AE42" />
          <path
            d="M112.516 39.9852C112.86 39.9852 113.138 39.7068 113.138 39.3634C113.138 39.02 112.86 38.7416 112.516 38.7416C112.173 38.7416 111.895 39.02 111.895 39.3634C111.895 39.7068 112.173 39.9852 112.516 39.9852Z"
            fill="#00AE42" />
        </g>
        <path
          d="M48.3792 39.9876C54.0664 39.9876 58.6769 35.3771 58.6769 29.6899C58.6769 24.0026 54.0664 19.3922 48.3792 19.3922C42.6919 19.3922 38.0815 24.0026 38.0815 29.6899C38.0815 35.3771 42.6919 39.9876 48.3792 39.9876Z"
          fill="#00AE42" />
        <path opacity="0.7"
          d="M48.3792 39.9876C54.0664 39.9876 58.6769 35.3771 58.6769 29.6899C58.6769 24.0026 54.0664 19.3922 48.3792 19.3922C42.6919 19.3922 38.0815 24.0026 38.0815 29.6899C38.0815 35.3771 42.6919 39.9876 48.3792 39.9876Z"
          fill="white" />
        <path opacity="0.2"
          d="M44.8302 21.9203C43.3868 21.9203 42.0168 22.2188 40.7689 22.7522C39.1004 24.5819 38.0824 27.0189 38.0824 29.6903C38.0824 35.3759 42.6917 39.9852 48.3773 39.9852C49.8254 39.9852 51.2053 39.6868 52.4583 39.1486C52.4583 39.1486 52.4583 39.1486 52.4583 39.1439C54.1169 37.3137 55.1298 34.8867 55.1298 32.2204C55.1303 26.5296 50.521 21.9203 44.8302 21.9203Z"
          fill="#00AE42" />
        <path opacity="0.2"
          d="M43.8065 24.643C43.8065 25.1628 43.385 25.5844 42.8651 25.5844C42.3453 25.5844 41.9237 25.1628 41.9237 24.643C41.9237 24.1232 42.3453 23.7016 42.8651 23.7016C43.385 23.7016 43.8065 24.1232 43.8065 24.643Z"
          fill="#00AE42" />
        <path opacity="0.2"
          d="M54.8797 23.7016C54.8797 24.2214 54.4582 24.643 53.9383 24.643C53.4185 24.643 52.9969 24.2214 52.9969 23.7016C52.9969 23.1818 53.4185 22.7602 53.9383 22.7602C54.4582 22.7607 54.8797 23.1818 54.8797 23.7016Z"
          fill="#00AE42" />
        <path opacity="0.2"
          d="M52.9969 30.4137C52.9969 31.2644 52.3074 31.9543 51.4563 31.9543C50.6056 31.9543 49.9156 31.2644 49.9156 30.4137C49.9156 29.563 50.6051 28.873 51.4563 28.873C52.307 28.873 52.9969 29.563 52.9969 30.4137Z"
          fill="#00AE42" />
        <path opacity="0.2"
          d="M48.1014 34.8058C48.1014 35.9432 47.1793 36.8649 46.0423 36.8649C44.9049 36.8649 43.9833 35.9428 43.9833 34.8058C43.9833 33.6684 44.9054 32.7468 46.0423 32.7468C47.1793 32.7468 48.1014 33.6689 48.1014 34.8058Z"
          fill="#00AE42" />
        <path
          d="M170.426 153.931C171.573 149.074 168.565 144.207 163.708 143.06C158.852 141.913 153.985 144.921 152.838 149.778C151.691 154.635 154.699 159.502 159.555 160.648C164.412 161.795 169.279 158.788 170.426 153.931Z"
          fill="#00AE42" />
        <path opacity="0.3"
          d="M170.426 153.931C171.573 149.074 168.565 144.207 163.708 143.06C158.852 141.913 153.985 144.921 152.838 149.778C151.691 154.635 154.699 159.502 159.555 160.648C164.412 161.795 169.279 158.788 170.426 153.931Z"
          fill="white" />
        <path opacity="0.4"
          d="M167.336 144.83C166.514 144.58 165.639 144.448 164.733 144.448C159.742 144.448 155.696 148.495 155.696 153.485C155.696 156.314 156.992 158.833 159.028 160.492C159.85 160.741 160.726 160.873 161.631 160.873C166.622 160.873 170.668 156.827 170.668 151.836C170.668 149.008 169.372 146.483 167.336 144.83Z"
          fill="#00AE42" />
        <path
          d="M153 154.516C143.349 159.057 150.548 162.557 164.144 157.671C176.733 153.147 181.519 147.439 170.279 149.208C170.859 151.609 155.092 157.752 153 154.516Z"
          fill="#00AE42" />
        <path opacity="0.2"
          d="M167.226 63.2798C154.588 71.8197 138.227 83.0809 133.711 110.19H132.747C137.142 83.4099 152.906 71.8526 165.549 63.2798H167.226Z"
          fill="black" />
        <path
          d="M101.86 148.085C93.8839 148.086 85.9696 144.484 82.1264 138.598C79.7515 134.96 76.994 127.341 85.2072 117.176L85.9385 117.767C80.1284 124.958 79.054 132.173 82.9136 138.084C87.6108 145.279 98.7037 148.897 108.167 146.322C117.862 143.684 123.748 135.25 124.316 123.185C126.26 81.9157 148.185 69.2032 164.194 59.9207C174.14 54.1538 181.326 49.9867 179.747 41.1968C179.528 39.9772 179.041 39.1344 178.26 38.6207C176.214 37.2728 172.219 38.2043 167.594 39.2825C158.52 41.3961 146.093 44.2913 138.69 31.6995L139.501 31.2229C146.554 43.2187 158.102 40.5289 167.381 38.3665C172.217 37.2403 176.394 36.267 178.777 37.8349C179.785 38.4985 180.405 39.5438 180.672 41.0304C182.368 50.469 174.556 54.9988 164.665 60.7338C148.843 69.9077 127.174 82.4717 125.255 123.229C124.667 135.729 118.528 144.476 108.413 147.229C106.282 147.809 104.067 148.085 101.86 148.085Z"
          fill="#00AE42" />
        <path opacity="0.2"
          d="M101.86 148.085C93.8839 148.086 85.9696 144.484 82.1264 138.598C79.7515 134.96 76.994 127.341 85.2072 117.176L85.9385 117.767C80.1284 124.958 79.054 132.173 82.9136 138.084C87.6108 145.279 98.7037 148.897 108.167 146.322C117.862 143.684 123.748 135.25 124.316 123.185C126.26 81.9157 148.185 69.2032 164.194 59.9207C174.14 54.1538 181.326 49.9867 179.747 41.1968C179.528 39.9772 179.041 39.1344 178.26 38.6207C176.214 37.2728 172.219 38.2043 167.594 39.2825C158.52 41.3961 146.093 44.2913 138.69 31.6995L139.501 31.2229C146.554 43.2187 158.102 40.5289 167.381 38.3665C172.217 37.2403 176.394 36.267 178.777 37.8349C179.785 38.4985 180.405 39.5438 180.672 41.0304C182.368 50.469 174.556 54.9988 164.665 60.7338C148.843 69.9077 127.174 82.4717 125.255 123.229C124.667 135.729 118.528 144.476 108.413 147.229C106.282 147.809 104.067 148.085 101.86 148.085Z"
          fill="white" />
        <path
          d="M128.997 20.5657C132.219 20.5069 135.381 21.5687 135.381 21.5687C135.381 21.5687 140.534 30.391 141.163 32.4102C140.946 34.403 137.584 37.6699 137.584 37.6699L128.997 20.5657Z"
          fill="#00AE42" />
        <path opacity="0.3"
          d="M128.997 20.5657C132.219 20.5069 135.381 21.5687 135.381 21.5687C135.381 21.5687 140.534 30.391 141.163 32.4102C140.946 34.403 137.584 37.6699 137.584 37.6699L128.997 20.5657Z"
          fill="white" />
        <path
          d="M144.31 63.4222C142.366 60.9556 140.458 58.443 138.877 55.7212C138.483 55.0411 138.109 54.3483 137.755 53.6462C137.553 53.2471 137.359 52.8448 137.17 52.4397C137.005 52.0858 136.641 51.5645 136.604 51.1782C136.04 45.2224 138.078 40.8382 136.121 36.0574L128.38 39.0827C128.38 39.0827 129.038 47.6001 130.541 52.6954C131.473 55.8566 133.584 58.5934 135.618 61.1257C136.254 61.9182 136.868 62.7285 137.503 63.5218C138.104 64.2729 138.777 64.9619 139.38 65.7092C140.295 66.8433 140.6 67.9257 139.904 69.2629C139.867 69.3343 139.828 69.4053 139.788 69.4758C139.59 69.824 140.514 70.2188 141.095 69.4856C142.055 68.2726 141.904 68.3473 142.722 67.3128C143.219 66.6849 143.789 66.0067 144.282 65.4173C144.763 64.8425 144.774 64.0106 144.31 63.4222Z"
          fill="#00AE42" />
        <path opacity="0.7"
          d="M144.31 63.4222C142.366 60.9556 140.458 58.443 138.877 55.7212C138.483 55.0411 138.109 54.3483 137.755 53.6462C137.553 53.2471 137.359 52.8448 137.17 52.4397C137.005 52.0858 136.641 51.5645 136.604 51.1782C136.04 45.2224 138.078 40.8382 136.121 36.0574L128.38 39.0827C128.38 39.0827 129.038 47.6001 130.541 52.6954C131.473 55.8566 133.584 58.5934 135.618 61.1257C136.254 61.9182 136.868 62.7285 137.503 63.5218C138.104 64.2729 138.777 64.9619 139.38 65.7092C140.295 66.8433 140.6 67.9257 139.904 69.2629C139.867 69.3343 139.828 69.4053 139.788 69.4758C139.59 69.824 140.514 70.2188 141.095 69.4856C142.055 68.2726 141.904 68.3473 142.722 67.3128C143.219 66.6849 143.789 66.0067 144.282 65.4173C144.763 64.8425 144.774 64.0106 144.31 63.4222Z"
          fill="white" />
        <path opacity="0.3"
          d="M142.418 60.9711C141.381 62.2237 139.274 63.8485 138.345 64.5069C138.548 64.7321 138.748 64.9619 138.948 65.1917C140.86 63.9429 142.372 62.3228 142.945 61.6648C142.768 61.4369 142.593 61.2038 142.418 60.9711Z"
          fill="#00AE42" />
        <path opacity="0.3"
          d="M144.308 63.4231C144.29 63.3987 144.272 63.3789 144.254 63.355C143.928 63.6948 143.698 64.2367 143.659 64.8449C143.628 65.265 143.699 65.6495 143.836 65.9498C143.993 65.7684 144.139 65.5889 144.282 65.4183C144.766 64.843 144.772 64.0115 144.308 63.4231Z"
          fill="#00AE42" />
        <path
          d="M127.157 26.2066C126.347 27.3844 125.392 28.5453 124.16 29.5878C123.549 30.1119 122.851 30.5861 122.084 30.9884C121.698 31.1858 121.291 31.3569 120.87 31.4984L120.553 31.6004L120.451 31.6323C120.376 31.6549 120.302 31.6751 120.226 31.6929C120.085 31.7249 119.942 31.7465 119.816 31.7569C119.307 31.7954 118.938 31.723 118.634 31.6474C118.034 31.4838 117.633 31.2652 117.254 31.0533C116.88 30.8375 116.552 30.6166 116.241 30.3887C115.629 29.9229 115.076 29.4553 114.56 28.9561C113.521 27.9644 112.6 26.9361 111.744 25.7907C111.354 25.2685 111.461 24.5292 111.984 24.1396C112.386 23.8383 112.919 23.8336 113.32 24.0869L113.356 24.11C114.465 24.814 115.584 25.5566 116.676 26.2212C117.227 26.5464 117.766 26.8736 118.295 27.1283C118.556 27.2599 118.811 27.3798 119.041 27.4648C119.265 27.5522 119.489 27.6021 119.547 27.5903C119.572 27.5889 119.543 27.5555 119.37 27.5616C119.328 27.564 119.269 27.5696 119.208 27.5828C119.174 27.5894 119.14 27.5983 119.106 27.6091C119.074 27.6199 119.12 27.6002 119.122 27.5974L119.278 27.5179C119.485 27.4127 119.683 27.2811 119.884 27.1471C120.287 26.875 120.67 26.5295 121.061 26.1507C121.834 25.3823 122.579 24.4258 123.365 23.4313L123.375 23.4177C124.18 22.3987 125.658 22.2253 126.677 23.0299C127.653 23.8002 127.849 25.1957 127.157 26.2066Z"
          fill="#00AE42" />
        <path opacity="0.7"
          d="M127.157 26.2066C126.347 27.3844 125.392 28.5453 124.16 29.5878C123.549 30.1119 122.851 30.5861 122.084 30.9884C121.698 31.1858 121.291 31.3569 120.87 31.4984L120.553 31.6004L120.451 31.6323C120.376 31.6549 120.302 31.6751 120.226 31.6929C120.085 31.7249 119.942 31.7465 119.816 31.7569C119.307 31.7954 118.938 31.723 118.634 31.6474C118.034 31.4838 117.633 31.2652 117.254 31.0533C116.88 30.8375 116.552 30.6166 116.241 30.3887C115.629 29.9229 115.076 29.4553 114.56 28.9561C113.521 27.9644 112.6 26.9361 111.744 25.7907C111.354 25.2685 111.461 24.5292 111.984 24.1396C112.386 23.8383 112.919 23.8336 113.32 24.0869L113.356 24.11C114.465 24.814 115.584 25.5566 116.676 26.2212C117.227 26.5464 117.766 26.8736 118.295 27.1283C118.556 27.2599 118.811 27.3798 119.041 27.4648C119.265 27.5522 119.489 27.6021 119.547 27.5903C119.572 27.5889 119.543 27.5555 119.37 27.5616C119.328 27.564 119.269 27.5696 119.208 27.5828C119.174 27.5894 119.14 27.5983 119.106 27.6091C119.074 27.6199 119.12 27.6002 119.122 27.5974L119.278 27.5179C119.485 27.4127 119.683 27.2811 119.884 27.1471C120.287 26.875 120.67 26.5295 121.061 26.1507C121.834 25.3823 122.579 24.4258 123.365 23.4313L123.375 23.4177C124.18 22.3987 125.658 22.2253 126.677 23.0299C127.653 23.8002 127.849 25.1957 127.157 26.2066Z"
          fill="white" />
        <path
          d="M109.975 23.1352L110.526 24.0592C110.526 24.0592 110.946 25.2887 111.788 25.5162L114.069 24.7774L113.955 24.5856C113.956 24.5861 113.958 24.5861 113.959 24.5861C113.666 24.141 113.698 23.2828 113.801 22.5688C113.903 21.8549 113.533 21.8295 113.259 22.0072C113.101 22.1096 112.989 22.4354 112.861 22.7864C112.749 22.6318 112.628 22.4828 112.493 22.3475L111.796 21.6533C111.495 21.3539 111.013 21.3403 110.696 21.6223L110.131 22.1242C109.843 22.3799 109.778 22.8043 109.975 23.1352Z"
          fill="#00AE42" />
        <path opacity="0.7"
          d="M109.975 23.1352L110.526 24.0592C110.526 24.0592 110.946 25.2887 111.788 25.5162L114.069 24.7774L113.955 24.5856C113.956 24.5861 113.958 24.5861 113.959 24.5861C113.666 24.141 113.698 23.2828 113.801 22.5688C113.903 21.8549 113.533 21.8295 113.259 22.0072C113.101 22.1096 112.989 22.4354 112.861 22.7864C112.749 22.6318 112.628 22.4828 112.493 22.3475L111.796 21.6533C111.495 21.3539 111.013 21.3403 110.696 21.6223L110.131 22.1242C109.843 22.3799 109.778 22.8043 109.975 23.1352Z"
          fill="white" />
        <path
          d="M131.308 19.7549C129.123 20.2851 126.121 21.532 124.094 22.7949C123.375 23.2423 123.016 24.0921 123.186 24.9217C124.09 29.3585 126.153 35.3016 128.381 39.0823L138.773 34.7832C138.844 32.9502 136.321 27.0188 133.751 21.2862C133.29 20.255 132.406 19.4889 131.308 19.7549Z"
          fill="#00AE42" />
        <path opacity="0.8"
          d="M131.308 19.7549C129.123 20.2851 126.121 21.532 124.094 22.7949C123.375 23.2423 123.016 24.0921 123.186 24.9217C124.09 29.3585 126.153 35.3016 128.381 39.0823L138.773 34.7832C138.844 32.9502 136.321 27.0188 133.751 21.2862C133.29 20.255 132.406 19.4889 131.308 19.7549Z"
          fill="white" />
        <path opacity="0.3"
          d="M135.36 24.9202L133.298 24.2289C133.759 25.4354 135.428 26.9647 136.602 27.8629C136.215 26.9201 135.796 25.9345 135.36 24.9202Z"
          fill="#00AE42" />
        <path
          d="M130.623 15.1001C129.765 13.464 127.909 12.6406 125.68 12.8267C123.801 12.9837 122.135 14.9022 122.333 15.939C122.53 16.9758 124.111 17.4148 124.398 17.7748L123.094 18.7195C122.446 19.19 122.319 20.1046 122.814 20.7344C123.363 21.431 124.085 22.1585 124.505 22.6722C128.107 22.5787 130.771 21.2044 131.731 19.8823C131.386 18.2002 131.476 16.7272 130.623 15.1001Z"
          fill="#00AE42" />
        <path opacity="0.8"
          d="M130.623 15.1001C129.765 13.464 127.909 12.6406 125.68 12.8267C123.801 12.9837 122.135 14.9022 122.333 15.939C122.53 16.9758 124.111 17.4148 124.398 17.7748L123.094 18.7195C122.446 19.19 122.319 20.1046 122.814 20.7344C123.363 21.431 124.085 22.1585 124.505 22.6722C128.107 22.5787 130.771 21.2044 131.731 19.8823C131.386 18.2002 131.476 16.7272 130.623 15.1001Z"
          fill="white" />
        <path
          d="M128.858 16.1134C129.573 17.9445 128.669 20.0087 126.838 20.7241C125.007 21.4394 122.943 20.5356 122.227 18.7045C121.512 16.8738 122.416 14.8091 124.247 14.0938C126.077 13.3784 128.142 14.2827 128.858 16.1134Z"
          fill="#263238" />
        <path
          d="M159.373 58.4698C159.321 56.924 159.254 57.0786 159.209 55.7607C159.182 54.9603 159.179 54.0739 159.173 53.3059C159.168 52.5562 158.639 51.9147 157.904 51.7657C157.281 51.6393 156.657 51.519 156.035 51.3864C155.224 51.2135 154.422 51.0273 153.625 50.7961C153.008 50.6166 152.395 50.4187 151.79 50.2006C151.141 49.967 150.502 49.7109 149.868 49.4392C149.127 49.1215 148.395 48.7831 147.669 48.4343C146.859 48.0452 146.054 47.6429 145.252 47.2354C142.159 42.1147 142.092 38.7406 138.773 34.7827L131.647 38.006C131.647 38.006 136.883 47.2955 140.448 51.2341C142.501 53.5009 145.721 54.5227 148.592 55.2644C150.664 55.7992 152.765 56.2086 154.868 56.595C155.686 56.7449 156.575 56.8032 157.278 57.2915C157.833 57.676 158.119 58.2761 158.283 58.9139C158.316 59.0441 158.344 59.1757 158.37 59.3083C158.445 59.7017 159.405 59.4046 159.373 58.4698Z"
          fill="#00AE42" />
        <path opacity="0.8"
          d="M159.373 58.4698C159.321 56.924 159.254 57.0786 159.209 55.7607C159.182 54.9603 159.179 54.0739 159.173 53.3059C159.168 52.5562 158.639 51.9147 157.904 51.7657C157.281 51.6393 156.657 51.519 156.035 51.3864C155.224 51.2135 154.422 51.0273 153.625 50.7961C153.008 50.6166 152.395 50.4187 151.79 50.2006C151.141 49.967 150.502 49.7109 149.868 49.4392C149.127 49.1215 148.395 48.7831 147.669 48.4343C146.859 48.0452 146.054 47.6429 145.252 47.2354C142.159 42.1147 142.092 38.7406 138.773 34.7827L131.647 38.006C131.647 38.006 136.883 47.2955 140.448 51.2341C142.501 53.5009 145.721 54.5227 148.592 55.2644C150.664 55.7992 152.765 56.2086 154.868 56.595C155.686 56.7449 156.575 56.8032 157.278 57.2915C157.833 57.676 158.119 58.2761 158.283 58.9139C158.316 59.0441 158.344 59.1757 158.37 59.3083C158.445 59.7017 159.405 59.4046 159.373 58.4698Z"
          fill="white" />
        <path opacity="0.3"
          d="M155.76 51.3277C155.479 51.2661 155.194 51.2031 154.911 51.1359C154.933 52.7588 154.172 55.5619 153.893 56.4112C154.189 56.4671 154.49 56.5244 154.786 56.5808C155.611 54.3507 155.741 51.9899 155.76 51.3277Z"
          fill="#00AE42" />
        <path opacity="0.3"
          d="M159.173 53.3064C159.167 52.5548 158.64 51.9147 157.906 51.7643C157.759 51.7342 157.606 51.7023 157.458 51.6769C157.489 52.1187 157.763 52.6314 158.223 53.0385C158.524 53.3101 158.865 53.4906 159.178 53.5757C159.173 53.4821 159.173 53.3943 159.173 53.3064Z"
          fill="#00AE42" />
        <path
          d="M128.192 16.1435C128.383 16.856 127.585 17.6052 126.947 16.989C126.429 16.4889 125.649 15.6514 125.012 15.2533C124.358 14.8439 125.23 14.1314 126.257 14.4077C127.217 14.6662 128.002 15.4305 128.192 16.1435Z"
          fill="white" />
        <path
          d="M128.247 38.8539C127.915 38.9761 128.521 39.5382 128.521 39.5382C128.521 39.5382 135.086 37.2883 139.093 34.9717C139.127 34.4871 138.775 34.23 138.775 34.23C138.775 34.23 133.9 36.7699 128.247 38.8539Z"
          fill="#00AE42" />
        <path opacity="0.5"
          d="M128.247 38.8539C127.915 38.9761 128.521 39.5382 128.521 39.5382C128.521 39.5382 135.086 37.2883 139.093 34.9717C139.127 34.4871 138.775 34.23 138.775 34.23C138.775 34.23 133.9 36.7699 128.247 38.8539Z"
          fill="white" />
        <path
          d="M133.087 19.4443C134.383 20.099 135.603 20.7922 136.822 21.5597C137.429 21.9419 138.033 22.3343 138.628 22.7578C139.228 23.1728 139.819 23.6103 140.413 24.1029L140.636 24.2853L140.914 24.5358C141.111 24.7097 141.256 24.8699 141.401 25.0297C141.553 25.1947 141.679 25.3536 141.802 25.512C141.931 25.6713 142.052 25.8297 142.162 25.9857C142.627 26.6099 143.005 27.2449 143.377 27.8747C144.1 29.1517 144.725 30.4268 145.241 31.7987C145.472 32.4106 145.162 33.0931 144.55 33.3238C144.09 33.4968 143.59 33.3652 143.272 33.0296L143.249 33.0042C142.305 32.0041 141.399 30.9489 140.488 29.969C139.597 28.9919 138.65 27.9889 137.886 27.5527C136.819 26.8872 135.631 26.2264 134.45 25.5754L130.895 23.6024L130.887 23.5977C129.751 22.9679 129.342 21.5372 129.972 20.4021C130.587 19.2953 131.967 18.8803 133.087 19.4443Z"
          fill="#00AE42" />
        <path opacity="0.8"
          d="M133.087 19.4443C134.383 20.099 135.603 20.7922 136.822 21.5597C137.429 21.9419 138.033 22.3343 138.628 22.7578C139.228 23.1728 139.819 23.6103 140.413 24.1029L140.636 24.2853L140.914 24.5358C141.111 24.7097 141.256 24.8699 141.401 25.0297C141.553 25.1947 141.679 25.3536 141.802 25.512C141.931 25.6713 142.052 25.8297 142.162 25.9857C142.627 26.6099 143.005 27.2449 143.377 27.8747C144.1 29.1517 144.725 30.4268 145.241 31.7987C145.472 32.4106 145.162 33.0931 144.55 33.3238C144.09 33.4968 143.59 33.3652 143.272 33.0296L143.249 33.0042C142.305 32.0041 141.399 30.9489 140.488 29.969C139.597 28.9919 138.65 27.9889 137.886 27.5527C136.819 26.8872 135.631 26.2264 134.45 25.5754L130.895 23.6024L130.887 23.5977C129.751 22.9679 129.342 21.5372 129.972 20.4021C130.587 19.2953 131.967 18.8803 133.087 19.4443Z"
          fill="white" />
        <path
          d="M146.375 34.0965L145.976 33.0978C145.976 33.0978 145.754 31.8175 144.958 31.4608L142.589 31.833L142.672 32.0403C142.671 32.0398 142.67 32.0393 142.668 32.0393C142.888 32.5244 142.722 33.3675 142.508 34.0561C142.295 34.7451 142.657 34.8283 142.955 34.6958C143.127 34.6192 143.288 34.3151 143.47 33.9884C143.557 34.159 143.652 34.3249 143.765 34.4796L144.344 35.2743C144.595 35.617 145.068 35.7063 145.426 35.4774L146.062 35.0699C146.387 34.8626 146.519 34.4542 146.375 34.0965Z"
          fill="#00AE42" />
        <path opacity="0.8"
          d="M146.375 34.0965L145.976 33.0978C145.976 33.0978 145.754 31.8175 144.958 31.4608L142.589 31.833L142.672 32.0403C142.671 32.0398 142.67 32.0393 142.668 32.0393C142.888 32.5244 142.722 33.3675 142.508 34.0561C142.295 34.7451 142.657 34.8283 142.955 34.6958C143.127 34.6192 143.288 34.3151 143.47 33.9884C143.557 34.159 143.652 34.3249 143.765 34.4796L144.344 35.2743C144.595 35.617 145.068 35.7063 145.426 35.4774L146.062 35.0699C146.387 34.8626 146.519 34.4542 146.375 34.0965Z"
          fill="white" />
        <path opacity="0.3"
          d="M131.102 24.8465L130.529 24.893L127.011 33.3925C127.218 33.4066 127.408 33.3892 127.583 33.3459C127.583 33.3459 132.171 31.6365 133.559 31.01C132.625 29.0736 131.102 24.8465 131.102 24.8465Z"
          fill="#00AE42" />
        <path
          d="M124.678 27.0766C124.874 28.6074 126.022 31.8607 127.011 33.3925C128.729 32.8003 131.599 31.6831 132.987 31.0566C132.052 29.1206 131.068 26.4529 130.529 24.8934C129.049 24.9917 125.824 26.2066 124.678 27.0766Z"
          fill="white" />
        <path opacity="0.3"
          d="M128.171 28.9148C127.979 29.5563 127.303 29.9206 126.662 29.7283C126.021 29.5361 125.656 28.8607 125.849 28.2196C126.041 27.5781 126.716 27.2138 127.357 27.4061C127.998 27.5978 128.363 28.2732 128.171 28.9148Z"
          fill="#00AE42" />
        <path opacity="0.6"
          d="M129.07 27.2956C129.007 27.5057 128.786 27.6251 128.576 27.5621C128.365 27.4991 128.246 27.2778 128.309 27.0677C128.372 26.8576 128.593 26.7382 128.803 26.8012C129.014 26.8637 129.133 27.0851 129.07 27.2956Z"
          fill="#00AE42" />
        <path opacity="0.6"
          d="M130.119 26.8872C130.056 27.0973 129.835 27.2167 129.625 27.1537C129.414 27.0907 129.295 26.8693 129.358 26.6592C129.421 26.4492 129.642 26.3298 129.853 26.3927C130.063 26.4557 130.182 26.6771 130.119 26.8872Z"
          fill="#00AE42" />
        <path opacity="0.5" d="M131.598 30.5475L127.252 32.2649L126.989 31.4105L131.335 29.6926L131.598 30.5475Z"
          fill="#00AE42" />
        <path opacity="0.2"
          d="M107.607 95.8701C107.513 99.4139 107.25 102.135 106.818 104.025C106.263 106.44 105.403 108.208 104.233 109.326C103.067 110.445 101.685 111.004 100.092 111.004C97.6763 111.004 95.8292 109.688 94.5602 107.051C94.3534 106.624 94.1654 106.135 93.9915 105.59C91.3078 107.897 88.8074 110.417 86.5091 113.124L84.0134 116.066C85.3623 117.434 86.8945 118.557 88.6053 119.445C91.5334 120.959 95.3733 121.72 100.13 121.72C100.966 121.72 101.775 121.697 102.56 121.65C105.436 117.391 107.819 112.814 109.657 108.01L116.636 89.8118L107.607 95.8701Z"
          fill="black" />
        <path
          d="M44.689 120.68C47.3196 123.311 52.9149 128.906 52.9149 128.906L75.9501 107.208C68.0231 104.194 52.5714 112.799 44.689 120.68Z"
          fill="#263238" />
        <path
          d="M73.375 149.366C70.7444 146.736 65.149 141.141 65.149 141.141L86.8475 118.106C89.8612 126.032 81.2573 141.484 73.375 149.366Z"
          fill="#263238" />
        <path
          d="M104.854 97.5288L113.827 80.2285L96.5267 89.2013C90.9685 92.0843 85.837 95.7235 81.278 100.015L51.7869 127.778L66.277 142.268L94.0399 112.777C98.332 108.218 101.971 103.087 104.854 97.5288Z"
          fill="#00AE42" />
        <path opacity="0.6"
          d="M104.854 97.5288L113.827 80.2285L96.5267 89.2013C90.9685 92.0843 85.837 95.7235 81.278 100.015L51.7869 127.778L66.277 142.268L94.0399 112.777C98.332 108.218 101.971 103.087 104.854 97.5288Z"
          fill="white" />
        <path
          d="M86.4297 113.689C89.7779 113.689 92.4922 110.974 92.4922 107.626C92.4922 104.278 89.7779 101.564 86.4297 101.564C83.0815 101.564 80.3672 104.278 80.3672 107.626C80.3672 110.974 83.0815 113.689 86.4297 113.689Z"
          fill="white" />
        <path
          d="M86.4292 111.52C88.5798 111.52 90.3232 109.777 90.3232 107.626C90.3232 105.476 88.5798 103.732 86.4292 103.732C84.2787 103.732 82.5353 105.476 82.5353 107.626C82.5353 109.777 84.2787 111.52 86.4292 111.52Z"
          fill="#00AE42" />
        <path opacity="0.3" d="M71.1773 137.064L56.9917 122.879L68.6059 111.945L82.1109 125.45L71.1773 137.064Z"
          fill="#00AE42" />
        <path
          d="M47.9 133.68C37.2757 134.544 33.7563 141.344 32.5559 150.383C31.944 154.993 31.6723 159.658 27.801 162.356C26.7265 163.105 27.26 164.775 28.5694 164.735C42.8287 164.289 49.4797 157.307 50.3149 154.383C50.0273 156.231 49.608 157.689 49.1437 158.836C48.6661 160.014 49.96 161.105 51.0373 160.43C55.0403 157.922 60.0608 153.307 60.3945 145.823C57.4349 141.664 47.9 133.68 47.9 133.68Z"
          fill="#00AE42" />
        <path opacity="0.2"
          d="M47.9 133.68C37.2757 134.544 33.7563 141.344 32.5559 150.383C31.944 154.993 31.6723 159.658 27.801 162.356C26.7265 163.105 27.26 164.775 28.5694 164.735C42.8287 164.289 49.4797 157.307 50.3149 154.383C50.0273 156.231 49.608 157.689 49.1437 158.836C48.6661 160.014 49.96 161.105 51.0373 160.43C55.0403 157.922 60.0608 153.307 60.3945 145.823C57.4349 141.664 47.9 133.68 47.9 133.68Z"
          fill="white" />
        <path d="M62.0264 149.041L45.0142 132.029L54.0772 130.069L63.9872 139.978L62.0264 149.041Z" fill="#00AE42" />
      </svg>


      <p class="main-text mt-4"> Uh-Oh! <br> We Can't Find That Page.</p>

      <p class="content mt-3 text-center">
        <span class="fw-700"> Oops,</span> it looks like the page you're searching for has wandered off. Please <br>
        check the URL or <a class="fw-700 fc-blue " href="/">navigate back to the homepage.</a>
      </p>

      <a class="btn-back mt-2" href="/">
        Back to Home
      </a>
    </ng-container>

    <ng-container *ngIf="access_forbidden">
      <svg width="174" height="157" viewBox="0 0 174 157" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.85773 83.7772C7.85773 83.7772 8.81481 113.445 33.5805 133.219C58.3463 152.994 92.6642 154.41 120.447 156.288C148.229 158.166 169.568 142.439 172.784 118.665C176 94.8909 154.771 87.912 143.253 60.8494C131.735 33.7867 132.76 24.8913 108.389 8.24645C84.0176 -8.39835 45.8936 1.82214 25.905 30.3371C5.9168 58.852 7.85773 83.7772 7.85773 83.7772Z" fill="#00AE42"/>
        <path opacity="0.7" d="M7.85773 83.7772C7.85773 83.7772 8.81481 113.445 33.5805 133.219C58.3463 152.994 92.6642 154.41 120.447 156.288C148.229 158.166 169.568 142.439 172.784 118.665C176 94.8909 154.771 87.912 143.253 60.8494C131.735 33.7867 132.76 24.8913 108.389 8.24645C84.0176 -8.39835 45.8936 1.82214 25.905 30.3371C5.9168 58.852 7.85773 83.7772 7.85773 83.7772Z" fill="white"/>
        <path d="M19.4481 45.0306V34.4146C19.4481 26.8997 25.6928 20.7861 33.3683 20.7861C41.0439 20.7861 47.2886 26.8997 47.2886 34.4146V45.0306H52.8382V34.4146C52.8382 23.9041 44.1042 15.353 33.3683 15.353C22.6324 15.353 13.8984 23.9041 13.8984 34.4146V45.0306H19.4481Z" stroke="#263238" stroke-width="1.1663" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M54.3474 45.1073H11.8364V80.2731H54.3474V45.1073Z" stroke="#263238" stroke-width="1.1663" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M37.5127 59.7106C37.5127 57.2081 35.4408 55.1798 32.8847 55.1798C30.3287 55.1798 28.2567 57.2085 28.2567 59.7106C28.2567 61.4344 29.2397 62.9329 30.6871 63.6988L29.9097 71.6858H35.8602L35.0827 63.6988C36.5293 62.9324 37.5127 61.4344 37.5127 59.7106Z" stroke="#263238" stroke-width="1.1663" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M80.8464 81.4111L70.2642 80.7148V80.5438L68.9631 80.6291L67.662 80.5438V80.7148L57.0798 81.4111L49.2728 145.599H57.0798L68.7027 94.422H69.2231L80.8464 145.599H88.6534L80.8464 81.4111Z" fill="#263238" stroke="#263238" stroke-width="1.1663" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M49.2728 145.599L47.3644 148.549L41.4662 151.151C41.4662 151.151 40.4255 151.671 40.4255 152.886C40.4255 154.1 40.4255 155.141 40.4255 155.141H57.2533C57.2533 155.141 57.4267 153.233 57.2533 151.498C57.0798 149.763 57.0798 145.6 57.0798 145.6H49.2728V145.599Z" fill="#666565" stroke="#263238" stroke-width="1.1663" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M40.4255 153.233C40.4255 154.291 40.4255 155.141 40.4255 155.141H57.2533C57.2533 155.141 57.3291 154.302 57.3299 153.233H40.4255Z" fill="#263238" stroke="#263238" stroke-width="1.1663" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M88.6212 145.599L90.5296 148.549L96.4278 151.151C96.4278 151.151 97.4685 151.671 97.4685 152.886C97.4685 154.1 97.4685 155.141 97.4685 155.141H80.6408C80.6408 155.141 80.4674 153.233 80.6408 151.498C80.8143 149.763 80.8143 145.6 80.8143 145.6H88.6212V145.599Z" fill="#666565" stroke="#263238" stroke-width="1.1663" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M97.4689 153.233C97.4689 154.291 97.4689 155.141 97.4689 155.141H80.6412C80.6412 155.141 80.5654 154.302 80.5646 153.233H97.4689Z" fill="#263238" stroke="#263238" stroke-width="1.1663" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M52.5977 51.5497C52.5977 51.5497 40.2521 68.92 40.2521 70.1923C40.2521 71.4646 42.2181 81.2953 45.3407 82.2202C48.4632 83.1456 50.1982 81.5264 50.7766 78.2881C51.3551 75.0498 49.3886 70.4234 49.3886 70.4234L55.1714 65.219L52.5977 51.5497Z" fill="#00AE42" stroke="#263238" stroke-width="1.1663" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M50.1982 77.8258C50.1982 77.8258 53.7834 77.2474 54.7088 77.4789C55.6341 77.7101 56.328 79.9077 56.328 79.9077C56.328 79.9077 56.6749 83.146 56.0964 83.8398C55.518 84.5336 52.7423 85.3432 51.5858 85.5747C50.4293 85.8059 48.0006 83.146 47.0753 81.5268C46.1503 79.9077 47.7695 77.132 50.1982 77.8258Z" fill="#CCEFD9" stroke="#263238" stroke-width="1.1663" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M55.197 50.762L52.5977 51.5497L56.6938 78.8838H81.0343L84.737 51.7075L81.4282 50.4472L69.2186 47.7688L55.197 50.762Z" fill="#00AE42" stroke="#263238" stroke-width="1.1663" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M82.3737 59.8997L81.822 63.8384L77.5686 64.8627L73.236 63.1294L73.5511 59.5845L82.3737 59.8997Z" fill="#263238" stroke="#263238" stroke-width="1.1663" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M77.0959 63.0902C77.0959 62.677 77.4309 62.342 77.8441 62.342C78.2574 62.342 78.5923 62.677 78.5923 63.0902C78.5923 63.5035 78.2574 63.8384 77.8441 63.8384C77.4309 63.8384 77.0959 63.5035 77.0959 63.0902Z" fill="#666565" stroke="#263238" stroke-width="1.1663" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M66.6188 52.81L68.9034 55.4884L65.9889 74.0791L69.0612 78.9629L72.6847 73.8426L69.6124 55.5671L72.2117 53.0465L69.4551 49.5016L66.6188 52.81Z" fill="#263238" stroke="#263238" stroke-width="1.1663" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M64.4134 48.1626L53.9366 50.762L54.2518 52.0223L64.4921 49.1865L64.4134 48.1626Z" fill="#263238" stroke="#263238" stroke-width="1.1663" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M72.9995 47.8475L83.4766 50.4472L83.1614 51.7075L72.9208 48.8717L72.9995 47.8475Z" fill="#263238" stroke="#263238" stroke-width="1.1663" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M83.4766 50.4472C83.4766 50.4472 87.4392 51.3412 89.8675 52.7288C92.2963 54.1169 92.8747 57.2394 91.4867 60.7089C90.0987 64.1787 85.8196 63.7161 83.8536 62.7907C81.8875 61.8654 81.3091 58.3959 81.656 56.8925" fill="#00AE42"/>
        <path d="M83.4766 50.4472C83.4766 50.4472 87.4392 51.3412 89.8675 52.7288C92.2963 54.1169 92.8747 57.2394 91.4867 60.7089C90.0987 64.1787 85.8196 63.7161 83.8536 62.7907C81.8875 61.8654 81.3091 58.3959 81.656 56.8925" stroke="#263238" stroke-width="1.1663" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M64.886 46.8236L63.7043 47.611L64.0195 55.5671L69.1399 51.9436L74.4963 55.6462L74.9688 47.7688L73.5511 46.5872L64.886 46.8236Z" fill="#00AE42" stroke="#263238" stroke-width="1.1663" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M75.2341 37.3608C75.2341 37.3608 78.1433 36.653 78.6937 33.7439C79.2441 30.8348 73.9759 27.2965 70.3594 26.6674C66.7424 26.0383 61.3959 29.8126 60.5311 32.407C59.6663 35.0017 63.0472 37.5965 66.6641 38.1469C70.2807 38.6974 75.2341 37.3608 75.2341 37.3608Z" fill="#263238" stroke="#263238" stroke-width="1.1663" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M64.3837 37.8326C64.3837 37.8326 62.8111 35.3952 62.4181 37.5182C62.025 39.6413 63.6759 40.5847 64.3837 40.506C65.0915 40.4274 64.3837 37.8326 64.3837 37.8326Z" fill="#CCEFD9" stroke="#263238" stroke-width="1.1663" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M74.6055 37.8326C74.6055 37.8326 76.1781 35.3952 76.5711 37.5182C76.9642 39.6413 75.3133 40.5847 74.6055 40.506C73.8977 40.4274 74.6055 37.8326 74.6055 37.8326Z" fill="#CCEFD9" stroke="#263238" stroke-width="1.1663" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M64.7285 42.6485V47.8475L69.1398 51.9436L74.1023 47.6901V42.8058L64.7285 42.6485Z" fill="#CCEFD9" stroke="#263238" stroke-width="1.1663" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M64.0694 36.26C64.0694 36.26 63.8333 41.1348 64.2268 42.7074C64.6198 44.28 67.2146 47.5821 69.2589 48.29C71.3033 48.9978 74.1337 44.7517 74.7628 43.3365C75.392 41.9213 75.1559 35.9456 75.1559 35.9456C75.1559 35.9456 74.2911 33.9013 72.5611 33.2722C70.8311 32.6434 65.4846 31.5426 64.0694 36.26Z" fill="#CCEFD9" stroke="#263238" stroke-width="1.1663" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M67.3403 38.8482C67.3403 39.2198 67.1701 39.5205 66.96 39.5205C66.7499 39.5205 66.5797 39.2194 66.5797 38.8482C66.5797 38.4769 66.7499 38.1758 66.96 38.1758C67.1701 38.1758 67.3403 38.4765 67.3403 38.8482Z" fill="#263238"/>
        <path d="M72.1647 38.8482C72.1647 39.2198 71.9946 39.5205 71.7845 39.5205C71.5747 39.5205 71.4042 39.2194 71.4042 38.8482C71.4042 38.4769 71.5743 38.1758 71.7845 38.1758C71.9946 38.1758 72.1647 38.4765 72.1647 38.8482Z" fill="#263238"/>
        <path d="M68.1597 40.7269C67.8053 40.7269 67.5182 40.9568 67.5182 41.2402C67.5182 41.5237 67.8053 41.7536 68.1597 41.7536C68.3385 41.7536 68.4996 41.6951 68.6162 41.6007C68.8193 41.8772 69.1909 42.0646 69.621 42.0646C70.0343 42.0646 70.3952 41.8924 70.6032 41.6337C70.7145 41.7074 70.855 41.7536 71.0107 41.7536C71.365 41.7536 71.6522 41.5237 71.6522 41.2402C71.6522 40.9568 71.365 40.7269 71.0107 40.7269" fill="#CCEFD9"/>
        <path d="M68.1597 40.7269C67.8053 40.7269 67.5182 40.9568 67.5182 41.2402C67.5182 41.5237 67.8053 41.7536 68.1597 41.7536C68.3385 41.7536 68.4996 41.6951 68.6162 41.6007C68.8193 41.8772 69.1909 42.0646 69.621 42.0646C70.0343 42.0646 70.3952 41.8924 70.6032 41.6337C70.7145 41.7074 70.855 41.7536 71.0107 41.7536C71.365 41.7536 71.6522 41.5237 71.6522 41.2402C71.6522 40.9568 71.365 40.7269 71.0107 40.7269" stroke="#263238" stroke-width="1.1663" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M68.786 43.9541H70.4291" stroke="#263238" stroke-width="1.1663" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M63.8898 37.4445C63.8898 37.4445 63.9483 39.0233 64.4744 38.6141C65.0005 38.2046 65.176 35.5147 64.7084 35.1641C64.2408 34.813 63.8898 36.6258 63.8898 37.4445Z" fill="#263238" stroke="#263238" stroke-width="1.1663" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M74.0389 37.4445C74.0389 37.4445 74.0974 39.0233 74.6236 38.6141C75.1497 38.2046 75.3252 35.5147 74.8576 35.1641C74.39 34.813 74.0389 36.6258 74.0389 37.4445Z" fill="#263238" stroke="#263238" stroke-width="1.1663" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M63.8897 36.1582C63.8897 36.1582 64.0673 36.3003 64.064 36.3712C64.825 36.784 66.5768 37.5425 69.4109 37.6958C71.8438 37.8272 73.9285 36.9624 75.1666 36.2839C75.16 36.0717 75.1555 35.9468 75.1555 35.946C75.0838 34.1023 73.7155 32.8766 72.021 32.4049C70.4014 31.9537 68.5416 32.0304 66.9781 32.6562C66.1689 32.98 65.4327 33.4687 64.8921 34.1592C64.7125 34.3883 63.6026 35.9279 63.8897 36.1582Z" fill="#666565" stroke="#263238" stroke-width="1.1663" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M64.0693 36.26C64.0693 36.26 66.035 36.8104 69.4159 36.653C72.7967 36.4956 75.1505 36.2604 75.1505 36.2604C75.1505 36.2604 72.8449 38.601 69.4641 38.7583C65.8096 38.9281 64.0693 36.26 64.0693 36.26Z" fill="#666565" stroke="#263238" stroke-width="1.1663" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M55.4335 59.8997L55.9847 63.8384L60.2386 64.8627L64.5708 63.1294L64.256 59.5845L55.4335 59.8997Z" fill="#263238" stroke="#263238" stroke-width="1.1663" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M60.7112 63.0902C60.7112 62.677 60.3763 62.342 59.963 62.342C59.5498 62.342 59.2148 62.677 59.2148 63.0902C59.2148 63.5035 59.5498 63.8384 59.963 63.8384C60.3763 63.8384 60.7112 63.5035 60.7112 63.0902Z" fill="#666565" stroke="#263238" stroke-width="1.1663" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M81.3091 78.6354H56.3279V82.5675H81.3091V78.6354Z" fill="#666565" stroke="#263238" stroke-width="1.1663" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M61.1278 83.724H55.6918C55.0849 83.724 54.593 83.2321 54.593 82.6252V79.9649C54.593 79.3581 55.0849 78.8661 55.6918 78.8661H61.1278C61.7346 78.8661 62.2266 79.3581 62.2266 79.9649V82.6252C62.2261 83.2321 61.7342 83.724 61.1278 83.724Z" fill="#666565" stroke="#263238" stroke-width="1.1663" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M81.0203 83.724H75.5844C74.9775 83.724 74.4856 83.2321 74.4856 82.6252V79.9649C74.4856 79.3581 74.9775 78.8661 75.5844 78.8661H81.0203C81.6272 78.8661 82.1191 79.3581 82.1191 79.9649V82.6252C82.1187 83.2321 81.6268 83.724 81.0203 83.724Z" fill="#666565" stroke="#263238" stroke-width="1.1663" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M71.9414 78.8665H66.5055V82.336H71.9414V78.8665Z" fill="white" stroke="#263238" stroke-width="1.1663" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M49.3886 70.4238C49.3886 70.4238 45.9187 70.1923 44.6469 67.9951" stroke="#263238" stroke-width="1.1663" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M87.9592 60.2462C91.1209 60.2462 93.684 57.5537 93.684 54.2322C93.684 50.9108 91.1209 48.2183 87.9592 48.2183C84.7976 48.2183 82.2345 50.9108 82.2345 54.2322C82.2345 57.5537 84.7976 60.2462 87.9592 60.2462Z" fill="#00AE42" stroke="#263238" stroke-width="1.1663" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M87.9592 55.2029C90.4349 55.2029 92.4418 53.4046 92.4418 51.1863C92.4418 48.968 90.4349 47.1697 87.9592 47.1697C85.4836 47.1697 83.4767 48.968 83.4767 51.1863C83.4767 53.4046 85.4836 55.2029 87.9592 55.2029Z" fill="#00AE42" stroke="#263238" stroke-width="1.1663" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M87.9591 52.2493C90.4348 52.2493 92.4417 50.451 92.4417 48.2327C92.4417 46.0144 90.4348 44.2161 87.9591 44.2161C85.4835 44.2161 83.4766 46.0144 83.4766 48.2327C83.4766 50.451 85.4835 52.2493 87.9591 52.2493Z" fill="#CCEFD9" stroke="#263238" stroke-width="1.1663" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M80.7578 39.572C80.7578 39.572 80.8336 45.4974 81.1377 47.2447C81.4417 48.992 82.6571 51.2708 84.3282 51.2708C85.9993 51.2708 87.5945 50.6631 87.5945 50.6631C87.5945 50.6631 89.5697 51.7265 91.469 51.4228C93.3683 51.1188 93.0643 48.4601 93.0643 47.3205C93.0643 46.1809 92.8364 37.5969 92.8364 37.5969C92.8364 37.5969 92.9884 36.7614 92.2287 36.6094C91.469 36.4573 91.0891 36.4573 91.0891 36.4573V34.6342C91.0891 34.6342 90.7851 34.0265 89.7975 34.0265C88.8099 34.0265 88.7341 34.2544 88.7341 34.2544V32.5833C88.7341 32.5833 87.913 31.2525 86.8348 31.8998C86.0751 32.3555 86.0751 33.1152 86.0751 33.1152L85.9993 34.7104C85.9993 34.7104 84.1762 34.0265 84.0242 35.0903C83.8721 36.1537 83.5685 42.6872 83.5685 42.6872C83.5685 42.6872 83.3406 40.6358 82.9608 39.6487C82.5809 38.6603 80.7578 38.6603 80.7578 39.572Z" fill="#CCEFD9" stroke="#263238" stroke-width="1.1663" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M85.9996 34.71L86.1517 41.6992" stroke="#263238" stroke-width="1.1663" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M88.7345 34.2544L88.8103 41.775" stroke="#263238" stroke-width="1.1663" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M91.0895 36.4573L90.9375 41.6992" stroke="#263238" stroke-width="1.1663" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M85.0121 41.623L91.925 41.927" stroke="#263238" stroke-width="1.1663" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M83.5685 42.6868C83.5685 42.6868 83.5615 43.6962 84.3117 44.165C85.0624 44.6339 86.7508 45.8534 86.9382 47.8236" stroke="#263238" stroke-width="1.1663" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M120.703 80.1318V81.0427H118.636V84.9316H117.635V75.9475H121.203V76.8584H118.636V80.1318H120.703Z" fill="#263238"/>
        <path d="M121.922 78.1163C121.922 76.7435 122.602 75.8577 123.975 75.8577C125.361 75.8577 126.042 76.7435 126.042 78.1163V82.7628C126.042 84.1232 125.361 85.0214 123.975 85.0214C122.602 85.0214 121.922 84.1232 121.922 82.7628V78.1163ZM122.91 82.8139C122.91 83.6354 123.256 84.1101 123.975 84.1101C124.707 84.1101 125.04 83.6354 125.04 82.8139V78.0648C125.04 77.2564 124.707 76.7686 123.975 76.7686C123.256 76.7686 122.91 77.2564 122.91 78.0648V82.8139Z" fill="#263238"/>
        <path d="M128.994 75.9475C130.405 75.9475 130.995 76.6665 130.995 78.0013V78.7326C130.995 79.721 130.649 80.337 129.879 80.5804C130.701 80.8243 131.009 81.4922 131.009 82.4542V83.8534C131.009 84.251 131.021 84.6234 131.189 84.9312H130.175C130.059 84.6745 130.008 84.4434 130.008 83.8402V82.4287C130.008 81.4143 129.533 81.0938 128.699 81.0938H127.993V84.9312H126.992V75.9471H128.994V75.9475ZM128.737 80.1829C129.532 80.1829 130.007 79.9258 130.007 78.9506V78.0907C130.007 77.2824 129.712 76.8584 128.968 76.8584H127.992V80.1829H128.737Z" fill="#263238"/>
        <path d="M134.063 75.9475C135.461 75.9475 136.013 76.6149 136.013 77.9371V78.3606C136.013 79.3107 135.744 79.9006 134.96 80.1441C135.833 80.388 136.167 81.081 136.167 82.0694V82.8267C136.167 84.1747 135.512 84.9316 134.113 84.9316H132.047V75.9475H134.063ZM133.818 79.7589C134.614 79.7589 135.025 79.5022 135.025 78.5654V78.0392C135.025 77.2432 134.755 76.858 134.036 76.858H133.048V79.7585H133.818V79.7589ZM134.114 84.0206C134.846 84.0206 135.179 83.6354 135.179 82.8011V82.0051C135.179 81.0044 134.781 80.6707 133.921 80.6707H133.048V84.0206H134.114Z" fill="#263238"/>
        <path d="M138.079 75.9475V84.9316H137.079V75.9475H138.079Z" fill="#263238"/>
        <path d="M141.211 75.9475C142.61 75.9475 143.252 76.795 143.252 78.1805V82.7113C143.252 84.0841 142.61 84.9316 141.211 84.9316H139.106V75.9475H141.211ZM141.198 84.0206C141.917 84.0206 142.264 83.5712 142.264 82.75V78.1295C142.264 77.3079 141.917 76.8588 141.186 76.8588H140.107V84.0211L141.198 84.0206Z" fill="#263238"/>
        <path d="M146.293 75.9475C147.692 75.9475 148.334 76.795 148.334 78.1805V82.7113C148.334 84.0841 147.692 84.9316 146.293 84.9316H144.188V75.9475H146.293ZM146.28 84.0206C146.999 84.0206 147.345 83.5712 147.345 82.75V78.1295C147.345 77.3079 146.999 76.8588 146.267 76.8588H145.189V84.0211H146.28V84.0206Z" fill="#263238"/>
        <path d="M152.466 79.9258V80.8244H150.271V84.0202H152.967V84.9312H149.271V75.9471H152.967V76.858H150.271V79.9254H152.466V79.9258Z" fill="#263238"/>
        <path d="M154.776 84.9316H153.878V75.9475H155.149L157.228 82.4415V75.9475H158.114V84.9316H157.073L154.776 77.6676V84.9316H154.776Z" fill="#263238"/>
        <path d="M111.297 56.8015L120.461 35.127H125.96V56.8015H128.343V61.3837H125.96V67.2036H121.011V61.3837H111.297L111.297 56.8015ZM121.011 56.8015V44.7501L115.924 56.8015H121.011Z" fill="#263238"/>
        <path d="M130.221 42.8256C130.221 37.6937 132.924 34.7611 137.874 34.7611C142.822 34.7611 145.526 37.6937 145.526 42.8256V59.5054C145.526 64.6373 142.822 67.5699 137.874 67.5699C132.924 67.5699 130.221 64.6373 130.221 59.5054V42.8256ZM135.262 59.8264C135.262 62.1175 136.27 62.9881 137.874 62.9881C139.478 62.9881 140.486 62.1175 140.486 59.8264V42.5051C140.486 40.2139 139.478 39.3434 137.874 39.3434C136.27 39.3434 135.262 40.2139 135.262 42.5051V59.8264Z" fill="#263238"/>
        <path d="M158.494 43.0094C158.494 40.1225 157.486 39.3434 155.882 39.3434C154.278 39.3434 153.27 40.2139 153.27 42.5051V44.5671H148.505V42.8256C148.505 37.6937 151.07 34.7611 156.02 34.7611C160.968 34.7611 163.534 37.6937 163.534 42.8256V43.6504C163.534 47.0877 162.434 49.2413 160.006 50.249C162.526 51.3491 163.534 53.7312 163.534 56.9848V59.5054C163.534 64.6373 160.968 67.5699 156.02 67.5699C151.07 67.5699 148.505 64.6373 148.505 59.5054V56.8472H153.27V59.826C153.27 62.1171 154.278 62.9876 155.882 62.9876C157.486 62.9876 158.494 62.2086 158.494 59.3678V56.8472C158.494 53.8688 157.486 52.7688 155.195 52.7688H153.499V48.1865H155.469C157.349 48.1865 158.494 47.3617 158.494 44.7954V43.0094H158.494Z" fill="#263238"/>
        <path d="M20.7909 154.96H14.917L22.7487 100.362H28.6226L20.7909 154.96Z" fill="#263238" stroke="#263238" stroke-width="1.1663" stroke-miterlimit="10"/>
        <path d="M32.5386 154.96H38.4121L30.5804 100.362H24.7069L32.5386 154.96Z" fill="white" stroke="#263238" stroke-width="1.1663" stroke-miterlimit="10"/>
        <path d="M104.143 154.96H98.269L106.101 100.362H111.975L104.143 154.96Z" fill="#263238" stroke="#263238" stroke-width="1.1663" stroke-miterlimit="10"/>
        <path d="M115.89 154.96H121.764L113.932 100.362H108.059L115.89 154.96Z" fill="white" stroke="#263238" stroke-width="1.1663" stroke-miterlimit="10"/>
        <path d="M119.996 91.8021H7.9624V102.664H119.996V91.8021Z" fill="#00AE42" stroke="#263238" stroke-width="1.1663" stroke-miterlimit="10"/>
        <path d="M122.727 91.8021H10.6931V102.664H122.727V91.8021Z" fill="#00AE42" stroke="#263238" stroke-width="1.1663" stroke-miterlimit="10"/>
        <path d="M12.527 102.664H22.0368L26.9507 91.8021H17.4405L12.527 102.664Z" fill="#263238"/>
        <path d="M50.166 91.8021H40.6562L35.7423 102.664H45.2521L50.166 91.8021Z" fill="#263238"/>
        <path d="M119.813 91.8021H110.303L105.389 102.664H114.899L119.813 91.8021Z" fill="#263238"/>
        <path d="M96.5972 91.8021H87.087L82.1731 102.664H91.6833L96.5972 91.8021Z" fill="#263238"/>
        <path d="M73.3815 91.8021H63.8717L58.9578 102.664H68.468L73.3815 91.8021Z" fill="#263238"/>
        <path d="M122.115 96.6205H103.574" stroke="#263238" stroke-width="1.1663" stroke-miterlimit="10"/>
        <path d="M121.79 98.437H118.862" stroke="#263238" stroke-width="1.1663" stroke-miterlimit="10"/>
        <path d="M10.2181 97.9829H28.7594" stroke="#263238" stroke-width="1.1663" stroke-miterlimit="10"/>
        <path d="M10.5436 95.9394H33.9637" stroke="#263238" stroke-width="1.1663" stroke-miterlimit="10"/>
        <path d="M153.306 152.576H121.525V154.96H153.306V152.576Z" fill="#263238" stroke="#263238" stroke-width="1.1663" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M147.943 152.509H126.888L135.142 107.618H139.69L147.943 152.509Z" fill="#00AE42" stroke="#263238" stroke-width="1.1663" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M132.074 124.304H142.757L141.735 118.742H133.097L132.074 124.304Z" fill="white" stroke="#263238" stroke-width="1.1663" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M144.779 145.018L145.307 148.087" stroke="#263238" stroke-width="1.1663" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M138.768 110.099L144.114 141.154" stroke="#263238" stroke-width="1.1663" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M170.404 154.96H173.252" stroke="#263238" stroke-width="1.1663" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M1.22131 154.96H166.43" stroke="#263238" stroke-width="1.1663" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>


      <p class="main-text mt-4"> Uh-Oh! <br> Access Denied.</p>


      <p class="content mt-3 text-center">
        <span class="fw-700"> Oops,</span> It seems you don't have the necessary permissions to view this page. If you <br>
        believe this is an error, please contact the administrator for assistance.
      </p>

      <!-- <a class="btn-back mt-2" href="/account/login"> -->
      <a class="btn-back mt-2" (click)="backToLogin()">
        Back to Login
      </a>
    </ng-container>


  </div>
</div>
