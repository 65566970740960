<ngx-select-dropdown
  #dropdown
  [instanceId]="'instance1'"
  [class]="customClass"
  (change)="selectionChanged($event)"
  [multiple]="false"
  [(ngModel)]="dataModel"
  [config]="config"
  [options]="dropdownOptions"
></ngx-select-dropdown>

