import { OnInit, Component, EventEmitter, Output, Renderer2  } from '@angular/core';
import { Router } from '@angular/router';
import { appModuleAnimation } from '@shared/animations/routerTransition';
  
  @Component({
    selector: 'success-page',
    templateUrl: './success-page.component.html',
    styleUrls: [ './success-page.component.css' ],
    animations:[appModuleAnimation()],
  })
  export class SuccessPageComponent implements OnInit {
    isTableLoading: boolean=false;
    @Output() moveBack = new EventEmitter<boolean>();

    constructor(private router: Router, private renderer: Renderer2) {}
  
    ngOnInit(): void {
      this.renderer.addClass(document.body, 'success-page');
    }
  
    moveToForm(){
      this.moveBack.emit(true);
    }

    // moveForward(){
    //   this.router.navigate(['']);
    // }

    ngOnDestroy(): void {}
  
  }
  