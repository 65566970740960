import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-page-size-dropdown',
  templateUrl: './app-page-size-dropdown.component.html',
  styleUrls: ['./app-page-size-dropdown.component.css']
})
export class AppPageSizeDropdownComponent implements OnInit {
  pageSizeList: number[] = [15, 25, 50, 75, 100];
  @Input() defaultMaxCount: number = 15;

  @Output() maxCountChange = new EventEmitter<number>();
  constructor() { }

  ngOnInit() {
  }

  onPageSizeChange(event: any) {
    this.maxCountChange.emit(Number(event.target.value));
  }
}
