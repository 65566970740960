import { Component, Injector, Input } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { appModuleAnimation } from "@shared/animations/routerTransition";
import { AppComponentBase } from "@shared/app-component-base";
import { LayoutStoreService } from "@shared/layout/layout-store.service";
import { BankGuaranteesServices, IGetDataForAllBankGuaranteesItems } from "@shared/service-proxies/dakonex/bankGuarantees/bank-guarantee.service";
import { BsDatepickerConfig } from "ngx-bootstrap/datepicker";
import { finalize } from "rxjs";
export interface printDTO {
  tableHeading: tableHeadingItems[],
  printBtnTitle: string,
  title: string,
  state?: string
  projectCode?:string,
  id?:number
};
export interface tableHeadingItems{
  title: string
};
@Component({
  selector: 'app-print',
  templateUrl: "print.component.html",
  styleUrls: ["print.component.css"],
  animations: [appModuleAnimation()],
})
export class PrintComponent  extends AppComponentBase{
  isTableLoading: boolean = false;
  public bsConfig: Partial<BsDatepickerConfig> = {
    dateInputFormat: "DD/MM/YYYY",
    showClearButton: true,
    clearPosition: "right",
    showTodayButton: true,
    todayPosition: "left",
  };
  // @Input() data: printDTO;

  private _data: printDTO;

  @Input() 
  set data(value: printDTO) {
    this._data = value;
    this.showLayout(value);
  }

  get data(): printDTO {
    return this._data;
  }

  bankGuaranteesList: IGetDataForAllBankGuaranteesItems[] = [];
  constructor(
    injector:Injector,
    private _bankGuaranteesService: BankGuaranteesServices,
    private route: Router
  ) {
    super(injector)
  }

  ngOnInit() {
    if(!this.data?.title){
      this.route.navigateByUrl("../");
    }
  }

  showLayout(data:printDTO) {
    if (data?.id == 0) {
      this.initialize(0);
    } else {
      this.initialize(1);
    }
  }

  initialize(reportFlag: number) {
     if (reportFlag === 1) {
      this.getAllBankGurantees();
    }
    else{
      this.getCurrentBankGurantees();
    }
  }

  getAllBankGurantees(){
    let prjectCode=this.data.projectCode
    abp.ui.setBusy();
      this._bankGuaranteesService.GetAllBankGuaranteeData('','',0,10,'',true).subscribe({
        next:(res)=>{
          this.bankGuaranteesList=res.items
          abp.ui.clearBusy();

        },
        error:()=>{
          abp.ui.clearBusy();
        }
      })
  }

  getCurrentBankGurantees(){
    abp.ui.setBusy();
      this._bankGuaranteesService.GetCurrentBankGuaranteeData().subscribe({
        next:(res:any)=>{
          this.bankGuaranteesList=res
          abp.ui.clearBusy();

        },
        error:()=>{
          abp.ui.clearBusy();
        }
      })
  }
 
}
