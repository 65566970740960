import { Directive, ElementRef, HostListener, Renderer2, OnInit } from '@angular/core';

@Directive({
  selector: '[appCurrencyFormat]'
})
export class CurrencyFormatDirective implements OnInit {

  constructor(private el: ElementRef, private renderer: Renderer2) { }

  ngOnInit() {
    // Format the initial value when the component initializes
    this.formatInputValue();
  }

  @HostListener('input', ['$event']) onInput(event: any) {
    // Format the input value as the user types
    this.formatInputValue();
  }

  @HostListener('blur') onBlur() {
    // Format the input value when the input field loses focus
    this.formatInputValue();
  }

  onModelChange(newValue: any) {
    this.formatInputValue();
  }

  private formatInputValue(newValue?) {
    let inputVal = this.el.nativeElement.value;
    inputVal = inputVal.replace(/[^0-9]/g, ''); // Remove non-numeric characters

    const formattedValue = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    }).format(parseFloat(inputVal));
    // Remove the currency symbol from the formatted value
    const valueWithoutSymbol = formattedValue.replace(/^[\D]+/, '');
    this.renderer.setProperty(this.el.nativeElement, 'value', valueWithoutSymbol);
  }
}